import { useAuth } from '@chroma-x/frontend/core/react-auth-provider';
import { useL10n } from '@chroma-x/frontend/core/react-l10n';
import { Card } from '@chroma-x/frontend/core/react-ui-primitive';

import './customer.scss';

type CustomTokenPayload = {
	name: string,
	customer: {
		id: string
	}
};

export function Customer() {

	const auth = useAuth();
	const l10n = useL10n();

	if (auth === null) {
		return null;
	}

	const authDetails = auth.getTokenDetails<CustomTokenPayload>().get();

	return (
		<Card>
			<main className="customer">
				<h1 className="customer__name">{auth.getTokenDetails<CustomTokenPayload>().get()?.name}</h1>
				<p className="customer__id">{l10n.translate('document.reactUiDomain.customer.idLabel', new Map([['customerId', authDetails?.customer?.id ?? '']]))}</p>
			</main>
		</Card>
	);
}
