/**
 * Combines a base URL with a path and optional query parameters.
 *
 * @param baseUrl - The base URL to combine.
 * @param path - The path to append to the base URL.
 * @param queryParams - Optional query parameters to add to the URL.
 * @returns The combined URL.
 */
export const combineUrl = (baseUrl: string, path: string, queryParams?: Map<string, string>): string => {
	const url = new URL(baseUrl);
	url.pathname = path;
	if (queryParams && queryParams.size > 0) {
		queryParams.forEach((value, key) => {
			url.searchParams.set(key, value);
		});
	}
	return url.toString();
};
