/**
 * Generates a predictable UUID version 4 based on an index.
 * The UUID follows a specific format and is deterministic based on the input index.
 *
 * @param index - The index used to generate the UUID.
 * @returns The generated UUID.
 *
 * @example
 * const uuid = generatePredictableUuid(1);
 * console.log(uuid); // Output: "baa2866a-3db1-4716-a669-000000000001"
 */
export const generatePredictableUuid = (index: number): string => {
	return `baa2866a-3db1-4716-a669-${index.toString(16).toLowerCase().padStart(12, '0')}`;
};
