import { FunctionComponent, ReactElement, useMemo } from 'react';

import { Nullable, Optional } from '@chroma-x/common/core/util';
import { FetchStatus, fetchStatusPendingGroup } from '@chroma-x/frontend/core/service';

import { ServiceProviderPendingProps } from '../props/service-provider-props';

/**
 * Custom hook to handle fetch suspense.
 *
 * @param fetchStatus - The current fetch status.
 * @param suspense - Flag to enable suspense.
 * @param pendingComponent - Optional component to show while fetch is pending.
 * @returns Optional React element to suspend rendering.
 */
export const useFetchSuspense = (
	fetchStatus: FetchStatus,
	suspense: boolean,
	pendingComponent: Nullable<FunctionComponent<ServiceProviderPendingProps>>
): Optional<ReactElement> => {
	const PendingComponent = pendingComponent;
	return useMemo(() => {
		if (suspense && (fetchStatus === FetchStatus.IDLE || fetchStatusPendingGroup.includes(fetchStatus))) {
			if (PendingComponent !== null) {
				return new Optional(<PendingComponent />);
			}
			return new Optional(<code>Loading</code>);
		}
		return new Optional<ReactElement>(null);
	}, [fetchStatus]);
};

/**
 * Custom hook to handle suspense during search.
 *
 * @param searchStatus - The current status of the search.
 * @param suspense - Flag indicating if suspense is enabled.
 * @param pendingComponent - Component to render while pending.
 * @returns Optional ReactElement to render.
 */
export const useSearchSuspense = (
	searchStatus: FetchStatus,
	suspense: boolean,
	pendingComponent: Nullable<FunctionComponent<ServiceProviderPendingProps>>
): Optional<ReactElement> => {
	const PendingComponent = pendingComponent;
	if (suspense && (searchStatus === FetchStatus.IDLE || fetchStatusPendingGroup.includes(searchStatus))) {
		if (PendingComponent !== null) {
			return new Optional(<PendingComponent />);
		}
		return new Optional(<code>Loading</code>);
	}
	return new Optional<ReactElement>(null);
};
