import { ThemeDefinition } from '@chroma-x/frontend/core/react-tenant-provider';

export const primeroTheme: ThemeDefinition = {
	cssFiles: ['primero-theme.css'],
	logo: 'primero.png',
	favicon: 'primero-favicon.png',
	literals: [
		{
			localeIdentifier: 'de',
			literals: {
				reactStarter: {
					appName: 'Primero Dokumente',
					theme: {
						tenantName: 'Primero'
					}
				}
			}
		},
		{
			localeIdentifier: 'en',
			literals: {
				reactStarter: {
					appName: 'Primero Documents',
					theme: {
						tenantName: 'Primero'
					}
				}
			}
		}
	]
};
