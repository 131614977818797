/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ComponentType, FC } from 'react';

import { Logger } from '@chroma-x/frontend/core/logger';

import { useLogger } from './provider/logger-consumer';

type ComponentProps = {
	logger?: Logger
} & any;

/**
 * Higher-order component that injects a logger instance into the component.
 *
 * @param Component - The component to wrap.
 * @returns The wrapped component.
 */
export const withLogger = <P = ComponentProps>(Component: ComponentType<P>): FC<P> => {
	return (props: any) => {
		const logger = useLogger();
		return <Component logger={logger ?? undefined} {...props} />;
	};
};
