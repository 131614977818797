import React, { Suspense } from 'react';

import { IconIdentifier, IconSize } from './icon.enum';

const IconSvg = React.lazy(() => import('./icon-svg'));

export type IconProps = {
	identifier: IconIdentifier,
	size?: IconSize
};

export function Icon(props: IconProps) {

	const { identifier, size = IconSize.MEDIUM } = props;

	return (
		<Suspense fallback={null}>
			<IconSvg identifier={identifier} size={size} />
		</Suspense>
	);

}
