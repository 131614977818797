import { ThemeDefinition } from '@chroma-x/frontend/core/react-tenant-provider';

export const veganetTheme: ThemeDefinition = {
	cssFiles: ['veganet-theme.css', 'veganet-theme-custom.css'],
	logo: 'veganet.png',
	favicon: 'veganet-favicon.png',
	literals: [
		{
			localeIdentifier: 'de',
			literals: {
				reactStarter: {
					appName: 'Veganet Dokumente',
					theme: {
						tenantName: 'Veganet'
					}
				}
			}
		},
		{
			localeIdentifier: 'en',
			literals: {
				reactStarter: {
					appName: 'Veganet Documents',
					theme: {
						tenantName: 'Veganet'
					}
				}
			}
		}
	]
};
