import { useNavigate } from 'react-router-dom';

import { useL10n } from '@chroma-x/frontend/core/react-l10n';
import { ButtonPrimary, IconIdentifier, IllustrationIdentifier, SystemFeedback } from '@chroma-x/frontend/core/react-ui-primitive';
import { buildRouteUrl } from '@chroma-x/frontend/core/routing';

import { RoutePath } from '../router/routes';

export function NotFoundView() {

	const l10n = useL10n();
	const navigate = useNavigate();

	const handleHome = () => {
		navigate(buildRouteUrl(RoutePath.ROOT));
	};

	return (
		<SystemFeedback message={l10n.translate('reactStarter.error.notFound.message')} illustration={IllustrationIdentifier.NOT_FOUND}>
			<ButtonPrimary
				label={l10n.translate('reactStarter.error.notFound.resolveLabel')}
				icon={IconIdentifier.REPEAT}
				onClick={handleHome}
			/>
		</SystemFeedback>
	);

}
