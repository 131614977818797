import { Nullable } from '@chroma-x/common/core/util';

import { StorageAdapter } from './storage-adapter';

/**
 * MemoryStorageAdapter is a class that implements the StorageAdapter interface.
 * It stores data in memory using a Map.
 */
export class MemoryStorageAdapter implements StorageAdapter {

	private static storage: Map<string, string> = new Map();

	/**
	 * Reads the value associated with the given key from the storage.
	 * @param key - The key to read.
	 * @returns The value associated with the key, or null if the key does not exist.
	 */
	public read(key: string): Nullable<string> {
		return MemoryStorageAdapter.storage.get(key) ?? null;
	}

	/**
	 * Writes the value to the storage.
	 * @param key - The key to associate with the value.
	 * @param value - The value to write.
	 */
	public write(key: string, value: string): void {
		MemoryStorageAdapter.storage.set(key, value);
	}

	/**
	 * Removes the value associated with the given key from the storage.
	 * @param key - The key to remove.
	 */
	public remove(key: string): void {
		MemoryStorageAdapter.storage.delete(key);
	}

	/**
	 * Clears all values from the storage.
	 */
	public clear(): void {
		MemoryStorageAdapter.storage.clear();
	}

	/**
	 * Adds a listener for the 'storage' event.
	 * @param _callback - The callback function to invoke when the event is fired.
	 */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	public addListener(_callback: (event: StorageEvent) => void): void {
	}

	/**
	 * Removes a listener for the 'storage' event.
	 * @param _callback - The callback function to remove.
	 */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	public removeListener(_callback: (event: StorageEvent) => void): void {
	}

}
