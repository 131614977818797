import { ReactNode } from 'react';

import { EventData, Logger, LogLevel } from '@chroma-x/frontend/core/logger';

import { loggerContext } from './logger-context';

export type LoggerProviderProps = {
	logger: Logger,
	children: ReactNode
};

/**
 * LoggerProvider is a component that provides a logger context to its children.
 *
 * @param props - The props for the LoggerProvider.
 * @param props.logger - The logger instance to be provided.
 * @param props.children - The children to be wrapped in the logger context.
 * @returns The component wrapped in the logger context.
 */
export function LoggerProvider(props: LoggerProviderProps) {
	const { logger } = props;

	const loggerContextValue = {
		provided: true,
		async logEvent(message: string, data?: EventData, category?: string, level?: LogLevel): Promise<void> {
			void logger.logEvent(message, data, category, level);
		},
		async logMessage(message: unknown, level?: LogLevel): Promise<void> {
			void logger.logMessage(message, level);
		},
		async logError(error: Error, level?: LogLevel): Promise<void> {
			void logger.logError(error, level);
		}
	};

	return (
		<loggerContext.Provider value={loggerContextValue}>
			{props.children}
		</loggerContext.Provider>
	);
}
