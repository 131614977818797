import { Nullable } from '@chroma-x/common/core/util';
import { OauthApiClientInstrument } from '@chroma-x/frontend/core/oauth-api-integration';

import { AuthHandlerInterface } from './auth-handler-interface';
import { JsonWebToken } from './json-web-token';

/**
 * Type of the function returned by the createLoader method to receive a JWT on demand.
 */
export type AccessTokenLoaderClosure = () => Promise<Nullable<JsonWebToken>>;

export class AccessTokenLoader {

	private static tokenRefreshPending = false;

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	private constructor() {
	}

	/**
	 * **Creates and returns a function** prepared to receive a `JsonWebToken` on demand. The token can not be read and kept because its
	 * validity depends on its expiry time. Use the returned function instead to receive the freshest token available – if available.
	 * If a token update is needed or makes sense the token is refreshed before returning it. Therefor the returned closure is `async`.
	 *
	 * @param authHandler - The auth handler in use
	 * @returns The created AccessTokenLoaderClosure
	 */
	public static createLoader(authHandler: AuthHandlerInterface): AccessTokenLoaderClosure {
		return async () => {
			if (authHandler.needsUpdate() && !this.tokenRefreshPending) {
				this.tokenRefreshPending = true;
				try {
					const tokenRefreshResponseModel = await OauthApiClientInstrument.getClient().refreshToken(
						authHandler.getToken().get()?.refreshToken ?? ''
					);
					const accessTokenValidTo = new Date();
					accessTokenValidTo.setSeconds(accessTokenValidTo.getSeconds() + tokenRefreshResponseModel.expires_in);
					const refreshTokenValidTo = new Date();
					refreshTokenValidTo.setSeconds(refreshTokenValidTo.getSeconds() + tokenRefreshResponseModel.refresh_expires_in);
					authHandler.update(tokenRefreshResponseModel.access_token, accessTokenValidTo, tokenRefreshResponseModel.refresh_token, refreshTokenValidTo);
				} finally {
					this.tokenRefreshPending = false;
				}
			}
			return authHandler.getToken().get();
		};
	}

}
