export type RouteParameter = [string, string];

/**
 * Builds a route URL by replacing placeholders in the route path with corresponding values.
 *
 * @param routePath - The route path with placeholders.
 * @param parameters - An array of key-value pairs.
 * @returns The route URL with placeholders replaced.
 *
 * @example
 * buildRouteUrl('/groups/:groupId/users/:userId', ['groupId', '123'], ['userId', '456']); // Returns '/groups/123/users/456'
 */
export const buildRouteUrl = <T extends string>(routePath: T, ...parameters: Array<RouteParameter>): string => {
	return String(routePath)
		.split('/')
		.map((urlFragment) => {
			for (const [key, value] of parameters) {
				if (urlFragment === ':' + key) {
					return value;
				}
			}
			return urlFragment;
		})
		.join('/');
};
