import { useEffect } from 'react';

import { ModelPrimaryKey } from '@chroma-x/common/core/api-integration';
import { restoreError } from '@chroma-x/common/core/error';
import { l10nLiteralFromEnumValue } from '@chroma-x/common/core/util';
import { filterCriteriaFromFilterOptions, filterOptionsFromFilterCriteria } from '@chroma-x/frontend/core/api-integration';
import { LogLevel } from '@chroma-x/frontend/core/logger';
import { useL10n } from '@chroma-x/frontend/core/react-l10n';
import { useLogger } from '@chroma-x/frontend/core/react-logger';
import {
	ButtonPrimary,
	Collection,
	CollectionHeader,
	CollectionItemCell,
	CollectionItemCellWidth,
	IconIdentifier,
	IllustrationIdentifier,
	InputSelect, InputSelectOption,
	LoadingIndicator,
	SystemFeedback,
	SystemFeedbackDescription, useToastManager
} from '@chroma-x/frontend/core/react-ui-primitive';
import { ActionStatus } from '@chroma-x/frontend/core/service';
import {
	DocumentProvisionCollectionInterceptEmpty,
	DocumentProvisionCollectionSuspense,
	useDocumentProvisionService
} from '@chroma-x/frontend/domain/document/react-service-provider';

import { billingPeriodFilterOptions, DocumentFilterOption, documentFilterOptionGroups } from './document-collection-filter';
import { DocumentCollectionItem } from '../document-collection-item/document-collection-item';

import './document-collection.scss';

export function DocumentCollection() {

	const logger = useLogger();
	const l10n = useL10n();
	const service = useDocumentProvisionService();
	const toastManager = useToastManager();

	const effectiveFilterCriteria = service.meta.queryFilterCriteria() ?? [];
	const actionStatus = service.meta.queryActionStatus();
	const actionError = service.meta.queryActionError();

	useEffect(() => {
		if (actionStatus === ActionStatus.COMMAND_FAILED) {
			if (actionError !== null) {
				logger?.logError(restoreError(actionError), LogLevel.WARN);
			}
			service.meta.resolveActionStatus();
			toastManager.present(l10n.translate('document.reactUiDomain.error.downloadLinkCreationFailed'));
		}
	}, [actionStatus]);

	const effectiveFilterOptions = filterOptionsFromFilterCriteria(
		documentFilterOptionGroups,
		effectiveFilterCriteria ?? []
	);
	const effectiveFilterOption = effectiveFilterOptions.at(0);

	const documentFilterOptions = billingPeriodFilterOptions.map((billingPeriodFilterOption): InputSelectOption<DocumentFilterOption> => {
		return {
			label: l10n.translate(l10nLiteralFromEnumValue(billingPeriodFilterOption, 'document.reactUiDomain.documentCollection.filter.options')),
			value: billingPeriodFilterOption
		};
	});

	const handleFilterApply = (filterOption: DocumentFilterOption) => {
		const filterCriteria = filterCriteriaFromFilterOptions(documentFilterOptionGroups, [filterOption]);
		void service.command.applyFilter(filterCriteria);
	};

	const handleDownloadRequest = (documentId: ModelPrimaryKey): void => {
		void service.command.downloadDocument(documentId);
	};

	const handleLoadMore = () => {
		void service.command.fetchNextPage();
	};

	const renderItems = () => {
		return service.query.query().map((document) => {
			return (
				<DocumentCollectionItem
					document={document}
					onClick={handleDownloadRequest}
					key={document.id}
				/>
			);
		});
	};

	const renderPaginationButton = () => {
		if (!service.meta.hasNextPage()) {
			return;
		}
		return (
			<footer className="document-collection__footer">
				<ButtonPrimary
					label={l10n.translate('document.reactUiDomain.documentCollection.loadMoreLabel')}
					icon={IconIdentifier.PLUS}
					onClick={handleLoadMore}
				/>
			</footer>
		);
	};

	const renderPendingState = () => {
		return (
			<LoadingIndicator message={l10n.translate('document.reactUiDomain.documentCollection.pendingState.message')} />
		);
	};

	const renderEmptyState = () => {
		return (
			<SystemFeedback
				message={l10n.translate('document.reactUiDomain.documentCollection.emptyState.message')}
				illustration={IllustrationIdentifier.EMPTY}
			>
				<SystemFeedbackDescription description={l10n.translate('document.reactUiDomain.documentCollection.emptyState.description')} />
			</SystemFeedback>
		);
	};

	return (
		<article className="document-collection">
			<header className="document-collection__header">
				<h1 className="document-collection__header__heading">{l10n.translate('document.reactUiDomain.documentCollection.heading')}</h1>
				<InputSelect
					label={l10n.translate('document.reactUiDomain.documentCollection.filter.label')}
					options={documentFilterOptions}
					value={effectiveFilterOption}
					required={true}
					onChange={handleFilterApply}
				/>
			</header>
			<DocumentProvisionCollectionSuspense pendingComponent={renderPendingState}>
				<DocumentProvisionCollectionInterceptEmpty emptyStateComponent={renderEmptyState}>
					<Collection>
						<CollectionHeader>
							<CollectionItemCell width={CollectionItemCellWidth.WIDTH_WIDE}>
								<span className="document-collection__header-cell">
									{l10n.translate('document.reactUiDomain.documentCollection.header.documentType')}
								</span>
							</CollectionItemCell>
							<CollectionItemCell width={CollectionItemCellWidth.WIDTH_DEFAULT}>
								<span className="document-collection__header-cell">
									{l10n.translate('document.reactUiDomain.documentCollection.header.billingPeriod')}
								</span>
							</CollectionItemCell>
							<CollectionItemCell width={CollectionItemCellWidth.WIDTH_WIDE} />
							<CollectionItemCell width={CollectionItemCellWidth.WIDTH_FIXED} baseWidth={50} />
							<CollectionItemCell width={CollectionItemCellWidth.WIDTH_FIXED} baseWidth={24} />
						</CollectionHeader>
						{renderItems()}
					</Collection>
					{renderPaginationButton()}
				</DocumentProvisionCollectionInterceptEmpty>
			</DocumentProvisionCollectionSuspense>
		</article>
	);
}
