import { isArray, isString } from '@chroma-x/common/core/util';

import { FreeSortCriterion, ModelSortCriterion, SortCriteria, SortCriterion, SortDirection } from './sort-criteria';

const sortCriterionDirection = [...Object.values(SortDirection), undefined];

/**
 * Checks if the given object is a FreeSortCriterion.
 * @param sortCriterion - The object to check.
 * @returns True if the object is a FreeSortCriterion, false otherwise.
 */
export const isFreeSortCriterion = (sortCriterion: unknown): sortCriterion is FreeSortCriterion => {
	const directionValid = (sortCriterion as FreeSortCriterion).direction === undefined
		|| isString((sortCriterion as FreeSortCriterion).direction);
	return directionValid
		&& (sortCriterion as FreeSortCriterion).property !== undefined
		&& isString((sortCriterion as FreeSortCriterion).property);
};

/**
 * Checks if the given object is a ModelSortCriterion.
 * @param sortCriterion - The object to check.
 * @returns True if the object is a ModelSortCriterion, false otherwise.
 */
export const isModelSortCriterion = <Model = unknown>(sortCriterion: unknown): sortCriterion is ModelSortCriterion<Model> => {
	return sortCriterionDirection.includes((sortCriterion as FreeSortCriterion).direction)
		&& (sortCriterion as FreeSortCriterion).property !== undefined
		&& isString((sortCriterion as FreeSortCriterion).property);
};

/**
 * Checks if the given object is a SortCriterion.
 * @param sortCriterion - The object to check.
 * @returns True if the object is a SortCriterion, false otherwise.
 */
export const isSortCriterion = <Model = unknown>(sortCriterion: unknown): sortCriterion is SortCriterion<Model> => {
	return isFreeSortCriterion(sortCriterion) || isModelSortCriterion(sortCriterion);
};

/**
 * Checks if the given object is a SortCriteria.
 * @param sortCriteria - The object to check.
 * @returns True if the object is a SortCriteria, false otherwise.
 */
export const isSortCriteria = <Model = unknown>(sortCriteria: unknown): sortCriteria is SortCriteria<Model> => {
	if (!isArray(sortCriteria)) {
		return false;
	}
	for (const sortCriterion of sortCriteria) {
		if (!isSortCriterion(sortCriterion)) {
			return false;
		}
	}
	return true;
};
