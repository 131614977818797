import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { Locale } from '@chroma-x/common/core/l10n';
import { ConsoleLogger } from '@chroma-x/frontend/core/logger';
import { EnvProvider } from '@chroma-x/frontend/core/react-environment';
import { ErrorHandler } from '@chroma-x/frontend/core/react-error-handler';
import { L10nProvider } from '@chroma-x/frontend/core/react-l10n';
import { LoggerProvider } from '@chroma-x/frontend/core/react-logger';
import { ServiceManagerProvider } from '@chroma-x/frontend/core/react-service-provider';
import { TenantProvider, Theme } from '@chroma-x/frontend/core/react-tenant-provider';
import {
	ButtonPrimary,
	IconIdentifier,
	IllustrationIdentifier,
	SystemFeedback,
	SystemFeedbackDescription,
	ToastManager
} from '@chroma-x/frontend/core/react-ui-primitive';

import { AuthRouter } from './app/router/auth-router';
import { environment } from './environments/environment';
import { tenants } from './tenants/tenants';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

try {
	// Set up locales to be provided
	const locales = [
		// Option 1: use straight imported JSON modules
		// new Locale('aa', [appLiteralsEn]),
		// Option 2: use URLs to load the literals from
		//   Usually created from the JSON modules by running `nx bundle-literals frontend-react-starter` and a proper literal.config.json
		new Locale('de', ['/literal-bundles/literal-bundle-de.json']),
		new Locale('en', ['/literal-bundles/literal-bundle-en.json'])
	];

	// Set up logger instances to be provided
	const consoleLogger = new ConsoleLogger(true, undefined, undefined);

	const ErrorView = (error: Error) => {
		// Literals are not localized because l10n is not provided yet.
		return (
			<SystemFeedback message="Uuuups!" illustration={IllustrationIdentifier.OFFLINE}>
				<SystemFeedbackDescription description={error.message} />
				<ButtonPrimary
					label="Reload"
					icon={IconIdentifier.REPEAT}
					onClick={() => window.location.reload()}
				/>
			</SystemFeedback>
		);
	};

	root.render(
		<StrictMode>
			<LoggerProvider logger={consoleLogger}>
				<ErrorHandler errorComponent={ErrorView}>
					<EnvProvider env={environment}>
						<L10nProvider locales={locales} fallbackLocaleIdentifier="en">
							<BrowserRouter>
								<ServiceManagerProvider>
									<TenantProvider tenants={tenants}>
										<ToastManager>
											<Theme />
											<AuthRouter />
										</ToastManager>
									</TenantProvider>
								</ServiceManagerProvider>
							</BrowserRouter>
						</L10nProvider>
					</EnvProvider>
				</ErrorHandler>
			</LoggerProvider>
		</StrictMode>
	);

} catch (e) {
	root.render(<pre>Something went wrong</pre>);
}
