/**
 * This function is used to start a file download.
 * It creates a temporary anchor element, sets its href and download attributes,
 * triggers a click event on the anchor, and then removes the anchor.
 *
 * @param url - The URL of the file to be downloaded.
 * @param [filename] - The name of the file to be downloaded. If not provided, the filename is inferred from the URL.
 */
export const startDownload = (url: string, filename?: string): void => {
	const virtualAnchor = document.createElement('a');
	virtualAnchor.href = url;
	virtualAnchor.download = filename ?? '';
	virtualAnchor.click();
	virtualAnchor.remove();
};
