import { ReactNode } from 'react';

import { buildCssClassStringFromClassMap } from '@chroma-x/common/core/util';

import './collection-item.scss';

export type CollectionItemProps = {
	children: ReactNode,
	onClick?: () => void
};

export function CollectionItem(props: CollectionItemProps) {

	const { children, onClick } = props;

	const handleClick = (): void => {
		if (onClick) {
			onClick();
		}
	};

	const classMap = {
		'collection-item': true,
		'collection-item--clickable': onClick !== undefined
	};

	return (
		<article
			className={buildCssClassStringFromClassMap(classMap)}
			onClick={handleClick}
		>
			{children}
		</article>
	);

}
