import { Icon } from '../icon/icon';
import { IconIdentifier, IconSize } from '../icon/icon.enum';

import './toast.scss';

export type ToastProps = {
	message: string,
	onDismiss?: () => void;
};

export function Toast(props: ToastProps) {

	const { message, onDismiss } = props;

	const renderDismiss = () => {
		if (!onDismiss) {
			return null;
		}
		return (
			<div className="toast__dismiss" onClick={onDismiss}>
				<Icon identifier={IconIdentifier.X} size={IconSize.SMALL} />
			</div>
		);
	};

	return (
		<div className="toast">
			<Icon identifier={IconIdentifier.WARNING} />
			{message}
			{renderDismiss()}
		</div>
	);
}
