import { JwtPayload } from 'jwt-decode';
import { createContext } from 'react';

import { Maybe, Nullable, Optional } from '@chroma-x/common/core/util';
import { AccessToken, IdToken, JsonWebToken, RefreshToken } from '@chroma-x/frontend/core/auth-handler';

export type AuthContextValue = {
	/**
	 * Returns the scope of the authenticated user.
	 * If no auth context is provided, throws an AppError.
	 *
	 * @returns The scope of the authenticated user.
	 * @throws If no auth context is provided.
	 */
	getScope(): Maybe<string>,

	/**
	 * Authenticates the user with the provided access token.
	 * If no auth context is provided, throws an AppError.
	 *
	 * @param accessToken - The access token.
	 * @param accessTokenValidTo - The expiration date of the access token.
	 * @param [idToken] - The ID token.
	 * @param [idTokenValidTo] - The expiration date of the ID token.
	 * @param [refreshToken] - The refresh token.
	 * @param [refreshTokenValidTo] - The expiration date of the refresh token.
	 * @throws If no auth context is provided.
	 */
	authenticate(
		accessToken: AccessToken,
		accessTokenValidTo: Date,
		idToken?: IdToken,
		idTokenValidTo?: Date,
		refreshToken?: RefreshToken,
		refreshTokenValidTo?: Date
	): void,

	/**
	 * Updates the authentication state with the provided access token.
	 * If no auth context is provided, throws an AppError.
	 *
	 * @param accessToken - The access token.
	 * @param accessTokenValidTo - The expiration date of the access token.
	 * @param [idToken] - The ID token.
	 * @param [idTokenValidTo] - The expiration date of the ID token.
	 * @param [refreshToken] - The refresh token.
	 * @param [refreshTokenValidTo] - The expiration date of the refresh token.
	 * @throws If no auth context is provided.
	 */
	update(
		accessToken: AccessToken,
		accessTokenValidTo: Date,
		idToken?: IdToken,
		idTokenValidTo?: Date,
		refreshToken?: RefreshToken,
		refreshTokenValidTo?: Date
	): void,

	/**
	 * Reauthenticates the user.
	 * If no auth context is provided, throws an AppError.
	 *
	 * @throws If no auth context is provided.
	 */
	reauthenticate(): void,

	/**
	 * Unauthenticates the user.
	 * If no auth context is provided, throws an AppError.
	 *
	 * @throws If no auth context is provided.
	 */
	unauthenticate(): void,

	/**
	 * Returns the authentication token.
	 * If no auth context is provided, throws an AppError.
	 *
	 * @returns The authentication token.
	 * @throws If no auth context is provided.
	 */
	getToken(): Optional<JsonWebToken>,

	/**
	 * Returns the details of the authentication token.
	 * If no auth context is provided, throws an AppError.
	 *
	 * @returns The details of the authentication token.
	 * @throws If no auth context is provided.
	 */
	getTokenDetails<CustomPayload extends Record<string, unknown>>(): Optional<JwtPayload & CustomPayload>,

	/**
	 * Checks if the user is authenticated.
	 *
	 * @returns True if the user is authenticated, false otherwise.
	 */
	isAuthenticated(): boolean,

	/**
	 * Checks if the authentication needs to be updated.
	 *
	 * @returns True if the authentication needs to be updated, false otherwise.
	 */
	needsUpdate(): boolean
};

export const authContext = createContext<Nullable<AuthContextValue>>(null);
