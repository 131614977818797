import { createContext } from 'react';

import { Nullable } from '@chroma-x/common/core/util';

import { L10nContextValue } from './l10n-context-value';

/**
 * The `l10nContext` is a React context that holds the current locale and translation functions.
 * It is used to provide localization functionality to components in the application.
 *
 * The context is initialized with a `null` value, indicating that the locale is not yet set.
 */
export const l10nContext = createContext<Nullable<L10nContextValue>>(null);
