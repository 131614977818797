import { SortDirection } from '@chroma-x/common/core/api-integration';
import { filterCriteriaFromFilterOptions } from '@chroma-x/frontend/core/api-integration';
import { withUiErrorHandler } from '@chroma-x/frontend/core/react-ui-error-handler';
import { Card } from '@chroma-x/frontend/core/react-ui-primitive';
import { DocumentProvisionServiceProvider } from '@chroma-x/frontend/domain/document/react-service-provider';

import { Customer } from '../components/customer/customer';
import { DocumentCollection } from '../components/document-collection/document-collection';
import { DocumentFilterOption, documentFilterOptionGroups } from '../components/document-collection/document-collection-filter';

import './document-collection-view.scss';

const ServiceProviderWithUiErrorHandler = withUiErrorHandler(DocumentProvisionServiceProvider);

export function DocumentCollectionView() {

	return (
		<div className="document-collection-view">
			<Customer />
			<Card>
				<ServiceProviderWithUiErrorHandler
					suspense={false}
					sortCriteria={[
						{ property: 'billingPeriod', direction: SortDirection.DESCENDING },
						{ property: 'documentType', direction: SortDirection.ASCENDING }
					]}
					filterCriteria={filterCriteriaFromFilterOptions(documentFilterOptionGroups, [DocumentFilterOption.BILLING_PERIOD_ALL])}
				>
					<DocumentCollection />
				</ServiceProviderWithUiErrorHandler>
			</Card>
		</div>
	);

}
