import { ReactNode } from 'react';

import './collection.scss';

export type CollectionProps = {
	children: ReactNode
};

export function Collection(props: CollectionProps) {

	const { children } = props;

	return (
		<main className="collection">
			{children}
		</main>
	);

}
