import { castDraft } from 'immer';

import {
	DeleteResponseDto,
	FilterCriteria,
	HypermediaEntityResponse,
	ModelPrimaryKey,
	PaginatedCollectionResponse,
	SortCriteria
} from '@chroma-x/common/core/api-integration';
import { Model } from '@chroma-x/frontend/core/domain-model';

import {
	applyFilterPrecondition,
	applySortPrecondition,
	fetchCollectionEntityPrecondition,
	fetchCollectionPrecondition,
	fetchNextPagePrecondition,
	refetchCollectionEntityPrecondition,
	refetchCollectionPrecondition
} from './collection-operation-precondition';
import { CollectionServiceFoundation } from './collection-service-foundation';
import {
	addCollectionEntity,
	addOrUpdateCollectionEntity,
	removeCollectionEntity,
	updateCollectionEntity
} from './collection-state-operation';
import {
	collectionActionTransactionFailed,
	collectionActionTransactionStart,
	collectionActionTransactionSuccess,
	collectionEntityActionTransactionFailed,
	collectionEntityActionTransactionStart,
	collectionEntityActionTransactionSuccess,
	fetchCollectionEntityTransactionFailed,
	fetchCollectionEntityTransactionStart,
	fetchCollectionEntityTransactionSuccess,
	fetchCollectionTransactionFailed,
	fetchCollectionTransactionStart,
	fetchCollectionTransactionSuccess,
	fetchPageTransactionStart
} from './collection-transaction';
import { Action } from '../action-status-mapper';
import { GetArg, SetArg } from '../zustand-util';

/**
 * Creates a command to fetch a collection.
 *
 * @param get - A function to get the current state.
 * @param set - A function to set the state.
 * @param collectionResponseProvider - A function to fetch the collection.
 * @returns A function to fetch a collection.
 */
export const createFetchCollectionDefaultCommand = <
	S extends CollectionServiceFoundation<M, C>,
	M extends Model,
	C extends Record<string, unknown>
>(
	get: GetArg<S, M, C>,
	set: SetArg<S, M, C>,
	collectionResponseProvider: (
		sortCriteria?: SortCriteria<M>,
		filterCriteria?: FilterCriteria<M>,
		forceFetch?: boolean
	) => Promise<PaginatedCollectionResponse<M>>
) => {
	return async (
		sortCriteria?: SortCriteria<M>,
		filterCriteria?: FilterCriteria<M>,
		forceFetch?: boolean
	) => {
		if (fetchCollectionPrecondition<S, M>(get(), sortCriteria, filterCriteria, forceFetch)) {
			try {
				fetchCollectionTransactionStart(set);
				const collectionData
					= await collectionResponseProvider(sortCriteria, filterCriteria, forceFetch);
				set((state) => {
					state.data.meta.currentPage = collectionData.currentPage;
					state.data.meta.maxPages = collectionData.maxPages;
					state.data.meta.sortCriteria = castDraft(sortCriteria) ?? null;
					state.data.meta.filterCriteria = castDraft(filterCriteria) ?? null;
					state.data.models = castDraft(collectionData.items);
				});
				fetchCollectionTransactionSuccess(set);
			} catch (error) {
				fetchCollectionTransactionFailed(set, error as Error);
			}
		}
	};
};

/**
 * Creates a command to fetch the next page of a collection.
 *
 * @param get - A function to get the current state.
 * @param set - A function to set the state.
 * @param collectionResponseProvider - A function to fetch the next page of the collection.
 * @returns A function to fetch the next page of a collection.
 */
export const createFetchNextPageDefaultCommand = <
	S extends CollectionServiceFoundation<M, C>,
	M extends Model,
	C extends Record<string, unknown>
>(
	get: GetArg<S, M, C>,
	set: SetArg<S, M, C>,
	collectionResponseProvider: (
		currentPage: number,
		sortCriteria?: SortCriteria<M>,
		filterCriteria?: FilterCriteria<M>
	) => Promise<PaginatedCollectionResponse<M>>
) => {
	return async () => {
		if (fetchNextPagePrecondition(get())) {
			try {
				fetchPageTransactionStart(set);
				const currentState = get();
				const currentPage = currentState.data.meta.currentPage ?? 1;
				const sortCriteria = currentState.data.meta.sortCriteria ?? undefined;
				const filterCriteria = currentState.data.meta.filterCriteria ?? undefined;
				const collectionData
					= await collectionResponseProvider(currentPage, sortCriteria, filterCriteria);
				set((state) => {
					state.data.meta.currentPage = collectionData.currentPage;
					state.data.meta.maxPages = collectionData.maxPages;
					state.data.models = castDraft([...currentState.data.models, ...collectionData.items]);
				});
				fetchCollectionTransactionSuccess(set);
			} catch (error) {
				fetchCollectionTransactionFailed(set, error as Error);
			}
		}
	};
};

/**
 * Creates a command to apply a sort to a collection.
 *
 * @template S - The type of the collection service.
 * @template M - The type of the model.
 * @template C - The type of the collection.
 * @param get - The function to get the current state.
 * @param set - The function to set the state.
 * @param collectionResponseProvider - The function to fetch the collection.
 * @returns A function to apply the sort.
 */
export const createApplySortDefaultCommand = <
	S extends CollectionServiceFoundation<M, C>,
	M extends Model,
	C extends Record<string, unknown>
>(
	get: GetArg<S, M, C>,
	set: SetArg<S, M, C>,
	collectionResponseProvider: (
		sortCriteria?: SortCriteria<M>,
		filterCriteria?: FilterCriteria<M>
	) => Promise<PaginatedCollectionResponse<M>>
) => {
	return async (sortCriteria?: SortCriteria<M>, forceFetch?: boolean) => {
		if (applySortPrecondition(get(), sortCriteria, forceFetch)) {
			try {
				fetchCollectionTransactionStart(set);
				const currentState = get();
				const filterCriteria = currentState.data.meta.filterCriteria ?? undefined;
				const collectionData
					= await collectionResponseProvider(sortCriteria, filterCriteria);
				set((state) => {
					state.data.meta.currentPage = collectionData.currentPage;
					state.data.meta.maxPages = collectionData.maxPages;
					state.data.meta.sortCriteria = castDraft(sortCriteria) ?? null;
					state.data.models = castDraft(collectionData.items);
				});
				fetchCollectionTransactionSuccess(set);
			} catch (error) {
				fetchCollectionTransactionFailed(set, error as Error);
			}
		}
	};
};

/**
 * Creates a command to clear the sort of a collection.
 *
 * @param clearSortHandler - The function to clear the sort.
 * @returns A function to clear the sort.
 */
export const createClearSortDefaultCommand = (clearSortHandler: (forceFetch?: boolean) => Promise<void>) => {
	return async (forceFetch?: boolean) => {
		await clearSortHandler(forceFetch);
	};
};

/**
 * Creates a command to apply a filter to a collection.
 *
 * @template S - The type of the collection service.
 * @template M - The type of the model.
 * @template C - The type of the collection.
 * @param get - The function to get the current state.
 * @param set - The function to set the state.
 * @param collectionResponseProvider - The function to fetch the collection.
 * @returns A function to apply the filter.
 */
export const createApplyFilterDefaultCommand = <
	S extends CollectionServiceFoundation<M, C>,
	M extends Model,
	C extends Record<string, unknown>
>(
	get: GetArg<S, M, C>,
	set: SetArg<S, M, C>,
	collectionResponseProvider: (
		sortCriteria?: SortCriteria<M>,
		filterCriteria?: FilterCriteria<M>
	) => Promise<PaginatedCollectionResponse<M>>
) => {
	return async (filterCriteria?: FilterCriteria<M>, forceFetch?: boolean) => {
		if (applyFilterPrecondition(get(), filterCriteria, forceFetch)) {
			try {
				fetchCollectionTransactionStart(set);
				const currentState = get();
				const sortCriteria = currentState.data.meta.sortCriteria ?? undefined;
				const collectionData
					= await collectionResponseProvider(sortCriteria, filterCriteria);
				set((state) => {
					state.data.meta.currentPage = collectionData.currentPage;
					state.data.meta.maxPages = collectionData.maxPages;
					state.data.meta.filterCriteria = castDraft(filterCriteria) ?? null;
					state.data.models = castDraft(collectionData.items);
				});
				fetchCollectionTransactionSuccess(set);
			} catch (error) {
				fetchCollectionTransactionFailed(set, error as Error);
			}
		}
	};
};

/**
 * Creates a command to clear the filter.
 *
 * @param clearFilterHandler - The function to clear the filter.
 * @returns A command to clear the filter.
 */
export const createClearFilterDefaultCommand = (clearFilterHandler: (forceFetch?: boolean) => Promise<void>) => {
	return async (forceFetch?: boolean) => {
		await clearFilterHandler(forceFetch);
	};
};

/**
 * Creates a command to refetch the collection.
 *
 * @template S - The type of the collection service.
 * @template M - The type of the model.
 * @template C - The type of the collection.
 * @param get - The function to get the current state.
 * @param set - The function to set the state.
 * @param collectionResponseProvider - The function to fetch the collection.
 * @returns A command to refetch the collection.
 */
export const createRefetchCollectionDefaultCommand = <
	S extends CollectionServiceFoundation<M, C>,
	M extends Model,
	C extends Record<string, unknown>
>(
	get: GetArg<S, M, C>,
	set: SetArg<S, M, C>,
	collectionResponseProvider: (
		sortCriteria?: SortCriteria<M>,
		filterCriteria?: FilterCriteria<M>
	) => Promise<PaginatedCollectionResponse<M>>
) => {
	return async () => {
		if (refetchCollectionPrecondition(get())) {
			try {
				fetchCollectionTransactionStart(set);
				const currentState = get();
				const sortCriteria = currentState.data.meta.sortCriteria ?? undefined;
				const filterCriteria = currentState.data.meta.filterCriteria ?? undefined;
				const collectionData
					= await collectionResponseProvider(sortCriteria, filterCriteria);
				set((state) => {
					state.data.meta.currentPage = collectionData.currentPage;
					state.data.meta.maxPages = collectionData.maxPages;
					state.data.models = castDraft(collectionData.items);
				});
				fetchCollectionTransactionSuccess(set);
			} catch (error) {
				fetchCollectionTransactionFailed(set, error as Error);
			}
		}
	};
};

/**
 * Creates a command to fetch a collection entity.
 *
 * @template S - The type of the collection service.
 * @template M - The type of the model.
 * @template C - The type of the collection.
 * @param get - The function to get the current state.
 * @param set - The function to set the state.
 * @param entityResponseProvider - The function to fetch the entity.
 * @returns A command to fetch a collection entity.
 */
export const createFetchCollectionEntityDefaultCommand = <
	S extends CollectionServiceFoundation<M, C>,
	M extends Model,
	C extends Record<string, unknown>
>(
	get: GetArg<S, M, C>,
	set: SetArg<S, M, C>,
	entityResponseProvider: (id: ModelPrimaryKey) => Promise<HypermediaEntityResponse<M>>
) => {
	return async (id: ModelPrimaryKey, forceFetch?: boolean) => {
		if (fetchCollectionEntityPrecondition(get(), id, forceFetch)) {
			try {
				fetchCollectionEntityTransactionStart(set, id);
				const entityData = await entityResponseProvider(id);
				const model = entityData.item;
				if (model !== null) {
					addOrUpdateCollectionEntity(get, set, id, castDraft(model));
				}
				fetchCollectionEntityTransactionSuccess(set, id);
			} catch (error) {
				fetchCollectionEntityTransactionFailed(set, id, error as Error);
			}
		}
	};
};

/**
 * Creates a command to refetch a collection entity.
 *
 * @template S - The type of the collection service.
 * @template M - The type of the model.
 * @template C - The type of the collection.
 * @param get - The function to get the current state.
 * @param set - The function to set the state.
 * @param entityResponseProvider - The function to fetch the entity.
 * @returns A command to refetch a collection entity.
 */
export const createRefetchCollectionEntityDefaultCommand = <
	S extends CollectionServiceFoundation<M, C>,
	M extends Model,
	C extends Record<string, unknown>
>(
	get: GetArg<S, M, C>,
	set: SetArg<S, M, C>,
	entityResponseProvider: (id: ModelPrimaryKey) => Promise<HypermediaEntityResponse<M>>
) => {
	return async (id: ModelPrimaryKey) => {
		if (refetchCollectionEntityPrecondition(get(), id)) {
			try {
				fetchCollectionEntityTransactionStart(set, id);
				const entityData = await entityResponseProvider(id);
				const model = entityData.item;
				if (model !== null) {
					addOrUpdateCollectionEntity(get, set, id, castDraft(model));
				}
				fetchCollectionEntityTransactionSuccess(set, id);
			} catch (error) {
				fetchCollectionEntityTransactionFailed(set, id, error as Error);
			}
		}
	};
};

/**
 * Creates a command to create a collection entity.
 *
 * @template S - The type of the collection service.
 * @template M - The type of the model.
 * @template C - The type of the collection.
 * @template CM - The type of the create model.
 * @param set - The function to set the state.
 * @param entityResponseProvider - The function to create the entity.
 * @param onSuccess - The function to call on success.
 * @returns A command to create a collection entity.
 */
export const createCreateCollectionEntityDefaultCommand = <
	S extends CollectionServiceFoundation<M, C>,
	M extends Model,
	C extends Record<string, unknown>,
	CM extends Record<string, unknown>
>(
	set: SetArg<S, M, C>,
	entityResponseProvider: (createModel: CM) => Promise<HypermediaEntityResponse<M>>,
	onSuccess?: (model: M) => void
) => {
	return async (createModel: CM) => {
		try {
			collectionActionTransactionStart(set, Action.CREATE);
			const entityData = await entityResponseProvider(createModel);
			const model = entityData.item;
			if (model !== null) {
				addCollectionEntity<S, M, C>(set, castDraft(model));
				if (onSuccess) {
					onSuccess(model);
				}
			}
			collectionActionTransactionSuccess(set, Action.CREATE);
		} catch (error) {
			collectionActionTransactionFailed(set, Action.CREATE, error as Error);
		}
	};
};

/**
 * Creates a command to mutate a collection entity.
 *
 * @template S - The type of the collection service.
 * @template M - The type of the model.
 * @template C - The type of the collection.
 * @template MM - The type of the mutation model.
 * @param get - The function to get the current state.
 * @param set - The function to set the state.
 * @param entityResponseProvider - The function to mutate the entity.
 * @param onSuccess - The function to call on success.
 * @returns A command to mutate a collection entity.
 */
export const createMutateCollectionEntityDefaultCommand = <
	S extends CollectionServiceFoundation<M, C>,
	M extends Model,
	C extends Record<string, unknown>,
	MM extends Record<string, unknown>
>(
	get: GetArg<S, M, C>,
	set: SetArg<S, M, C>,
	entityResponseProvider: (id: ModelPrimaryKey, mutation: MM) => Promise<HypermediaEntityResponse<M>>,
	onSuccess?: (model: M) => void
) => {
	return async (id: ModelPrimaryKey, mutation: MM) => {
		try {
			collectionEntityActionTransactionStart(set, id, Action.MUTATE);
			const entityData = await entityResponseProvider(id, mutation);
			const model = entityData.item;
			if (model !== null) {
				updateCollectionEntity<S, M, C>(get, set, id, castDraft(model));
				if (onSuccess) {
					onSuccess(model);
				}
			}
			collectionEntityActionTransactionSuccess(set, id, Action.MUTATE);
		} catch (error) {
			collectionEntityActionTransactionFailed(set, id, Action.MUTATE, error as Error);
		}
	};
};

/**
 * Creates a command to delete a collection entity.
 *
 * @template S - The type of the collection service.
 * @template M - The type of the model.
 * @template C - The type of the collection.
 * @param get - The function to get the current state.
 * @param set - The function to set the state.
 * @param entityResponseProvider - The function to delete the entity.
 * @param onSuccess - The function to call on success.
 * @returns A command to delete a collection entity.
 */
export const createDeleteCollectionEntityDefaultCommand = <
	S extends CollectionServiceFoundation<M, C>,
	M extends Model,
	C extends Record<string, unknown>
>(
	get: GetArg<S, M, C>,
	set: SetArg<S, M, C>,
	entityResponseProvider: (id: ModelPrimaryKey) => Promise<DeleteResponseDto>,
	onSuccess?: (id: ModelPrimaryKey) => void
) => {
	return async (id: ModelPrimaryKey) => {
		try {
			collectionEntityActionTransactionStart(set, id, Action.DELETE);
			const deleteResponseDto = await entityResponseProvider(id);
			removeCollectionEntity<S, M, C>(get, set, deleteResponseDto.id);
			if (onSuccess) {
				onSuccess(deleteResponseDto.id);
			}
			collectionEntityActionTransactionSuccess(set, id, Action.DELETE);
		} catch (error) {
			collectionEntityActionTransactionFailed(set, id, Action.DELETE, error as Error);
		}
	};
};
