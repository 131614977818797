import { useContext } from 'react';

import { AppError } from '@chroma-x/common/core/error';

import { l10nContext } from './l10n-context';
import { L10nContextValue } from './l10n-context-value';

/**
 * Custom hook that provides the L10nContextValue.
 * Throws an error if the L10n context is not available.
 *
 * @returns The L10n context value.
 * @throws If the L10n context is not available.
 */
export const useL10n = (): L10nContextValue => {
	const context = useContext(l10nContext);
	if (context === null) {
		throw new AppError('No L10n context available');
	}
	return context;
};
