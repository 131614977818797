/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/document/api-spec/tspconfig.yml
 */
import {
	FilterCriteria,
	ModelPrimaryKey,
	PaginatedCollectionResponse,
	SortCriteria,
	extractPaginationResponse
} from '@chroma-x/common/core/api-integration';
import { AppError } from '@chroma-x/common/core/error';
import { Optional } from '@chroma-x/common/core/util';
import { JsonRestRequest, UrlPathParameter, UrlQueryParameter, buildApiEndpointUrl } from '@chroma-x/frontend/core/api-integration';
import { AccessTokenLoader, AccessTokenLoaderClosure, AuthHandler } from '@chroma-x/frontend/core/auth-handler';
import { DocumentModel, SignedDownloadUrlModel } from '@chroma-x/frontend/domain/document/domain-model';

import { DocumentDto, SignedDownloadUrlDto } from '../dto';
import { DocumentResponseModelConverter, SignedDownloadUrlResponseModelConverter } from '../model-converter/response';

export class DocumentProvisionServiceApiClient {
	private readonly apiBaseUrl: string;
	private readonly accessTokenLoader: AccessTokenLoaderClosure;

	public constructor() {
		this.apiBaseUrl = new Optional(process.env.NX_PUBLIC_DOCUMENT_API_BASE_URL).getOrThrow(
			new AppError('DocumentProvisionService API base URL unavailable')
		);
		this.accessTokenLoader = AccessTokenLoader.createLoader(AuthHandler.get());
	}

	public async fetchPage(
		page = 1,
		size = 50,
		sort?: SortCriteria<DocumentModel>,
		filter?: FilterCriteria<DocumentModel>
	): Promise<PaginatedCollectionResponse<DocumentModel>> {
		const url = buildApiEndpointUrl(
			this.apiBaseUrl,
			'document/provision/documents',
			new Map<string, UrlPathParameter>([
				['page', page],
				['size', size]
			]),
			new Map<string, UrlQueryParameter>([
				['sort', sort],
				['filter', filter]
			])
		);
		const jwt = await this.accessTokenLoader();
		const parsedResponse = await new JsonRestRequest(jwt).get(url);
		const dto = parsedResponse.body as Array<DocumentDto>;
		const items = new DocumentResponseModelConverter().toArrayOfModel(dto);
		return { items, ...extractPaginationResponse(parsedResponse.headers) };
	}

	public async getSignedDownloadUrl(id: ModelPrimaryKey): Promise<SignedDownloadUrlModel> {
		const url = buildApiEndpointUrl(
			this.apiBaseUrl,
			'document/provision/document/{id}/get-signed-download-url',
			new Map<string, UrlPathParameter>([['id', id]]),
			new Map<string, UrlQueryParameter>([])
		);
		const jwt = await this.accessTokenLoader();
		const requestDto = {};
		const parsedResponse = await new JsonRestRequest(jwt).put(url, requestDto);
		const dto = parsedResponse.body as SignedDownloadUrlDto;
		return new SignedDownloadUrlResponseModelConverter().toModel(dto);
	}
}
