import { ReactNode } from 'react';

import { removeWhitespace } from '@chroma-x/common/core/util';

import { Icon } from '../icon/icon';
import { IconIdentifier, IconSize } from '../icon/icon.enum';

import './error-support-feedback.scss';

export type ErrorSupportFeedbackProps = {
	message: string,
	supportPhoneNumber?: string,
	supportMailAddress?: string
};

export function ErrorSupportFeedback(props: ErrorSupportFeedbackProps) {

	const { message, supportPhoneNumber, supportMailAddress } = props;

	const renderContacts = () => {
		const contacts: Array<ReactNode> = [];
		if (supportPhoneNumber) {
			contacts.push(
				<li className="error-support-feedback__contacts__item" key="supportPhoneNumber">
					<a
						href={`tel:${removeWhitespace(supportPhoneNumber)}`}
						className="error-support-feedback__contacts__item__link"
					>
						{supportPhoneNumber}
					</a>
				</li>
			);
		}
		if (supportMailAddress) {
			contacts.push(
				<li className="error-support-feedback__contacts__item" key="supportMailAddress">
					<a
						href={`mailto:${supportMailAddress}`}
						className="error-support-feedback__contacts__item__link"
					>
						{supportMailAddress}
					</a>
				</li>
			);
		}
		if (contacts.length === 0) {
			return null;
		}

		return (
			<ul className="error-support-feedback__contacts">
				{contacts}
			</ul>
		);
	};

	return (
		<div className="error-support-feedback">
			<Icon identifier={IconIdentifier.HEADSET} size={IconSize.LARGE} />
			<h1 className="error-support-feedback__message">{message}</h1>
			{renderContacts()}
		</div>
	);
}
