export enum FetchStatus {
	IDLE = 'IDLE',
	PENDING = 'PENDING',
	REFRESH_PENDING = 'REFRESH_PENDING',
	PAGING_PENDING = 'PAGING_PENDING',
	SUCCESS = 'SUCCESS',
	FAILED = 'FAILED'
}

export const fetchStatusPendingGroup = [FetchStatus.PENDING, FetchStatus.REFRESH_PENDING];
