import { ButtonHTMLAttributes, ReactNode } from 'react';

import { Icon } from '../icon/icon';
import { IconIdentifier, IconSize } from '../icon/icon.enum';

import './button-primary.scss';

export type ButtonPrimaryProps = {
	label: string,
	icon?: IconIdentifier
} & ButtonHTMLAttributes<HTMLButtonElement>;

export function ButtonPrimary(props: ButtonPrimaryProps) {
	const { type = 'button', icon, label, ...rest } = props;

	const renderLabel = (): ReactNode => {
		if (icon) {
			return (
				<span className="button-primary__label">
					{label}
					<Icon identifier={icon} size={IconSize.SMALL} />
				</span>
			);
		}
		return (
			<span className="button-primary__label">{label}</span>
		);
	};

	return (
		<button
			type={type}
			className="button-primary"
			{...rest}
		>
			{renderLabel()}
		</button>
	);
}
