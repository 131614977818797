import { Dto } from '@chroma-x/common/core/api-integration';

/**
 * Creates a mock collection from a DTO.
 *
 * @param dto The DTO to create the collection from.
 * @param amount The number of entries in the collection.
 * @param page The page number of the collection.
 * @param transformer An optional function to transform each DTO before adding it to the collection.
 * @returns An array of DTOs, each with a unique ID.
 */
export const createMockCollectionFromDto = <D extends Dto>(dto: D, amount: number, page: number, transformer?: (dto: D) => D) => {
	const mockCollection = [];
	const startIndex = (page - 1) * amount;
	for (let i = startIndex; i < startIndex + amount; i++) {
		let entry = { ...dto };
		if (transformer) {
			entry = transformer(entry);
		}
		mockCollection.push({
			...entry,
			id: String(i + 1)
		});
	}
	return mockCollection;
};
