import { removeWhitespace } from '@chroma-x/common/core/util';
import { useL10n } from '@chroma-x/frontend/core/react-l10n';
import { useTenant } from '@chroma-x/frontend/core/react-tenant-provider';
import { Icon, IconIdentifier } from '@chroma-x/frontend/core/react-ui-primitive';

import './footer.scss';

export function Footer() {

	const tenant = useTenant().get();
	const l10n = useL10n();

	const renderLinks = () => {
		if (tenant === null) {
			return null;
		}
		return (
			<ul className="footer__links">
				{tenant.links.map((link, index) => {
					return (
						<li className="footer__links__item" key={index}>
							<a
								href={link.url}
								target="_blank"
								rel="noreferrer"
								className="footer__links__item__link"
							>
								{l10n.translate(link.label)}
							</a>
						</li>
					);
				})}
			</ul>
		);
	};

	const renderSupport = () => {
		if (tenant === null || (!tenant.support.serviceMailAddress && !tenant.support.serviceHotline)) {
			return null;
		}

		let mailLink = null;
		if (tenant.support.serviceMailAddress) {
			mailLink = (
				<a
					href={`mailto:${tenant.support.serviceMailAddress}`}
					className="footer__support__link"
				>
					<Icon identifier={IconIdentifier.ENVELOPE_SIMPLE} />
					{tenant.support.serviceMailAddress}
				</a>
			);
		}

		let hotlineLink = null;
		if (tenant.support.serviceHotline) {
			hotlineLink = (
				<a
					href={`tel:${removeWhitespace(tenant.support.serviceHotline)}`}
					className="footer__support__link"
				>
					<Icon identifier={IconIdentifier.PHONE} />
					{tenant.support.serviceHotline}
				</a>
			);
		}

		return (
			<div className="footer__support">
				{l10n.translate('reactStarter.footer.support')}
				{mailLink}
				{hotlineLink}
			</div>
		);
	};

	return (
		<section className="footer">
			{renderLinks()}
			{renderSupport()}
		</section>
	);
}
