import { useEffect, useMemo } from 'react';

import { AppError } from '@chroma-x/common/core/error';
import { LogLevel } from '@chroma-x/frontend/core/logger';
import { useL10n } from '@chroma-x/frontend/core/react-l10n';
import { useLogger } from '@chroma-x/frontend/core/react-logger';

import { useTenant } from '../tenant/tenant-provider';

export function Theme() {

	const tenant = useTenant().getOrThrow(new AppError('Tenant unavailable'));
	const l10n = useL10n();
	const logger = useLogger();

	const selectedLocale = l10n.language;

	useEffect(() => {
		const theme = tenant.theme;
		if (theme === undefined) {
			return;
		}
		// Register the CSS files in the host
		const cssLinkElements = theme.cssFiles.map((cssFile) => {
			const link = document.createElement('link');
			link.type = 'text/css';
			link.rel = 'stylesheet';
			link.href = '/themes/assets/' + cssFile;
			return link;
		});
		const head = document.head;
		for (const cssLinksElement of cssLinkElements) {
			head.appendChild(cssLinksElement);
		}

		return () => {
			// Deregister the CSS files from the host
			for (const cssLinksElement of cssLinkElements) {
				head.removeChild(cssLinksElement);
			}
		};
	}, [tenant]);

	useMemo(() => {
		// Register theme literals
		const themeLiteral = tenant.theme?.literals.find((themeLiteral) => themeLiteral.localeIdentifier === selectedLocale);
		if (themeLiteral === undefined) {
			logger?.logMessage(`No tenant theme literals defined for ${selectedLocale}.`, LogLevel.DEBUG);
			return;
		}
		const locale = l10n.getLocale(themeLiteral.localeIdentifier).get();
		if (locale === null) {
			logger?.logMessage(`No locale found for ${themeLiteral.localeIdentifier}.`, LogLevel.WARN);
			return;
		}
		locale.addLiterals(themeLiteral.literals);

	}, [tenant, selectedLocale]);

	return null;

}
