import process from 'process';

import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppError } from '@chroma-x/common/core/error';
import { KeycloakApiClient } from '@chroma-x/frontend/core/keycloak-integration';
import { EventType, LogLevel } from '@chroma-x/frontend/core/logger';
import { OauthApiClient, OauthApiClientMock } from '@chroma-x/frontend/core/oauth-api-integration';
import { useFavicon, usePageTitle } from '@chroma-x/frontend/core/react';
import { AuthGuard, AuthGuardInterceptingProps } from '@chroma-x/frontend/core/react-auth-guard';
import { AuthProvider } from '@chroma-x/frontend/core/react-auth-provider';
import { useL10n } from '@chroma-x/frontend/core/react-l10n';
import { useLogger } from '@chroma-x/frontend/core/react-logger';
import { useNetworkStatus } from '@chroma-x/frontend/core/react-network-status';
import { useServiceManager } from '@chroma-x/frontend/core/react-service-provider';
import { useTenant } from '@chroma-x/frontend/core/react-tenant-provider';
import { OauthEmitterFactory } from '@chroma-x/frontend/core/react-ui-auth-emitter';
import { IllustrationIdentifier, SystemFeedback } from '@chroma-x/frontend/core/react-ui-primitive';
import { buildRouteUrl } from '@chroma-x/frontend/core/routing';

import { AppLayout } from './components/app-layout/app-layout';
import { Router } from './router/router';
import { RoutePath } from './router/routes';

export function App() {

	const navigate = useNavigate();
	const logger = useLogger();
	const l10n = useL10n();
	const serviceManager = useServiceManager();
	const tenant = useTenant().getOrThrow(new AppError('Tenant unavailable'));
	const networkStatus = useNetworkStatus();

	usePageTitle(l10n.translate('reactStarter.appName'));
	useFavicon(tenant.theme?.favicon ? `/themes/assets/${tenant.theme.favicon}` : undefined);

	let oauthApiClient: OauthApiClient;
	if (process.env.NX_PUBLIC_OAUTH_CLIENT_MOCKED === 'true') {
		oauthApiClient = new OauthApiClientMock();
	} else {
		oauthApiClient = new KeycloakApiClient(tenant.realm);
	}

	const handleAuthenticated = () => {
		logger?.logEvent('Authenticated', { type: EventType.USER }, 'oauth', LogLevel.INFO);
		navigate(buildRouteUrl(RoutePath.ROOT));
	};

	const handleReauthenticate = () => {
		logger?.logEvent('Reauthenticate', { type: EventType.USER }, 'oauth', LogLevel.INFO);
		serviceManager.resetAll();
	};

	const handleUnauthenticate = () => {
		logger?.logEvent('Unauthenticate', { type: EventType.USER }, 'oauth', LogLevel.INFO);
		serviceManager.resetAll();
		try {
			const redirectUrl = new URL(window.location.origin);
			redirectUrl.pathname = buildRouteUrl(RoutePath.GOODBYE);
			window.location.replace(redirectUrl.toString());
		} catch (e) {
			console.warn(e);
		}
	};

	const handleUnauthenticated = () => {
		logger?.logEvent('Unauthenticated', { type: EventType.USER }, 'oauth', LogLevel.INFO);
		serviceManager.resetAll();
		navigate(buildRouteUrl(RoutePath.GOODBYE));
	};

	const handleTokenReceived = () => {
		logger?.logEvent('Authenticate', { type: EventType.USER }, 'oauth', LogLevel.INFO);
	};

	const renderAuthEmitter = (): ReactElement<AuthGuardInterceptingProps> => {
		return (<OauthEmitterFactory onTokenReceived={handleTokenReceived} />);
	};

	const renderMain = () => {
		if (!networkStatus) {
			return (
				<SystemFeedback
					message={l10n.translate('reactStarter.error.networkStatus')}
					illustration={IllustrationIdentifier.OFFLINE}
				/>
			);
		}
		return (
			<AuthGuard interceptingComponent={renderAuthEmitter}>
				<Router />
			</AuthGuard>
		);
	};

	return (
		<AuthProvider
			authScope={tenant.realm}
			oauthApiClient={oauthApiClient}
			onAuthenticated={handleAuthenticated}
			onReauthenticate={handleReauthenticate}
			onUnauthenticate={handleUnauthenticate}
			onUnauthenticated={handleUnauthenticated}
		>
			<AppLayout>
				{renderMain()}
			</AppLayout>
		</AuthProvider>
	);

}
