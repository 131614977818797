import { DetailedError, SimpleError } from './error';
import { AnyErrorObject, ErrorDiscriminator, errorTypes } from './error-object';

/**
 * Creates an error object from a given Error.
 *
 * @param error - The error to create the object from.
 * @returns An object representing the error.
 */
export const createErrorObject = (error: Error): AnyErrorObject => {
	const discriminator = errorTypes.includes(error.name as ErrorDiscriminator) ? error.name as ErrorDiscriminator : 'AppError';
	const status = error instanceof SimpleError ? error.status : undefined;
	const details = error instanceof DetailedError ? error.details : undefined;
	return {
		discriminator,
		name: error.name,
		message: error.message,
		stack: error.stack,
		status,
		details
	};
};
