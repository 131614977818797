import { Nullable } from './nullable';
import { Probably } from './probably';

/**
 * A wrapper class for values that may or may not exist.
 */
export class Optional<T> {

	private readonly value: Probably<T>;

	/**
	 * Constructs a new Optional instance.
	 * @param value - The value to wrap.
	 */
	constructor(value: Probably<T>) {
		this.value = value;
	}

	/**
	 * Returns the value if it exists, otherwise returns null.
	 * @returns The value or null.
	 */
	public get(): Nullable<T> {
		return this.value ?? null;
	}

	/**
	 * Returns the value if it exists, otherwise returns the default value.
	 * @param defaultValue - The default value to return.
	 * @returns The value or the default value.
	 */
	public getOrDefault(defaultValue: T): T {
		return this.value ?? defaultValue;
	}

	/**
	 * Returns the value if it exists, otherwise returns undefined.
	 * @returns The value or undefined.
	 */
	public getOrUndefined(): T | undefined {
		return this.value ?? undefined;
	}

	/**
	 * Returns the value if it exists, otherwise throws an error.
	 * @param error - The error to throw.
	 * @returns The value.
	 * @throws The error if the value does not exist.
	 */
	public getOrThrow(error: Error): T {
		if ((this.value ?? null) === null) {
			throw error;
		}
		return this.value as T;
	}

	/**
	 * Returns the value if it exists, otherwise computes a new value.
	 * @param computation - The function to compute a new value.
	 * @returns The value or the computed value.
	 */
	public getOrCompute(computation: () => T): T {
		if ((this.value ?? null) === null) {
			return computation();
		}
		return this.value as T;
	}

}
