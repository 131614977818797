/* eslint-disable @typescript-eslint/no-explicit-any */

export const deepMergeRecords = (targetObject: Record<string, any>, ...mergeObjects: Array<Record<string, any>>): Record<string, any> => {
	const result = { ...targetObject };
	for (const mergeObject of mergeObjects) {
		for (const key in mergeObject) {
			if (key in mergeObject) {
				if (targetObject[key] instanceof Object && mergeObject[key] instanceof Object) {
					result[key] = deepMergeRecords(targetObject[key], mergeObject[key]);
				} else {
					result[key] = mergeObject[key];
				}
			}
		}
	}
	return result;
};
