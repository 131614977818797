import { ChangeEvent, ReactNode, SelectHTMLAttributes } from 'react';

import { useL10n } from '@chroma-x/frontend/core/react-l10n';

import { Icon } from '../icon/icon';
import { IconIdentifier, IconSize } from '../icon/icon.enum';

import './input-select.scss';

export type InputSelectOption<OptionValue extends string = string> = {
	label: string,
	value: OptionValue,
	disabled?: boolean
};

export type InputSelectProps<OptionValue extends string = string> = {
	label: string,
	options?: Array<InputSelectOption<OptionValue>>,
	onChange?: (value: OptionValue) => void
} & Omit<SelectHTMLAttributes<HTMLSelectElement>, 'onChange'>;

export function InputSelect<OptionValue extends string = string>(props: InputSelectProps<OptionValue>) {

	const { label, options = [], value, required = false, onChange, ...rest } = props;

	const l10n = useL10n();

	const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
		if (onChange !== undefined) {
			onChange(event.currentTarget.value as OptionValue);
		}
	};

	const renderOptions = (): ReactNode => {
		return options.map((option, index)=>{
			return (
				<option
					value={option.value}
					disabled={option.disabled}
					key={index}
				>
					{option.label}
				</option>
			);
		});
	};

	const renderIcon = () => {
		if (props.multiple) {
			return null;
		}
		return (
			<span className="input-select__field__icon">
				<Icon identifier={IconIdentifier.CARET_DOWN} size={IconSize.SMALL} />
			</span>
		);
	};

	const renderLabel = (): ReactNode => {
		const labelSuffix = required ? null : (
			<span className="input-select__label__suffix">
				{l10n.translate('reactUiPrimitive.inputSelect.optionalLabelSuffix')}
			</span>
		);
		return (
			<span className="input-select__label">
				{label}
				{labelSuffix}
			</span>
		);
	};

	return (
		<label className="input-select">
			{renderLabel()}
			<span className="input-select__field">
				<select
					className="input-select__field__select"
					value={value}
					onChange={handleChange}
					required={required}
					{...rest}
				>
					{renderOptions()}
				</select>
				{renderIcon()}
			</span>
		</label>
	);
}
