/* eslint-disable @typescript-eslint/await-thenable */
import process from 'process';

import fetchMock from 'fetch-mock';

import { createTimestampDtoFromDate } from '@chroma-x/common/core/data-type';
import { AppError } from '@chroma-x/common/core/error';
import { JsonTransportValue } from '@chroma-x/common/core/json';
import { Optional } from '@chroma-x/common/core/util';
import { buildApiEndpointUrl } from '@chroma-x/frontend/core/api-integration';
import {
	createMockBeginAndContainsMatcher,
	createMockBeginMatcher,
	createMockCollectionResponseData,
	createMockHeadResponse,
	createMockOptions,
	createMockResponse,
	createMockResponseFunction,
	preconfigureFetchMock
} from '@chroma-x/frontend/core/api-integration-mock';
import { DocumentDto } from '@chroma-x/frontend/domain/document/api-integration';
import { DocumentType } from '@chroma-x/frontend/domain/document/domain-model';

import { documentDtoMockData, signedDownloadUrlDtoMockData } from '../mock-data';

export class DocumentProvisionApiFetchMockConfiguration {

	private static mockConfigured = false;

	private readonly apiBaseUrl: string;

	public constructor() {
		this.apiBaseUrl = new Optional(process.env.NX_PUBLIC_DOCUMENT_API_BASE_URL)
			.getOrThrow(new AppError('OKR API base URL unavailable'));
	}

	public apply() {
		if (DocumentProvisionApiFetchMockConfiguration.mockConfigured) {
			return;
		}

		preconfigureFetchMock();

		fetchMock
			.head(
				createMockBeginMatcher(buildApiEndpointUrl(this.apiBaseUrl, 'document/provision/documents'), true),
				createMockHeadResponse(204, [
					{ key: 'X-Pagination-Page', values: ['1'] },
					{ key: 'X-Pagination-Max-Pages', values: ['3'] },
					{ key: 'X-Pagination-Total-Items', values: ['128'] }
				]),
				createMockOptions()
			)
			/*
			// Error state
			.get(
				createMockBeginMatcher(buildApiEndpointUrl(this.apiBaseUrl, 'document/provision/documents', new Map([['page', 1]])), true),
				createMockErrorResponse(403),
				createMockOptions()
			)
			*/
			/*
			// Empty state
			.get(
				createMockBeginMatcher(buildApiEndpointUrl(this.apiBaseUrl, 'document/provision/documents', new Map([['page', 1]])), true),
				createMockResponse([], 200, [
					{ key: 'X-Pagination-Page', values: ['1'] },
					{ key: 'X-Pagination-Max-Pages', values: ['3'] },
					{ key: 'X-Pagination-Total-Items', values: ['128'] }
				]),
				createMockOptions(2000)
			)
			*/
			.get(
				createMockBeginMatcher(buildApiEndpointUrl(this.apiBaseUrl, 'document/provision/documents', new Map([['page', 1]])), true),
				createMockResponse(createMockCollectionResponseData(documentDtoMockData, 1, 50, 50, this.modifyCollectionItem), 200, [
					{ key: 'X-Pagination-Page', values: ['1'] },
					{ key: 'X-Pagination-Max-Pages', values: ['3'] },
					{ key: 'X-Pagination-Total-Items', values: ['128'] }
				]),
				createMockOptions()
			)
			.get(
				createMockBeginMatcher(buildApiEndpointUrl(this.apiBaseUrl, 'document/provision/documents', new Map([['page', 2]])), true),
				createMockResponse(createMockCollectionResponseData(documentDtoMockData, 2, 50, 50, this.modifyCollectionItem), 200, [
					{ key: 'X-Pagination-Page', values: ['2'] },
					{ key: 'X-Pagination-Max-Pages', values: ['3'] },
					{ key: 'X-Pagination-Total-Items', values: ['128'] }
				]),
				createMockOptions()
			)
			.get(
				createMockBeginMatcher(buildApiEndpointUrl(this.apiBaseUrl, 'document/provision/documents', new Map([['page', 3]])), true),
				createMockResponse(createMockCollectionResponseData(documentDtoMockData, 3, 50, 28, this.modifyCollectionItem), 200, [
					{ key: 'X-Pagination-Page', values: ['3'] },
					{ key: 'X-Pagination-Max-Pages', values: ['3'] },
					{ key: 'X-Pagination-Total-Items', values: ['128'] }
				]),
				createMockOptions()
			)
			.put(
				createMockBeginAndContainsMatcher(
					buildApiEndpointUrl(this.apiBaseUrl, 'document/provision/document/'),
					'get-signed-download-url',
					true
				),
				createMockResponseFunction(
					async (url): Promise<JsonTransportValue> => {
						const requestId = new URL(url).pathname
							.split('/')
							.filter(pathParam => pathParam.length > 0)
							.at(-2);
						return {
							...signedDownloadUrlDtoMockData,
							documentId: requestId
						};
					}
				),
				createMockOptions()
			);
		DocumentProvisionApiFetchMockConfiguration.mockConfigured = true;
	}

	private modifyCollectionItem(responseData: DocumentDto, index: number) {
		const fixedDate = '2024-09-01 11:30:00';
		const billingPeriod = new Date(fixedDate);
		billingPeriod.setMonth(billingPeriod.getMonth() - index);
		const billingPeriodYear = billingPeriod.getFullYear().toString();
		const billingPeriodMonth = (billingPeriod.getMonth() + 1).toString().padStart(2, '0');
		responseData.billingPeriod = `${billingPeriodYear}-${billingPeriodMonth}`;

		const createdAt = new Date(fixedDate);
		createdAt.setMonth(createdAt.getMonth() - index + 1);
		createdAt.setDate(1);
		responseData.createdAt = createTimestampDtoFromDate(createdAt);

		responseData.documentType = (index % 2 === 0) ? DocumentType.INVOICE : DocumentType.ITEMIZED_BILL;

		if (responseData.documentType === DocumentType.INVOICE) {
			responseData.plannedDeletionDate = undefined;
		}

		if (index < 4) {
			responseData.downloaded = false;
		}

		return responseData;
	}

}
