import { ModelPrimaryKey } from '@chroma-x/common/core/api-integration';
import { l10nLiteralFromEnumValue } from '@chroma-x/common/core/util';
import { useL10n } from '@chroma-x/frontend/core/react-l10n';
import {
	CollectionItem,
	CollectionItemCell,
	CollectionItemCellAlign,
	CollectionItemCellGroup,
	CollectionItemCellGroupAlign,
	CollectionItemCellWidth,
	Icon,
	IconIdentifier,
	Tag
} from '@chroma-x/frontend/core/react-ui-primitive';
import { DocumentModel } from '@chroma-x/frontend/domain/document/domain-model';
import './document-collection-item.scss';

export type DocumentCollectionItemProps = {
	document: DocumentModel,
	onClick: (documentId: ModelPrimaryKey) => void
};

export function DocumentCollectionItem(props: DocumentCollectionItemProps) {

	const { document, onClick } = props;

	const l10n = useL10n();

	const handleClick = () => {
		onClick(document.id);
	};

	const formatBillingPeriod = (): string => {
		const date = new Date(document.billingPeriod + '-01');
		return l10n.formatMonthName(date) + ' ' + l10n.formatYear(date);
	};

	const formatPlannedDeletionDate = (): string => {
		if (document.plannedDeletionDate) {
			return l10n.translate(
				'document.reactUiDomain.documentCollection.plannedDeletionDate',
				new Map([['plannedDeletionDate', l10n.formatTimestampDate(document.plannedDeletionDate)]])
			);
		}
		return '';
	};

	const renderPlannedDeletionDate = () => {
		if (!document.plannedDeletionDate) {
			return null;
		}
		return (
			<span className="document-collection-item__text">
				{formatPlannedDeletionDate()}
			</span>
		);
	};

	const renderTag = () => {
		if (!document.downloaded) {
			return (
				<Tag label={l10n.translate('document.reactUiDomain.documentCollection.newTag')} />
			);
		}
		return null;
	};

	return (
		<CollectionItem onClick={handleClick}>
			<CollectionItemCell width={CollectionItemCellWidth.WIDTH_WIDE}>
				<CollectionItemCellGroup align={CollectionItemCellGroupAlign.ALIGN_AUTO}>
					<CollectionItemCell width={CollectionItemCellWidth.WIDTH_WIDE}>
						<span className="document-collection-item__text document-collection-item__text--emphasized">
							{l10n.translate(l10nLiteralFromEnumValue(
								document.documentType,
								'document.reactUiDomain.documentCollection.documentType'
							))}
						</span>
					</CollectionItemCell>
					<CollectionItemCell width={CollectionItemCellWidth.WIDTH_DEFAULT}>
						<span className="document-collection-item__text">
							{formatBillingPeriod()}
						</span>
					</CollectionItemCell>
					<CollectionItemCell width={CollectionItemCellWidth.WIDTH_WIDE}>
						{renderPlannedDeletionDate()}
					</CollectionItemCell>
				</CollectionItemCellGroup>
			</CollectionItemCell>
			<CollectionItemCell width={CollectionItemCellWidth.WIDTH_FIXED} baseWidth={50} align={CollectionItemCellAlign.ALIGN_RIGHT}>
				{renderTag()}
			</CollectionItemCell>
			<CollectionItemCell>
				<Icon identifier={IconIdentifier.DOWNLOAD_SIMPLE} />
			</CollectionItemCell>
		</CollectionItem>
	);

}
