export enum HttpMethod {
	OPTIONS = 'OPTIONS',
	HEAD = 'HEAD',
	GET = 'GET',
	QUERY = 'QUERY',
	POST = 'POST',
	PUT = 'PUT',
	PATCH = 'PATCH',
	DELETE = 'DELETE'
}
