import { CustomError } from 'ts-custom-error';

/**
 * A custom error class that extends the built-in Error class.
 *
 * This class adds a `name` property to the error, which is used to identify the type of error.
 * It also allows for an optional `status` and `previousError` to be set.
 */
export class SimpleError extends CustomError {
	/**
	 * Creates a new SimpleError.
	 *
	 * @param name - The name of the error.
	 * @param message - An optional message to be included with the error.
	 * @param status - An optional status code to be included with the error.
	 * @param previousError - An optional previous error that caused this error.
	 */
	constructor(name: string, message?: string, public status?: number, public previousError?: Error) {
		super(message);
		Object.defineProperty(this, 'name', { value: name });
	}
}

export type ErrorDetail = {
	message: string,
	reference?: string
};

/**
 * A custom error class that extends the SimpleError class.
 *
 * This class adds a `details` property to the error, which is an array of ErrorDetail objects.
 * It also allows for an optional `status` and `previousError` to be set.
 */
export class DetailedError extends SimpleError {
	/**
	 * Creates a new DetailedError.
	 *
	 * @param name - The name of the error.
	 * @param message - An optional message to be included with the error.
	 * @param status - An optional status code to be included with the error.
	 * @param details - An optional array of ErrorDetail objects.
	 * @param previousError - An optional previous error that caused this error.
	 */
	constructor(
		name: string,
		message?: string,
		public override status?: number,
		public details: Array<ErrorDetail> = [],
		public override previousError?: Error
	) {
		super(name, message, status, previousError);
	}
}

export class AppError extends SimpleError {
	constructor(message?: string, status?: number, previousError?: Error) {
		super('AppError', message, status, previousError);
	}
}

export class StoreError extends SimpleError {
	constructor(message?: string, status?: number, previousError?: Error) {
		super('StoreError', message, status, previousError);
	}
}

export class NotFoundError extends SimpleError {
	constructor(message?: string, status?: number, previousError?: Error) {
		super('NotFoundError', message, status, previousError);
	}
}

export class NetworkError extends SimpleError {
	constructor(message?: string, status?: number, previousError?: Error) {
		super('NetworkError', message, status, previousError);
	}
}

export class TimeoutError extends SimpleError {
	constructor(message?: string, status?: number, previousError?: Error) {
		super('TimeoutError', message, status, previousError);
	}
}

export class ApiError extends SimpleError {
	constructor(message?: string, status?: number, previousError?: Error) {
		super('ApiError', message, status, previousError);
	}
}

export class ValidationError extends DetailedError {
	constructor(message?: string, status?: number, details: Array<ErrorDetail> = [], previousError?: Error) {
		super('ValidationError', message, status, details, previousError);
	}
}

export class AuthenticationRequiredError extends SimpleError {
	constructor(message?: string, status?: number, previousError?: Error) {
		super('AuthenticationRequiredError', message, status, previousError);
	}
}

export class AuthenticationFailedError extends SimpleError {
	constructor(message?: string, status?: number, previousError?: Error) {
		super('AuthenticationFailedError', message, status, previousError);
	}
}

export class AccessDeniedError extends SimpleError {
	constructor(message?: string, status?: number, previousError?: Error) {
		super('AccessDeniedError', message, status, previousError);
	}
}

export class MethodNotAllowedError extends SimpleError {
	constructor(message?: string, status?: number, previousError?: Error) {
		super('MethodNotAllowedError', message, status, previousError);
	}
}

export class NotAcceptableError extends SimpleError {
	constructor(message?: string, status?: number, previousError?: Error) {
		super('NotAcceptableError', message, status, previousError);
	}
}

export class PreconditionFailedError extends SimpleError {
	constructor(message?: string, status?: number, previousError?: Error) {
		super('PreconditionFailedError', message, status, previousError);
	}
}

export class UnsupportedMediaTypeError extends SimpleError {
	constructor(message?: string, status?: number, previousError?: Error) {
		super('UnsupportedMediaTypeError', message, status, previousError);
	}
}

export class UnprocessableEntityError extends SimpleError {
	constructor(message?: string, status?: number, previousError?: Error) {
		super('UnprocessableEntityError', message, status, previousError);
	}
}

export class WebSocketError extends SimpleError {
	constructor(message?: string, status?: number, previousError?: Error) {
		super('WebSocketError', message, status, previousError);
	}
}
