/* eslint-disable jsdoc/check-tag-names */
import { Draft } from 'immer';

import { ModelPrimaryKey } from '@chroma-x/common/core/api-integration';
import { Model } from '@chroma-x/frontend/core/domain-model';

import { CollectionServiceFoundation } from './collection-service-foundation';
import { GetArg, SetArg } from '../zustand-util';

/**
 * Adds or updates a collection entity in the store.
 *
 * @param get - A function to get the current state.
 * @param set - A function to set the state.
 * @param id - The ID of the entity.
 * @param model - The model to add or update.
 *
 * @typeParam S - The type of the collection service.
 * @typeParam M - The type of the model.
 * @typeParam C - The type of the collection.
 */
export const addOrUpdateCollectionEntity = <
	S extends CollectionServiceFoundation<M, C>,
	M extends Model,
	C extends Record<string, unknown>
>(get: GetArg<S, M, C>, set: SetArg<S, M, C>, id: ModelPrimaryKey, model: Draft<M>) => {
	const currentState = get();
	const index = currentState.data.models.findIndex((entry): boolean => {
		return entry.id === id;
	});
	if (index >= 0) {
		set((state) => {
			state.data.models[index] = model;
		});
	} else {
		set((state) => {
			state.data.models.push(model);
		});
	}
};

/**
 * Adds a collection entity to the store.
 *
 * @param set - A function to set the state.
 * @param model - The model to add.
 *
 * @typeParam S - The type of the collection service.
 * @typeParam M - The type of the model.
 * @typeParam C - The type of the collection.
 */
export const addCollectionEntity = <
	S extends CollectionServiceFoundation<M, C>,
	M extends Model,
	C extends Record<string, unknown>
>(set: SetArg<S, M, C>, model: Draft<M>) => {
	set((state) => {
		state.data.models.push(model);
		state.data.createdEntity = model;
	});
};

/**
 * Updates a collection entity in the store.
 *
 * @param get - A function to get the current state.
 * @param set - A function to set the state.
 * @param id - The ID of the entity.
 * @param model - The updated model.
 *
 * @typeParam S - The type of the collection service.
 * @typeParam M - The type of the model.
 * @typeParam C - The type of the collection.
 */
export const updateCollectionEntity = <
	S extends CollectionServiceFoundation<M, C>,
	M extends Model,
	C extends Record<string, unknown>
>(get: GetArg<S, M, C>, set: SetArg<S, M, C>, id: ModelPrimaryKey, model: Draft<M>) => {
	const currentState = get();
	const index = currentState.data.models.findIndex((entry): boolean => {
		return entry.id === id;
	});
	if (index >= 0) {
		set((state) => {
			state.data.models[index] = model;
		});
	}
};

/**
 * Removes a collection entity from the store.
 *
 * @param get - A function to get the current state.
 * @param set - A function to set the state.
 * @param id - The ID of the entity.
 *
 * @typeParam S - The type of the collection service.
 * @typeParam M - The type of the model.
 * @typeParam C - The type of the collection.
 */
export const removeCollectionEntity = <
	S extends CollectionServiceFoundation<M, C>,
	M extends Model,
	C extends Record<string, unknown>
>(get: GetArg<S, M, C>, set: SetArg<S, M, C>, id: ModelPrimaryKey) => {
	const currentState = get();
	const index = currentState.data.models.findIndex((entry): boolean => {
		return entry.id === id;
	});
	if (index >= 0) {
		set((state) => {
			state.data.models.splice(index, 1);
		});
	}
};
