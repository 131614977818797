import { FilterCriteria, FilterCriterion } from '@chroma-x/common/core/api-integration';
import { Nullable } from '@chroma-x/common/core/util';

import { FilterOptionGroup } from './filter-option-group';

export const filterOptionsFromFilterCriteria = <FilterOption extends string, Model>(
	filterOptionGroups: Array<FilterOptionGroup<FilterOption, Model>>,
	filterCriteria: FilterCriteria<Model>
): Array<FilterOption> => {
	let filterOptions: Array<FilterOption> = [];
	for (const filterOptionGroup of filterOptionGroups) {
		filterOptions = [...filterOptions, ...filterOptionGroup.buildFilterOptions(filterCriteria)];
	}
	return filterOptions;
};

export const filterCriteriaFromFilterOptions = <FilterOption extends string, Model>(
	filterOptionGroups: Array<FilterOptionGroup<FilterOption, Model>>,
	filterOptions: Array<FilterOption>
): FilterCriteria<Model> => {
	return filterOptionGroups
		.map((filterGroup): Nullable<FilterCriterion<Model>> => {
			return filterGroup.buildFilterCriteria(filterOptions);
		})
		.filter((filterCriteriaEntry): filterCriteriaEntry is FilterCriterion<Model> => {
			return filterCriteriaEntry !== null;
		});
};
