import { ResponseModelConverter } from './response-model-converter';

export abstract class AbstractResponseModelConverter<Model = unknown, Dto = unknown>
	implements ResponseModelConverter<Model, Dto> {

	/**
	 * Converts a DTO to a single model.
	 *
	 * @param dto - The DTO to convert.
	 * @returns The converted model.
	 */
	public abstract toModel(dto: Dto): Model;

	/**
	 * Converts an array of DTOs to an array of models.
	 *
	 * @param dtos - The array of DTOs to convert.
	 * @returns The array of converted models.
	 */
	public toArrayOfModel(dtos: Array<Dto>): Array<Model> {
		return dtos.map(dto => this.toModel(dto));
	}

}
