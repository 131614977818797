import './system-feedback-description.scss';

export type SystemFeedbackDescriptionProps = {
	description: string
};

export function SystemFeedbackDescription(props: SystemFeedbackDescriptionProps) {

	const { description } = props;

	return (
		<p className="system-feedback-description">
			{description}
		</p>
	);
}
