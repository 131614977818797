import { useNavigate } from 'react-router-dom';

import { useL10n } from '@chroma-x/frontend/core/react-l10n';
import { ButtonPrimary, IllustrationIdentifier, SystemFeedback } from '@chroma-x/frontend/core/react-ui-primitive';
import { buildRouteUrl } from '@chroma-x/frontend/core/routing';

import { AppLayout } from './components/app-layout/app-layout';
import { RoutePath } from './router/routes';

export function Goodbye() {

	const l10n = useL10n();
	const navigate = useNavigate();

	const handleHome = () => {
		navigate(buildRouteUrl(RoutePath.ROOT));
	};

	return (
		<AppLayout>
			<SystemFeedback message={l10n.translate('reactStarter.goodBye.message')} illustration={IllustrationIdentifier.SUCCESS}>
				<ButtonPrimary
					label={l10n.translate('reactStarter.goodBye.homeLabel')}
					onClick={handleHome}
				/>
			</SystemFeedback>
		</AppLayout>
	);

}
