export enum PaginationResponseHeader {
	PAGE = 'x-pagination-page',
	MAX_PAGES = 'x-pagination-max-pages',
	TOTAL_ITEMS = 'x-pagination-total-items'
}

export type CollectionPaginationResponse = {
	currentPage: number,
	maxPages: number,
	totalItems?: number
};

export type PaginatedCollectionResponse<Model> = {
	items: Array<Model>
} & CollectionPaginationResponse;

/**
 * Extracts pagination information from the response headers.
 *
 * @param responseHeaders - The response headers.
 * @returns The extracted pagination information.
 *
 * @example
 * const responseHeaders = new Map<string, string>();
 * responseHeaders.set(PaginationResponseHeader.PAGE, '2');
 * responseHeaders.set(PaginationResponseHeader.MAX_PAGES, '10');
 * responseHeaders.set(PaginationResponseHeader.TOTAL_ITEMS, '50');
 *
 * const paginationResponse = extractPaginationResponse(responseHeaders);
 * console.log(paginationResponse.currentPage); // Output: 2
 * console.log(paginationResponse.maxPages); // Output: 10
 * console.log(paginationResponse.totalItems); // Output: 50
 */
export const extractPaginationResponse = (responseHeaders: Map<string, string>): CollectionPaginationResponse => {
	const currentPage = parseInt(responseHeaders.get(PaginationResponseHeader.PAGE) ?? '1');
	const maxPages = parseInt(responseHeaders.get(PaginationResponseHeader.MAX_PAGES) ?? '1');
	const totalItemsValue = responseHeaders.get(PaginationResponseHeader.TOTAL_ITEMS);
	const totalItems = totalItemsValue ? parseInt(totalItemsValue) : undefined;
	return { currentPage, maxPages, totalItems };
};
