export enum CollectionItemCellWidth {
	WIDTH_FIXED = 'WIDTH_FIXED',
	WIDTH_CONTENT = 'WIDTH_CONTENT',
	WIDTH_NARROW = 'WIDTH_NARROW',
	WIDTH_DEFAULT = 'WIDTH_DEFAULT',
	WIDTH_WIDE = 'WIDTH_WIDE'
}

export enum CollectionItemCellAlign {
	ALIGN_LEFT = 'ALIGN_LEFT',
	ALIGN_RIGHT = 'ALIGN_RIGHT'
}
