import { ErrorResponseDto } from '@chroma-x/common/core/api-integration';
import {
	AccessDeniedError,
	ApiError,
	AppError,
	MethodNotAllowedError,
	NotAcceptableError,
	NotFoundError,
	PreconditionFailedError,
	TimeoutError,
	UnprocessableEntityError,
	UnsupportedMediaTypeError,
	ValidationError
} from '@chroma-x/common/core/error';

import { ErrorResponseHandler } from './error-response-handler';

/**
 * Class that handles error responses and generates specific error instances based on the response status code.
 */
export class DefaultErrorResponseHandler implements ErrorResponseHandler {

	/**
	 * Handles the error response and generates a specific error instance based on the response status code.
	 * Default NestJS HttpException status codes are covered.
	 * @param responseStatus The HTTP response status code.
	 * @param responseBody The JSON exception transport value containing error details.
	 * @returns An error instance corresponding to the response status code.
	 */
	public handleErrorResponse(responseStatus: number, responseBody: ErrorResponseDto): Error {
		if (responseStatus === 400 || responseStatus === 409) {
			return new ValidationError(
				responseBody?.message ?? 'Invalid request',
				responseBody?.statusCode,
				responseBody?.details
			);
		}
		if (responseStatus === 403) {
			return new AccessDeniedError(
				responseBody?.message ?? 'Forbidden',
				responseBody?.statusCode
			);
		}
		if (responseStatus === 404 || responseStatus === 410) {
			return new NotFoundError(
				responseBody?.message ?? 'Not found',
				responseBody?.statusCode
			);
		}
		if (responseStatus === 405) {
			return new MethodNotAllowedError(
				responseBody?.message ?? 'Method not allowed',
				responseBody?.statusCode
			);
		}
		if (responseStatus === 406) {
			return new NotAcceptableError(
				responseBody?.message ?? 'Not acceptable',
				responseBody?.statusCode
			);
		}
		if (responseStatus === 408) {
			return new TimeoutError(
				responseBody?.message ?? 'Request timeout',
				responseBody?.statusCode
			);
		}
		if (responseStatus === 412) {
			return new PreconditionFailedError(
				responseBody?.message ?? 'Precondition failed',
				responseBody?.statusCode
			);
		}
		if (responseStatus === 415) {
			return new UnsupportedMediaTypeError(
				responseBody?.message ?? 'Unsupported media type',
				responseBody?.statusCode
			);
		}
		if (responseStatus === 422) {
			return new UnprocessableEntityError(
				responseBody?.message ?? 'Unprocessable entity',
				responseBody?.statusCode
			);
		}

		if (responseStatus >= 400) {
			return new ApiError(
				'Unexpected response',
				responseStatus
			);
		}

		return new AppError('HTTP connector error');
	}

}
