import { ActionStatus } from '../meta/action-status';

export enum Action {
	MUTATE = 'MUTATE',
	UPDATE = 'UPDATE',
	CREATE = 'CREATE',
	DELETE = 'DELETE',
	COMMAND = 'COMMAND'
}

/**
 * Returns the pending status for a given action.
 * @param action - The action for which to get the pending status.
 * @returns The pending status for the given action.
 */
export const getActionPendingStatusFromAction = (action: Action): ActionStatus => {
	switch (action) {
		case Action.CREATE:
			return ActionStatus.CREATE_PENDING;
		case Action.MUTATE:
			return ActionStatus.MUTATE_PENDING;
		case Action.UPDATE:
			return ActionStatus.UPDATE_PENDING;
		case Action.DELETE:
			return ActionStatus.DELETE_PENDING;
		case Action.COMMAND:
			return ActionStatus.COMMAND_PENDING;
	}
};

/**
 * Returns the success status for a given action.
 * @param action - The action for which to get the success status.
 * @returns The success status for the given action.
 */
export const getActionSuccessStatusFromAction = (action: Action): ActionStatus => {
	switch (action) {
		case Action.CREATE:
			return ActionStatus.CREATE_SUCCESS;
		case Action.MUTATE:
			return ActionStatus.MUTATE_SUCCESS;
		case Action.UPDATE:
			return ActionStatus.UPDATE_SUCCESS;
		case Action.DELETE:
			return ActionStatus.DELETE_SUCCESS;
		case Action.COMMAND:
			return ActionStatus.COMMAND_SUCCESS;
	}
};

/**
 * Returns the failed status for a given action.
 * @param action - The action for which to get the failed status.
 * @returns The failed status for the given action.
 */
export const getActionFailedStatusFromAction = (action: Action): ActionStatus => {
	switch (action) {
		case Action.CREATE:
			return ActionStatus.CREATE_FAILED;
		case Action.MUTATE:
			return ActionStatus.MUTATE_FAILED;
		case Action.UPDATE:
			return ActionStatus.UPDATE_FAILED;
		case Action.DELETE:
			return ActionStatus.DELETE_FAILED;
		case Action.COMMAND:
			return ActionStatus.COMMAND_FAILED;
	}
};
