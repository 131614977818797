/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	FilterCriteria,
	isFilterCriteria,
	isSortCriteria,
	SortCriteria,
	UrlFilterCriteria,
	UrlSortCriteria
} from '@chroma-x/common/core/api-integration';
import { isNumber, isString, trimFromLeft, trimFromRight } from '@chroma-x/common/core/util';

export type UrlPathParameter = string | number | null | undefined;
export type UrlQueryParameter = SortCriteria<any> | FilterCriteria<any> | string | number | null | undefined;

/**
 * Builds a URL for an API endpoint.
 *
 * @param baseUrl - The base URL for the API.
 * @param urlString - The URL string for the API endpoint.
 * @param pathParams - Optional map of path parameters that are replaced or appended.
 * @param queryParams - Optional map of query parameters.
 * @returns The endpoint URL
 *
 * @example
 * const baseUrl = 'https://api.example.com';
 * const urlString = '/users/{id}';
 * const pathParams = new Map<string, UrlPathParameter>();
 * pathParams.set('id', '123');
 * pathParams.set('method', 'notify');
 * const queryParams = new Map<string, UrlQueryParameter>();
 * queryParams.set('exampleString', 'test');
 * queryParams.set('exampleNumber', 12);
 *
 * const url = buildApiEndpointUrl(baseUrl, urlString, pathParams, queryParams);
 * console.log(url); // Output: https://api.example.com/users/123/notify?exampleString=test&exampleNumber=12
 */
export const buildApiEndpointUrl = (
	baseUrl: string,
	urlString: string,
	pathParams?: Map<string, UrlPathParameter>,
	queryParams?: Map<string, UrlQueryParameter>
): string => {
	if (pathParams) {
		for (const [name, pathParam] of pathParams.entries()) {
			let pathParamValue: string;
			if (pathParam === undefined || pathParam === null) {
				pathParamValue = '';
			} else if (isNumber(pathParam)) {
				pathParamValue = pathParam.toString();
			} else {
				pathParamValue = pathParam;
			}
			if (urlString.includes(`{${name}}`)) {
				urlString = urlString.replace(`{${name}}`, pathParamValue);
			} else if (pathParamValue !== '') {
				urlString = trimFromRight(urlString, '/') + '/' + pathParamValue;
			}
		}
	}
	const url = new URL(trimFromRight(baseUrl, '/') + '/' + trimFromLeft(urlString, '/'));
	if (queryParams) {
		for (const [name, queryParam] of queryParams.entries()) {
			if (queryParam === null || queryParam === undefined) {
				continue;
			}
			if (isString(queryParam)) {
				url.searchParams.set(name, queryParam);
				continue;
			}
			if (isNumber(queryParam)) {
				url.searchParams.set(name, queryParam.toString());
				continue;
			}
			if (isSortCriteria(queryParam)) {
				url.searchParams.set(name, new UrlSortCriteria().buildUrlSortCriteria(queryParam));
				continue;
			}
			if (isFilterCriteria(queryParam)) {
				url.searchParams.set(name, new UrlFilterCriteria().buildUrlFilterCriteria(queryParam));
			}
		}
	}
	return url.toString();
};
