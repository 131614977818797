export enum ActionStatus {
	IDLE = 'IDLE',
	MUTATE_PENDING = 'MUTATE_PENDING',
	UPDATE_PENDING = 'UPDATE_PENDING',
	CREATE_PENDING = 'CREATE_PENDING',
	DELETE_PENDING = 'DELETE_PENDING',
	COMMAND_PENDING = 'COMMAND_PENDING',
	MUTATE_SUCCESS = 'MUTATE_SUCCESS',
	UPDATE_SUCCESS = 'UPDATE_SUCCESS',
	CREATE_SUCCESS = 'CREATE_SUCCESS',
	DELETE_SUCCESS = 'DELETE_SUCCESS',
	COMMAND_SUCCESS = 'COMMAND_SUCCESS',
	MUTATE_FAILED = 'MUTATE_FAILED',
	UPDATE_FAILED = 'UPDATE_FAILED',
	CREATE_FAILED = 'CREATE_FAILED',
	DELETE_FAILED = 'DELETE_FAILED',
	COMMAND_FAILED = 'COMMAND_FAILED'
}

export const actionStatusPendingGroup = [ActionStatus.CREATE_PENDING, ActionStatus.MUTATE_PENDING, ActionStatus.UPDATE_PENDING, ActionStatus.DELETE_PENDING, ActionStatus.COMMAND_PENDING];

export const actionStatusSuccessGroup = [ActionStatus.CREATE_SUCCESS, ActionStatus.MUTATE_SUCCESS, ActionStatus.UPDATE_SUCCESS, ActionStatus.DELETE_SUCCESS, ActionStatus.COMMAND_SUCCESS];

export const actionStatusFailedGroup = [ActionStatus.CREATE_FAILED, ActionStatus.MUTATE_FAILED, ActionStatus.UPDATE_FAILED, ActionStatus.DELETE_FAILED, ActionStatus.COMMAND_FAILED];
