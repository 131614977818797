import { useMemo } from 'react';

import {
	AuthenticationFailedError,
	AuthenticationRequiredError,
	restoreError,
	SimpleError
} from '@chroma-x/common/core/error';
import { Model } from '@chroma-x/frontend/core/domain-model';
import { CollectionServiceFoundation, FetchStatus } from '@chroma-x/frontend/core/service';

/**
 * Custom hook to handle failed fetches of a collection.
 *
 * @template M - The type of the model.
 * @param state - The state object of the collection.
 * @param fetchFailedError - The error to throw if the fetch fails.
 *
 * @throws Throws the fetchFailedError if the fetch status is FAILED.
 *
 */
export const useFetchCollectionFailedHandler = <M extends Model>(
	state: CollectionServiceFoundation<M, Record<string, unknown>>,
	fetchFailedError: Error
): void => {
	// Resolve a fetch status FAILED if remaining from the last render
	const fetchStatus = state.meta.queryFetchStatus();

	useMemo(() => {
		if (fetchStatus === FetchStatus.FAILED) {
			state.meta.resolveFetchStatus();
		}
		return true;
	}, []);

	// Register callback to handle fetch error if coming up
	const errorObject = state.meta.queryFetchError();
	useMemo(() => {
		if (errorObject === null) {
			return;
		}
		const error = restoreError(errorObject);
		if (error instanceof AuthenticationFailedError || error instanceof AuthenticationRequiredError) {
			throw error;
		}
		if (fetchFailedError instanceof SimpleError) {
			fetchFailedError.previousError = error;
		}
		throw fetchFailedError;
	}, [fetchStatus]);
};
