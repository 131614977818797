import { SimpleError } from '@chroma-x/common/core/error';

export class FetchError extends SimpleError {
	public constructor(message?: string, status?: number, previousError?: Error) {
		super('FetchError', message, status, previousError);
	}
}

export class MutateError extends SimpleError {
	public constructor(message?: string, status?: number, previousError?: Error) {
		super('MutateError', message, status, previousError);
	}
}

export class CreateError extends SimpleError {
	public constructor(message?: string, status?: number, previousError?: Error) {
		super('CreateError', message, status, previousError);
	}
}

export class DeleteError extends SimpleError {
	public constructor(message?: string, status?: number, previousError?: Error) {
		super('DeleteError', message, status, previousError);
	}
}
