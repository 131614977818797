import { Route, Routes } from 'react-router-dom';

import { buildRouteUrl } from '@chroma-x/frontend/core/routing';
import {
		DocumentCollectionView,
		RoutePath as DocumentRoutePath
} from '@chroma-x/frontend/domain/document/react-ui-domain';

import { RoutePath } from './routes';
import { NotFoundView } from '../views/not-found-view';

export function Router() {

	return (
		<Routes>
			<Route path={buildRouteUrl(RoutePath.ROOT)} element={<DocumentCollectionView />} />
			<Route path={buildRouteUrl(DocumentRoutePath.DOCUMENT_DOCUMENTS)} element={<DocumentCollectionView />} />
			<Route path="*" element={<NotFoundView />} />
		</Routes>
	);

}
