import { CustomError } from 'ts-custom-error';

import {
	AccessDeniedError,
	ApiError,
	AppError,
	AuthenticationFailedError,
	AuthenticationRequiredError,
	MethodNotAllowedError,
	NetworkError,
	NotAcceptableError,
	NotFoundError,
	PreconditionFailedError,
	StoreError,
	TimeoutError,
	UnprocessableEntityError,
	UnsupportedMediaTypeError,
	ValidationError,
	WebSocketError
} from './error';
import { AnyErrorObject } from './error-object';

/**
 * Restores an error from an error object.
 *
 * @param errorObject - The error object.
 * @returns The restored error.
 */
export const restoreError = (errorObject: AnyErrorObject): CustomError => {
	let error;
	switch (errorObject.discriminator) {
		case 'AppError':
			error = new AppError(errorObject.message, errorObject.status);
			break;
		case 'StoreError':
			error = new StoreError(errorObject.message, errorObject.status);
			break;
		case 'NotFoundError':
			error = new NotFoundError(errorObject.message, errorObject.status);
			break;
		case 'NetworkError':
			error = new NetworkError(errorObject.message, errorObject.status);
			break;
		case 'TimeoutError':
			error = new TimeoutError(errorObject.message, errorObject.status);
			break;
		case 'ApiError':
			error = new ApiError(errorObject.message, errorObject.status);
			break;
		case 'ValidationError':
			error = new ValidationError(errorObject.message, errorObject.status, errorObject.details);
			break;
		case 'AuthenticationRequiredError':
			error = new AuthenticationRequiredError(errorObject.message, errorObject.status);
			break;
		case 'AuthenticationFailedError':
			error = new AuthenticationFailedError(errorObject.message, errorObject.status);
			break;
		case 'AccessDeniedError':
			error = new AccessDeniedError(errorObject.message, errorObject.status);
			break;
		case 'MethodNotAllowedError':
			error = new MethodNotAllowedError(errorObject.message, errorObject.status);
			break;
		case 'NotAcceptableError':
			error = new NotAcceptableError(errorObject.message, errorObject.status);
			break;
		case 'PreconditionFailedError':
			error = new PreconditionFailedError(errorObject.message, errorObject.status);
			break;
		case 'UnsupportedMediaTypeError':
			error = new UnsupportedMediaTypeError(errorObject.message, errorObject.status);
			break;
		case 'UnprocessableEntityError':
			error = new UnprocessableEntityError(errorObject.message, errorObject.status);
			break;
		case 'WebSocketError':
			error = new WebSocketError(errorObject.message, errorObject.status);
			break;
	}
	error.stack = errorObject.stack;
	return error;
};
