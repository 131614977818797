import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { LogLevel } from '@chroma-x/frontend/core/logger';
import { OauthApiClientInstrument, TokenResponseModel } from '@chroma-x/frontend/core/oauth-api-integration';
import { useUrlSearchParams } from '@chroma-x/frontend/core/react';
import { AuthGuardInterceptingProps } from '@chroma-x/frontend/core/react-auth-guard';
import { useL10n } from '@chroma-x/frontend/core/react-l10n';
import { useLogger } from '@chroma-x/frontend/core/react-logger';
import {
	ButtonPrimary, IconIdentifier,
	IllustrationIdentifier, LoadingIndicator,
	SystemFeedback,
	SystemFeedbackDescription
} from '@chroma-x/frontend/core/react-ui-primitive';

enum OauthAction {
	IDLE = 'IDLE',
	REQUEST_TOKEN = 'REQUEST_TOKEN',
	DONE = 'DONE',
	FAILED = 'FAILED'
}

export type OauthEmitterProps = {
	onTokenReceived?: (oauthTokenResponse: TokenResponseModel) => void
} & AuthGuardInterceptingProps;

/**
 * OauthEmitter is a React component that handles the authentication and token retrieval process.
 *
 * @param props - The props for the OauthEmitter.
 * @returns The rendered React component.
 */
export function OauthEmitter(props: OauthEmitterProps): ReactNode {

	const { onAuthenticate = null, onTokenReceived = null } = props;

	const logger = useLogger();
	const location = useLocation();
	const searchParams = useUrlSearchParams();

	const oauthApiClient = OauthApiClientInstrument.getClient();

	// Create a clean oAuth redirect URL, i.e. without the query params added by the oAuth provider after redirecting back
	// to the application
	const redirectUrl = new URL(window.location.href);
	redirectUrl.pathname = location.pathname;
	redirectUrl.hash = location.hash;
	const redirectSearchParams: Record<string, string> = {};
	const searchParamsMap = searchParams.all();
	for (const searchParamKey of searchParamsMap.keys()) {
		if (!oauthApiClient.getLoginRedirectUrlParams().includes(searchParamKey)) {
			const searchParamValue = searchParamsMap.get(searchParamKey);
			if (searchParamValue === undefined) {
				continue;
			}
			redirectSearchParams[searchParamKey] = searchParamValue;
		}
	}
	redirectUrl.search = new URLSearchParams(redirectSearchParams).toString();

	const l10n = useL10n();

	const [authAction, setAuthAction] = useState<OauthAction>(OauthAction.IDLE);
	const tokenRequested = useRef<boolean>(false);

	const shouldRequestToken = oauthApiClient.getLoginRedirectUrlParams().reduce((shouldRequest, loginRedirectUrlParam) => {
		return shouldRequest && searchParams.has(loginRedirectUrlParam);
	}, true);
	if (shouldRequestToken && authAction === OauthAction.IDLE) {
		setAuthAction(OauthAction.REQUEST_TOKEN);
	}

	const handleResolveError = () => {
		oauthApiClient.login(redirectUrl.toString());
	};

	// Call the respective oAuth API client methods based on the current auth flow state.
	useEffect((): void => {
		const processAuth = async (): Promise<void> => {
			switch (authAction) {
				case OauthAction.IDLE:
					// Redirect to the login URL
					oauthApiClient.login(redirectUrl.toString());
					break;
				case OauthAction.REQUEST_TOKEN:
					// Perform accessToken request
					try {
						if (tokenRequested.current) {
							return;
						}
						tokenRequested.current = true;
						const tokenResponse = await oauthApiClient.requestToken(
							redirectUrl.toString(),
							searchParams.all()
						);
						if (onAuthenticate !== null) {
							const accessTokenValidTo = new Date();
							accessTokenValidTo.setSeconds(accessTokenValidTo.getSeconds() + tokenResponse.expires_in);
							const refreshTokenValidTo = new Date();
							refreshTokenValidTo.setSeconds(refreshTokenValidTo.getSeconds() + tokenResponse.refresh_expires_in);
							const idTokenValidTo = new Date();
							idTokenValidTo.setSeconds(idTokenValidTo.getSeconds() + tokenResponse.id_expires_in);
							onAuthenticate(
								tokenResponse.access_token,
								accessTokenValidTo,
								tokenResponse.id_token,
								idTokenValidTo,
								tokenResponse.refresh_token,
								refreshTokenValidTo
							);
						}
						if (onTokenReceived !== null) {
							onTokenReceived(tokenResponse);
						}
						setAuthAction(OauthAction.DONE);

						oauthApiClient.finishLogin(redirectUrl.toString());
					} catch (error) {
						logger?.logError(error as Error, LogLevel.WARN);
						setAuthAction(OauthAction.FAILED);
					}
					break;
			}
		};
		void processAuth();
	}, [authAction]);

	// Call the respective UI based on the current auth flow state.
	const renderMain = (): ReactNode => {
		switch (authAction) {
			case OauthAction.FAILED: {
				return (
					<SystemFeedback
						message={l10n.translate('reactUiAuthEmitter.error.oauth.heading')}
						illustration={IllustrationIdentifier.OFFLINE}
					>
						<SystemFeedbackDescription description={l10n.translate('reactUiAuthEmitter.error.oauth.message')} />
						<ButtonPrimary
							label={l10n.translate('reactUiAuthEmitter.error.oauth.retry.label')}
							icon={IconIdentifier.REPEAT}
							onClick={handleResolveError}
						/>
					</SystemFeedback>
				);
			}
			default:
				return (
					<LoadingIndicator message={l10n.translate('reactUiAuthEmitter.pending.message')} />
				);
		}
	};

	return renderMain();

}
