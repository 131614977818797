import { StoreApi } from 'zustand/vanilla';

import { ServiceManager } from './service-manager';

/**
 * Registers a managed service.
 *
 * @template T - The type of the store.
 * @param store - The store to be registered.
 */
export const registerManagedService = <T extends StoreApi<unknown>>(store: T): void => {
	const initialState = store.getState();
	ServiceManager.registerResetClosure(() => store.setState(initialState, true));
};
