import { isString } from './string';

/**
 * Check if a given value is a valid enum value.
 *
 * @param enumSet - The enum set to check against.
 * @param value - The value to check.
 * @returns True if the value is a valid enum value, false otherwise.
 */
export const isEnumValue = (enumSet: { [_: string]: string }, value: unknown): boolean => {
	if (!isString(value)) {
		return false;
	}
	return Object.values(enumSet).includes(value);
};

/**
 * Check if a given value is a valid enum value or undefined.
 *
 * @param enumSet - The enum set to check against.
 * @param value - The value to check.
 * @returns True if the value is a valid enum value or undefined, false otherwise.
 */
export const isOptionalEnumValue = (enumSet: { [_: string]: string }, value: unknown): boolean => {
	return value === undefined || isEnumValue(enumSet, value);
};
