import { DocumentProvisionServiceApiClient } from '@chroma-x/frontend/domain/document/api-integration';
import { DocumentProvisionApiFetchMockConfiguration } from '@chroma-x/frontend/domain/document/api-integration-mock';

export class DocumentProvisionServiceApiClientFactory {

	public static create() {
		if (process.env.NX_PUBLIC_DOCUMENT_API_MOCKED === 'true') {
			new DocumentProvisionApiFetchMockConfiguration().apply();
		}
		return new DocumentProvisionServiceApiClient();
	}

}
