import { useEffect } from 'react';

import { EffectCallbackReturn } from '@chroma-x/frontend/core/react';

import { FetchMode } from '../props/service-provider-props';

/**
 * Custom hook to initialize a fetch operation.
 *
 * @param fetchMode - The fetch mode.
 * @param initCallback - The callback to initialize the fetch.
 * @param preInitCallback - Optional callback to be called before the fetch.
 */
export const useFetchInit = (fetchMode: FetchMode, initCallback: () => void, preInitCallback?: () => void) => {
	useEffect((): EffectCallbackReturn => {
		if (preInitCallback) {
			preInitCallback();
		}
		if (fetchMode === FetchMode.IMMEDIATE) {
			initCallback();
		}
	}, []);
};
