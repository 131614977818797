import { isArray } from './array';
import { trimFromLeft, trimFromRight } from './string';

export type ClassMap = Record<string, unknown>;

/**
 * Builds an array of CSS class names from a class map.
 *
 * @param classMap - The class map to build the class names from.
 * @returns An array of CSS class names.
 */
export const buildCssClassNamesFromClassMap = (classMap: ClassMap): Array<string> => {
	const classNames: Array<string> = [];
	for (const className in classMap) {
		const classMapElement = classMap[className];
		const checkValue = isArray(classMapElement) && classMapElement.length === 0 ? false : classMapElement;
		if ((checkValue ?? false) !== false) {
			classNames.push(className);
		}
	}
	return classNames;
};

/**
 * Builds a string of CSS class names from a class map.
 *
 * @param classMap - The class map to build the class names from.
 * @returns A string of CSS class names.
 */
export const buildCssClassStringFromClassMap = (classMap: ClassMap): string => {
	return buildCssClassNamesFromClassMap(classMap).join(' ');
};

/**
 * Builds a CSS class name from an enum value.
 *
 * @param enumValue - The enum value to build the class name from.
 * @param prefix - An optional prefix to prepend to the class name.
 * @param suffix - An optional suffix to append to the class name.
 * @returns The CSS class name.
 */
export const buildCssClassNameFromEnumValue = (enumValue: string, prefix?: string, suffix?: string): string => {
	prefix = prefix ? trimFromRight(prefix, '-') + '--' : '';
	suffix = suffix ? '-' + trimFromLeft(suffix, '-') : '';
	return prefix + enumValue.toLowerCase().replace(/_/g, '-') + suffix;
};

/**
 * Extends a class map with a CSS class name built from an enum value.
 *
 * @param classMap - The class map to extend.
 * @param enumValue - The enum value to build the class name from.
 * @param prefix - An optional prefix to prepend to the class name.
 * @param suffix - An optional suffix to append to the class name.
 */
export const extendCssMapByEnumValue = (classMap: ClassMap, enumValue: string, prefix?: string, suffix?: string): void => {
	classMap[buildCssClassNameFromEnumValue(enumValue, prefix, suffix)] = true;
};
