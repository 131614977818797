import { Maybe } from './maybe';

/**
 * Checks if the given value is an array.
 *
 * @param value - The value to check.
 * @returns True if the value is an array, false otherwise.
 */
export const isArray = (value: unknown): value is Array<unknown> => {
	return Array.isArray(value);
};

/**
 * Checks if the given value is either undefined or an array.
 *
 * @param value - The value to check.
 * @returns True if the value is undefined or an array, false otherwise.
 */
export const isMaybeArray = (value: unknown): value is Maybe<Array<unknown>> => {
	return value === undefined || isArray(value);
};

/**
 * Pads an array to a specified length with a given fill value.
 *
 * @param array - The array to pad.
 * @param length - The desired length of the padded array.
 * @param fill - The value to fill the array with.
 * @returns The padded array.
 */
export const arrayPad = <T = unknown>(array: Array<T>, length: number, fill: T): Array<T> => {
	if (array.length >= length) {
		return array;
	}
	return Array.from({ ...array, length: length }, (v): T => v ?? fill);
};

/**
 * Reverses an array.
 *
 * @param array - The array to reverse.
 * @returns The reversed array.
 */
export const arrayReverse = <T = unknown>(array: Array<T>): Array<T> => {
	return [...array].reverse();
};

/**
 * Filters an array to only contain unique values.
 *
 * @param value - The value to check.
 * @param index - The index of the value in the array.
 * @param array - The array to filter.
 * @returns True if the value is unique, false otherwise.
 *
 * @example
 * const array = [1, 2, 3, 1, 2, 3];
 * const uniqueArray = array.filter(uniqueFilter);
 * console.log(uniqueArray); // [1, 2, 3]
 */
export const uniqueFilter = <T = unknown>(value: T, index: number, array: Array<T>): boolean => {
	return array.indexOf(value) === index;
};

/**
 * Checks if an array includes any of the given needles.
 *
 * @param haystack - The array to search.
 * @param needles - The values to search for.
 * @returns True if the array includes any of the needles, false otherwise.
 */
export const arrayIncludesAny = <T = unknown>(haystack: Array<T>, needles: Array<T>): boolean => {
	return needles.some(value => haystack.includes(value));
};

/**
 * Checks if an array includes all of the given needles.
 *
 * @param haystack - The array to search.
 * @param needles - The values to search for.
 * @returns True if the array includes all of the needles, false otherwise.
 */
export const arrayIncludesAll = <T = unknown>(haystack: Array<T>, needles: Array<T>): boolean => {
	return needles.every(value => haystack.includes(value));
};
