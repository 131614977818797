/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/document/api-spec/tspconfig.yml
 */
import { ApiError } from '@chroma-x/common/core/error';
import { guardProbably } from '@chroma-x/common/core/util';
import { AbstractResponseModelConverter, ModelPrimaryKeyConverter } from '@chroma-x/frontend/core/api-integration';
import { SignedDownloadUrlModel } from '@chroma-x/frontend/domain/document/domain-model';

import { SignedDownloadUrlDto } from '../../../../../dto';

export class SignedDownloadUrlResponseModelConverter extends AbstractResponseModelConverter<SignedDownloadUrlModel, SignedDownloadUrlDto> {
	public toModel(dto: SignedDownloadUrlDto): SignedDownloadUrlModel {
		return {
			documentId: new ModelPrimaryKeyConverter().toModel(
				guardProbably(dto.documentId, new ApiError("Expected property 'documentId' of 'SignedDownloadUrlDto' missing."))
			),
			signedUrl: guardProbably(
				dto.signedUrl,
				new ApiError("Expected property 'signedUrl' of 'SignedDownloadUrlDto' missing.")
			) satisfies string
		};
	}
}
