import { ReactComponent as IndicatorSvg } from './assets/loading.tokenized.svg';

import './loading-indicator.scss';

export type LoadingIndicatorProps = {
	message: string
};

export function LoadingIndicator(props: LoadingIndicatorProps) {

	const { message } = props;

	return (
		<div className="loading-indicator">
			<div className="loading-indicator__visual">
				<IndicatorSvg />
			</div>
			<h1 className="loading-indicator__message">{message}</h1>
		</div>
	);
}
