import { isEnumValue, Maybe, Nullable } from '@chroma-x/common/core/util';

import { FreeSortCriterion, SortCriteria, SortDirection } from './sort-criteria';

/**
 * Class for building and parsing URL sort criteria.
 */
export class UrlSortCriteria {

	private readonly urlSortParameterSeparator = ';';
	private readonly urlSortDirectionSeparator = ':';
	private readonly defaultSortDirection = SortDirection.ASCENDING;

	/**
	 * Builds the URL sort criteria string from the provided sort criteria.
	 *
	 * @param sort - The sort criteria.
	 * @returns The URL sort criteria string.
	 */
	public buildUrlSortCriteria = <CollectionModel>(sort: SortCriteria<CollectionModel>) => {
		const sortParams = sort.map<string>((sortCriteria) => {
			return sortCriteria.property.toString() + this.urlSortDirectionSeparator + (sortCriteria?.direction ?? this.defaultSortDirection);
		});
		return sortParams.join(this.urlSortParameterSeparator);
	};

	/**
	 * Parses the URL sort criteria string and returns an array of parsed sort criteria.
	 *
	 * @param sortString - The URL sort criteria string.
	 * @returns The parsed sort criteria.
	 */
	public parseUrlSortString(sortString?: string): Maybe<Array<FreeSortCriterion>> {
		if (sortString === undefined || sortString.length === 0) {
			return undefined;
		}

		const sortList = sortString.split(this.urlSortParameterSeparator);
		if (sortList.length === 0) {
			return undefined;
		}

		const parsedList = sortList
			.map(sortItem => this.parseSortRule(sortItem))
			.filter((item): item is FreeSortCriterion => item !== null);

		if (parsedList.length === 0) {
			return undefined;
		}
		return parsedList;
	}

	/**
	 * Returns the separator used to separate multiple sort criteria in the URL.
	 *
	 * @returns The separator.
	 */
	public getUrlSortParameterSeparator(): string {
		return this.urlSortParameterSeparator;
	}

	private parseSortRule(sortItem: string): Nullable<FreeSortCriterion> {
		const sortItemParts = sortItem.split(this.urlSortDirectionSeparator);

		if (sortItemParts.length === 1) {
			const [property] = sortItemParts;

			return {
				property: property,
				direction: undefined
			};

		}
		if (sortItemParts.length === 2) {
			const [property, direction] = sortItemParts;

			if (!isEnumValue(SortDirection, direction)) {
				return null;
			}

			return {
				property: property,
				direction: direction as SortDirection
			};
		}
		return null;
	}

}
