import { isArray } from '@chroma-x/common/core/util';

import {
	FilterCriteria,
	FilterCriterion,
	MultiValueFilterComparator,
	MultiValueFilterCriterion,
	SingleValueFilterComparator,
	SingleValueFilterCriterion
} from './filter-criteria';

const singleValueFilterCriterionComparators = [...Object.values(SingleValueFilterComparator), undefined];
const multiValueFilterCriterionComparators = [...Object.values(MultiValueFilterComparator), undefined];

/**
 * Checks if the given object is a SingleValueFilterCriterion.
 * @param filterCriterion - The object to check.
 * @returns True if the object is a SingleValueFilterCriterion, false otherwise.
 */
export const isSingleValueFilterCriterion = (filterCriterion: unknown): filterCriterion is SingleValueFilterCriterion<unknown> => {
	return singleValueFilterCriterionComparators.includes((filterCriterion as SingleValueFilterCriterion<unknown>).comparator)
		&& (filterCriterion as SingleValueFilterCriterion<unknown>).property !== undefined
		&& (filterCriterion as SingleValueFilterCriterion<unknown>).value !== undefined
		&& !isArray((filterCriterion as SingleValueFilterCriterion<unknown>).value);
};

/**
 * Checks if the given object is a MultiValueFilterCriterion.
 * @param filterCriterion - The object to check.
 * @returns True if the object is a MultiValueFilterCriterion, false otherwise.
 */
export const isMultiValueFilterCriterion = (filterCriterion: unknown): filterCriterion is MultiValueFilterCriterion<unknown> => {
	return multiValueFilterCriterionComparators.includes((filterCriterion as MultiValueFilterCriterion<unknown>).comparator)
		&& (filterCriterion as MultiValueFilterCriterion<unknown>).property !== undefined
		&& (filterCriterion as MultiValueFilterCriterion<unknown>).value !== undefined
		&& isArray((filterCriterion as SingleValueFilterCriterion<unknown>).value);
};

/**
 * Checks if the given object is a FilterCriterion.
 * @param filterCriterion - The object to check.
 * @returns True if the object is a FilterCriterion, false otherwise.
 */
export const isFilterCriterion = <Model = unknown>(filterCriterion: unknown): filterCriterion is FilterCriterion<Model> => {
	return isSingleValueFilterCriterion(filterCriterion) || isMultiValueFilterCriterion(filterCriterion);
};

/**
 * Checks if the given object is an array of FilterCriterion.
 * @param filterCriterionCollection - The object to check.
 * @returns True if the object is a FilterCriterion, false otherwise.
 */
export const isFilterCriterionCollection = <Model = unknown>(filterCriterionCollection: unknown): filterCriterionCollection is Array<FilterCriterion<Model>> => {
	for (const filterCriterion of (filterCriterionCollection as Array<FilterCriterion<Model>>)) {
		if (!isFilterCriterion<Model>(filterCriterion)) {
			return false;
		}
	}
	return true;
};

/**
 * Checks if the given object is a FilterCriteria.
 * @param filterCriteria - The object to check.
 * @returns True if the object is a FilterCriteria, false otherwise.
 */
export const isFilterCriteria = <Model = unknown>(filterCriteria: unknown): filterCriteria is FilterCriteria<Model> => {
	if (!isArray(filterCriteria)) {
		return false;
	}
	for (const filterCriterion of (filterCriteria as FilterCriteria<Model>)) {
		if (!isFilterCriterionCollection<Model>(filterCriterion.criteria)) {
			return false;
		}
	}
	return true;
};
