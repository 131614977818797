/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/document/api-spec/tspconfig.yml
 */
import { SignedDownloadUrlDto } from '@chroma-x/frontend/domain/document/api-integration';

export const signedDownloadUrlDtoMockData: SignedDownloadUrlDto = {
	documentId: '0c0a0470-11a4-4805-a070-13162f2d9023',
	signedUrl: 'https://dev.chroma-x.vse-net.test.fra1.digitaloceanspaces.com/mock-documents/Untitled.pdf'
};
