import { SortCriteria } from './sort-criteria';

/**
 * Compares two SortCriteria objects by their content.
 *
 * @param left - The left-hand side SortCriteria.
 * @param right - The right-hand side SortCriteria.
 * @returns True if the SortCriteria objects are equal, false otherwise.
 */
export const compareSortCriteria = <Model>(left?: SortCriteria<Model>, right?: SortCriteria<Model>): boolean => {
	if (left === undefined && right === undefined) {
		return true;
	}
	if (left === undefined || right === undefined) {
		return false;
	}
	if (left.length !== right.length) {
		return false;
	}
	for (let i = 0; i < left.length; i++) {
		if (left[i].property !== right[i].property) {
			return false;
		}
		if (left[i].direction !== right[i].direction) {
			return false;
		}
	}
	return true;
};
