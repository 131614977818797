import { TenantDefinition } from '@chroma-x/frontend/core/react-tenant-provider';

import { energisTheme } from '../themes/energis';
import { herznetTheme } from '../themes/herznet';
import { primeroTheme } from '../themes/primero';
import { veganetTheme } from '../themes/veganet';

const legalDocumentLinks = [
	{
		label: 'reactStarter.theme.privacyLinkLabel',
		url: 'https://vsenet.de/datenschutz/'
	},
	{
		label: 'reactStarter.theme.termsLinkLabel',
		url: 'https://vsenet.de/agb/'
	},
	{
		label: 'reactStarter.theme.imprintLinkLabel',
		url: 'https://vsenet.de/impressum/'
	}
];

export const tenants: Array<TenantDefinition> = [
	{
		name: 'vse-net',
		hostnames: ['localhost', '192.168.0.125', 'test.vse-net.chroma-x.dev', 'vse-net.chroma-x.local'],
		realm: 'chroma-x',
		links: legalDocumentLinks,
		support: {
			serviceMailAddress: 'support@tenantname.de',
			serviceHotline: '+49 123 456789555'
		}
	},
	{
		name: 'energis',
		hostnames: ['energis-test.vse-net.chroma-x.dev', 'energis.vse-net.chroma-x.local'],
		realm: 'chroma-x',
		links: legalDocumentLinks,
		support: {
			serviceMailAddress: 'support@tenantname.de',
			serviceHotline: '+49 123 456789555'
		},
		theme: energisTheme
	},
	{
		name: 'herznet',
		hostnames: ['herznet-test.vse-net.chroma-x.dev', 'herznet.vse-net.chroma-x.local'],
		realm: 'chroma-x',
		links: legalDocumentLinks,
		support: {
			serviceMailAddress: 'support@tenantname.de',
			serviceHotline: '+49 123 456789555'
		},
		theme: herznetTheme
	},
	{
		name: 'schlau',
		hostnames: ['schlau-test.vse-net.chroma-x.dev', 'schlau.vse-net.chroma-x.local'],
		realm: 'chroma-x',
		links: legalDocumentLinks,
		support: {
			serviceMailAddress: 'support@tenantname.de',
			serviceHotline: '+49 123 456789555'
		},
		theme: energisTheme
	},
	{
		name: 'primero',
		hostnames: ['primero-test.vse-net.chroma-x.dev', 'primero.vse-net.chroma-x.local'],
		realm: 'chroma-x',
		links: legalDocumentLinks,
		support: {
			serviceMailAddress: 'support@tenantname.de',
			serviceHotline: '+49 123 456789555'
		},
		theme: primeroTheme
	},
	{
		name: 'veganet',
		hostnames: ['veganet-test.vse-net.chroma-x.dev', 'veganet.vse-net.chroma-x.local'],
		realm: 'chroma-x',
		links: legalDocumentLinks,
		support: {
			serviceMailAddress: 'support@tenantname.de',
			serviceHotline: '+49 123 456789555'
		},
		theme: veganetTheme
	}
];
