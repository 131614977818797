import { camelCase, trimFromLeft, trimFromRight } from './string';

/**
 * Generates an l10n literal from an enum value.
 *
 * @param enumValue - The enum value.
 * @param prefix - An optional prefix to prepend to the l10n literal.
 * @param suffix - An optional suffix to append to the l10n literal.
 * @returns The generated l10n literal.
 */
export const l10nLiteralFromEnumValue = (enumValue: string, prefix?: string, suffix?: string): string => {
	prefix = prefix ? trimFromRight(prefix, '.') + '.' : '';
	suffix = suffix ? '.' + trimFromLeft(suffix, '.') : '';
	return prefix + camelCase(enumValue) + suffix;
};

/**
 * Generates an l10n literal from a model member.
 *
 * @param memberKey - The member key.
 * @param prefix - An optional prefix to prepend to the l10n literal.
 * @param suffix - An optional suffix to append to the l10n literal.
 * @returns The generated l10n literal.
 */
export const l10nLiteralFromModelMember = (memberKey: string, prefix?: string, suffix?: string): string => {
	prefix = prefix ? trimFromRight(prefix, '.') + '.' : '';
	suffix = suffix ? '.' + trimFromLeft(suffix, '.') : '';
	return prefix + memberKey + suffix;
};
