import { useEffect, useState } from 'react';

import { getPageTitle, setPageTitle } from '@chroma-x/frontend/core/browser';

/**
 * Custom hook to set the page title.
 *
 * @param title - The title to set.
 */
export const usePageTitle = (title: string): void => {

	useEffect(() => {
		setPageTitle(title);
	}, [title]);

};

/**
 * Custom hook to modify the page title.
 *
 * @returns A function that takes a title and updates the page title.
 */
export const usePageTitleModifier = (): (title: string) => void => {

	const [pageTitleState, setPageTitleState] = useState<string>(getPageTitle());

	useEffect(() => {
		setPageTitle(pageTitleState);
	}, [pageTitleState]);

	return (title: string): void => {
		if (title !== pageTitleState) {
			setPageTitleState(title);
		}
	};

};
