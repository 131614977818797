import { ReactNode } from 'react';

import { Illustration } from '../illustration/illustration';
import { IllustrationIdentifier } from '../illustration/illustration.enum';

import './system-feedback.scss';

export type SystemFeedbackProps = {
	message: string,
	illustration: IllustrationIdentifier,
	children?: ReactNode
};

export function SystemFeedback(props: SystemFeedbackProps) {

	const { message, illustration, children } = props;

	return (
		<div className="system-feedback">
			<Illustration identifier={illustration} />
			<h1 className="system-feedback__message">{message}</h1>
			{children}
		</div>
	);
}
