import { Component, ReactNode } from 'react';

import { Logger, LogLevel } from '@chroma-x/frontend/core/logger';
import { withLogger } from '@chroma-x/frontend/core/react-logger';

export type SilentErrorHandlerProps = {
	handleError: (error: Error) => boolean,
	transparent?: boolean,
	logger?: Logger,
	children?: ReactNode
};

type ErrorHandlerState = {
	error?: Error
};

/**
 * SilentErrorHandlerFoundation is a React component that handles errors thrown during rendering.
 * It uses the getDerivedStateFromError lifecycle method to set the error state.
 * The error is then logged and handled using the handleError prop.
 * If the handleError returns false, the error is rethrown.
 * If the handleError returns true, the children are rendered.
 */
class SilentErrorHandlerFoundation extends Component<SilentErrorHandlerProps, ErrorHandlerState> {

	public constructor(props: SilentErrorHandlerProps) {
		super(props);
		this.state = {
			error: undefined
		};
	}

	public static getDerivedStateFromError(error: Error): ErrorHandlerState {
		return {
			error: error
		};
	}

	private logError(error: Error) {
		if (!this.props.logger) {
			return;
		}
		void this.props.logger.logError(error, LogLevel.ERROR);
	}

	private renderError(error: Error) {
		const errorHandled = this.props.handleError(error);
		if (!errorHandled) {
			throw error;
		}
		const children = this.props.transparent ? this.props.children : null;
		return children;
	}

	public override render() {
		const error = this.state.error;
		if (error !== undefined) {
			this.logError(error);
			return this.renderError(error);
		}
		return this.props.children;
	}

}

/**
 * SilentErrorHandler is a higher-order component (HOC) that wraps a component with an error handler.
 * It is a silent error handler, meaning it will not render any error message or component.
 * Instead, it logs the error and rethrows it if not handled.
 *
 * @param Component - The component to wrap.
 * @returns The wrapped component.
 */
export const SilentErrorHandler = withLogger(SilentErrorHandlerFoundation);
