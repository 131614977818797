/**
 * ServiceManager is a class that manages the reset closures of various services.
 * It provides a way to register and reset all the registered reset closures.
 */
export class ServiceManager {

	private static resetClosures: Set<() => void> = new Set();

	/**
	 * Registers a reset closure.
	 * @param resetClosure - The reset closure to register.
	 */
	public static registerResetClosure(resetClosure: () => void) {
		this.resetClosures.add(resetClosure);
	}

	/**
	 * Resets all the registered reset closures.
	 */
	public static resetAll() {
		this.resetClosures.forEach((resetClosure) => {
			resetClosure();
		});
	}

}
