import { useEffect } from 'react';

import { setFavicon } from '@chroma-x/frontend/core/browser';

/**
 * Custom hook to set the pages favicon.
 *
 * @param url - The URL for the new favicon
 */
export const useFavicon = (url?: string): void => {

	useEffect(() => {
		if (url) {
			setFavicon(url);
		}
	}, [url]);

};
