import { InterceptEmptyProps } from '@chroma-x/frontend/core/react-service-provider';
import { DocumentModel } from '@chroma-x/frontend/domain/document/domain-model';

import { useDocumentProvisionService } from './document-provision-service-provider';

export function DocumentProvisionCollectionInterceptEmpty(props: InterceptEmptyProps<DocumentModel>) {
	const { children, emptyStateComponent, filter } = props;

	const service = useDocumentProvisionService();
	const hasResults = service.query.hasResults(filter);

	if (!hasResults) {
		return (
			<>
				{emptyStateComponent()}
			</>
		);
	}

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{children}
		</>
	);
}
