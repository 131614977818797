export enum SortDirection {
	ASCENDING = 'asc',
	DESCENDING = 'desc'
}

export type SortCriteria<Model> = Array<ModelSortCriterion<Model>>;

export type ModelSortCriterion<Model> = {
	property: keyof Model,
	direction?: SortDirection
};

// FreeSortCriterion is used as return type when an url string is parsed
export type FreeSortCriterion = {
	property: string,
	direction?: SortDirection
};

export type SortCriterion<Model> = ModelSortCriterion<Model> | FreeSortCriterion;
