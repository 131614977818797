import { AppError } from '@chroma-x/common/core/error';
import { Nullable } from '@chroma-x/common/core/util';

import { OauthApiClient } from './oauth-api-client';

/**
 * OauthApiClientInstrument is a class that provides a singleton instance of the OauthApiClient.
 * It allows for easy access to the OauthApiClient throughout the application.
 */
export class OauthApiClientInstrument {

	private static client: Nullable<OauthApiClient> = null;

	/**
	 * Sets the client to the provided instance of the OauthApiClient.
	 * @param oauthApiClient - The OauthApiClient instance to set.
	 */
	public static setClient(oauthApiClient: OauthApiClient): void {
		this.client = oauthApiClient;
	}

	/**
	 * Returns the singleton instance of the OauthApiClient.
	 * @throws If the client has not been set.
	 * @returns The OauthApiClient instance.
	 */
	public static getClient(): OauthApiClient {
		if (this.client === null) {
			throw new AppError('No oAuth API client defined');
		}
		return this.client;
	}

}
