import { v4 as uuid } from 'uuid';

import { Dto, ModelPrimaryKeyDto } from '@chroma-x/common/core/api-integration';
import { createMockCollectionResponse, generatePredictableUuid } from '@chroma-x/common/core/api-integration-mock';
import { mutate } from '@chroma-x/common/core/util';

/**
 * Creates a mock collection response data.
 *
 * @param dto - The DTO (Data Transfer Object) to create the collection from.
 * @param page - The page number of the collection.
 * @param itemsPerPage - The number of items per page.
 * @param amount - The total number of items in the collection (optional).
 * @param modifier - A function to modify the constructed response data (optional).
 *
 * @returns The mock collection response.
 */
export const createMockCollectionResponseData = <D extends Dto>(
	dto: D,
	page: number,
	itemsPerPage: number,
	amount?: number,
	modifier?: (dto: D, index: number) => D
) => {
	return createMockCollectionResponse((index) => {
		let response = {
			...dto,
			id: generatePredictableUuid(index)
		};
		if (modifier) {
			response = modifier(response, index);
		}
		return response;
	}, page, itemsPerPage, amount);
};

/**
 * Creates a mock entity response data.
 *
 * @param dto - The DTO to create the entity from.
 * @param id - The ID of the entity (optional).
 *
 * @returns The mock entity response.
 */
export const createMockEntityResponseData = <D extends Dto>(
	dto: D,
	id?: ModelPrimaryKeyDto
) => {
	const responseData = { ...dto };
	return (mutate(responseData, { id: id ?? uuid() }));
};
