/**
 * Polyfill for the `requestIdleCallback` function.
 * This function schedules a function to be called during a browser's idle periods.
 *
 * @param cb - The function to be called during an idle period.
 * @returns A handle to the request.
 */
export const requestIdleCallbackPolyfill =
	window.requestIdleCallback ||
	function (cb: IdleRequestCallback) {
		const start = Date.now();
		return window.setTimeout(function () {
			cb({
				didTimeout: false,
				timeRemaining: function () {
					return Math.max(0, 50 - (Date.now() - start));
				}
			});
		}, 1);
	};

/**
 * Polyfill for the `cancelIdleCallback` function.
 * This function cancels a previously scheduled idle period callback.
 *
 * @param id - The handle to the request.
 */
export const cancelIdleCallbackPolyfill =
	window.cancelIdleCallback ||
	function (id: number) {
		window.clearTimeout(id);
	};
