/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/document/api-spec/tspconfig.yml
 */
import { DocumentDto } from '@chroma-x/frontend/domain/document/api-integration';

export const documentDtoMockData: DocumentDto = {
	id: '0c0a0470-11a4-4805-a070-13162f2d9023',
	documentType: 'INVOICE',
	billingPeriod: '2024-09',
	invoiceId: 'F0123456789',
	customerId: '12345678',
	downloaded: true,
	plannedDeletionDate: '2024-05-28T16:02:57.919Z',
	createdAt: '2024-05-28T16:02:57.919Z'
};
