export type Maybe<T> = T | undefined;

/**
 * Checks if the given value is not undefined.
 *
 * @template T The type of the value.
 * @param value The value to check.
 * @returns True if the value is not undefined, false otherwise.
 */
export const maybeResolves = <T>(value: Maybe<T>): value is T => {
	return value !== undefined;
};

/**
 * Guards a maybe value and throws an error if it is not resolved.
 *
 * @template T The type of the value.
 * @param value The maybe value to guard.
 * @param [error] Optional error to throw. If not provided, a default error is thrown.
 * @returns The resolved value.
 * @throws If the maybe value is not resolved.
 */
export const guardMaybe = <T = unknown>(value: Maybe<T>, error?: Error): T => {
	if (!maybeResolves(value)) {
		error = error ?? new Error('Resolving maybe value failed');
		throw error;
	}
	return value;
};
