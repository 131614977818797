import { SuspenseProps } from '@chroma-x/frontend/core/react-service-provider';
import { FetchStatus } from '@chroma-x/frontend/core/service';

import { useDocumentProvisionService } from './document-provision-service-provider';

export function DocumentProvisionCollectionSuspense(props: SuspenseProps) {
	const { children, pendingComponent = null } = props;

	const service = useDocumentProvisionService();
	const PendingComponent = pendingComponent;

	const fetchStatus = service.meta.queryFetchStatus();

	switch (fetchStatus) {
		case FetchStatus.IDLE:
			return null;
		case FetchStatus.PENDING:
			if (PendingComponent !== null) {
				return (<PendingComponent />);
			}
			return (<code>Loading</code>);
		case FetchStatus.FAILED:
			return null;
	}

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{children}
		</>
	);
}
