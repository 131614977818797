import { ReactNode } from 'react';

import { buildCssClassNameFromEnumValue } from '@chroma-x/common/core/util';

import { CollectionItemCellGroupAlign } from './collection-item-cell-group.enum';

import './collection-item-cell-group.scss';

export type CollectionItemCellGroupProps = {
	children: ReactNode,
	align?: CollectionItemCellGroupAlign
};

export function CollectionItemCellGroup(props: CollectionItemCellGroupProps) {

	const { children, align = CollectionItemCellGroupAlign.ALIGN_ROW } = props;

	const alignCssClass = buildCssClassNameFromEnumValue(align, 'collection-item-cell-group');

	return (
		<article className={`collection-item-cell-group ${alignCssClass}`}>
			{children}
		</article>
	);

}
