import { useContext } from 'react';

import { serviceManagerContext, ServiceManagerContextValue } from './service-manager-context';

/**
 * Custom hook to access the service manager context.
 *
 * @returns The service manager context value.
 */
export const useServiceManager = (): ServiceManagerContextValue => {
	return useContext(serviceManagerContext);
};
