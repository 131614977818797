import { ModelConverter } from './model-converter';

export abstract class AbstractModelConverter<Model = unknown, Dto = unknown>
	implements ModelConverter<Model, Dto> {

	/**
	 * Converts a single model to a DTO.
	 *
	 * @param model - The model to convert.
	 * @returns The converted DTO.
	 */
	public abstract toDto(model: Model): Dto ;

	/**
	 * Converts an array of models to an array of DTOs.
	 *
	 * @param models - The array of models to convert.
	 * @returns The array of converted DTOs.
	 */
	public toArrayOfDto(models: Array<Model>): Array<Dto> {
		return models.map(model => this.toDto(model));
	}

	/**
	 * Converts a DTO to a single model.
	 *
	 * @param dto - The DTO to convert.
	 * @returns The converted model.
	 */
	public abstract toModel(dto: Dto): Model;

	/**
	 * Converts an array of DTOs to an array of models.
	 *
	 * @param dtos - The array of DTOs to convert.
	 * @returns The array of converted models.
	 */
	public toArrayOfModel(dtos: Array<Dto>): Array<Model> {
		return dtos.map(dto => this.toModel(dto));
	}
}
