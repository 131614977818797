import { createContext } from 'react';

import { Logger } from '@chroma-x/frontend/core/logger';

/**
 * LoggerContext is a type that extends the Logger interface and adds a 'provided' property.
 * The 'provided' property is a boolean that indicates whether a logger has been provided.
 */
export type LoggerContext = Logger & {
	/**
	 * Indicates whether a logger has been provided.
	 */
	provided: boolean
};

/**
 * Context for the logger.
 *
 * The logger context provides methods for logging events, messages, and errors.
 * It also includes a flag indicating whether a logger has been provided.
 */
export const loggerContext = createContext<LoggerContext>({
	provided: false,
	logEvent(): Promise<void> {
		throw new Error('No Logger provided');
	},
	logMessage(): Promise<void> {
		throw new Error('No Logger provided');
	},
	logError(): Promise<void> {
		throw new Error('No Logger provided');
	}
});
