import { useContext } from 'react';

import { Nullable } from '@chroma-x/common/core/util';

import { LoggerContext, loggerContext } from './logger-context';

/**
 * Custom hook to get the logger context.
 *
 * @returns The logger context, or null if not provided.
 */
export const useLogger = (): Nullable<LoggerContext> => {
	const logger = useContext(loggerContext);
	if (!logger.provided) {
		return null;
	}
	return logger;
};
