import { Route, Routes } from 'react-router-dom';

import { buildRouteUrl } from '@chroma-x/frontend/core/routing';

import { RoutePath } from './routes';
import { App } from '../app';
import { Goodbye } from '../goodbye';

export function AuthRouter() {

	return (
		<Routes>
			<Route path={buildRouteUrl(RoutePath.GOODBYE)} element={<Goodbye />} />
			<Route path="*" element={<App />} />
		</Routes>
	);

}
