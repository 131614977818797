import { Nullable } from '@chroma-x/common/core/util';

import { StorageAdapter } from './storage-adapter';

/**
 * Represents a storage adapter that uses the browser's localStorage API.
 */
export class BrowserLocalStorageAdapter implements StorageAdapter {

	/**
	 * Reads a value from localStorage.
	 *
	 * @param key - The key of the value to read.
	 * @returns The value associated with the key, or null if the key does not exist.
	 */
	public read(key: string): Nullable<string> {
		return window.localStorage.getItem(key) ?? null;
	}

	/**
	 * Writes a value to localStorage.
	 *
	 * @param key - The key to associate with the value.
	 * @param value - The value to write.
	 */
	public write(key: string, value: string): void {
		window.localStorage.setItem(key, value);
	}

	/**
	 * Removes a value from localStorage.
	 *
	 * @param key - The key of the value to remove.
	 */
	public remove(key: string): void {
		window.localStorage.removeItem(key);
	}

	/**
	 * Clears all values from localStorage.
	 */
	public clear(): void {
		window.localStorage.clear();
	}

	/**
	 * Adds an event listener for the 'storage' event.
	 *
	 * @param callback - The callback function to invoke when the event is fired.
	 */
	public addListener(callback: (event: StorageEvent) => void): void {
		window.addEventListener('storage', callback);
	}

	/**
	 * Removes an event listener for the 'storage' event.
	 *
	 * @param callback - The callback function to remove.
	 */
	public removeListener(callback: (event: StorageEvent) => void): void {
		window.removeEventListener('storage', callback);
	}

}
