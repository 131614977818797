import { ButtonHTMLAttributes, ReactNode } from 'react';

import { Icon } from '../icon/icon';
import { IconIdentifier, IconSize } from '../icon/icon.enum';

import './button-secondary.scss';

export type ButtonSecondaryProps = {
	label: string,
	icon?: IconIdentifier
} & ButtonHTMLAttributes<HTMLButtonElement>;

export function ButtonSecondary(props: ButtonSecondaryProps) {
	const { type = 'button', icon, label, ...rest } = props;

	const renderLabel = (): ReactNode => {
		if (icon) {
			return (
				<span className="button-secondary__label">
					{label}
					<Icon identifier={icon} size={IconSize.SMALL} />
				</span>
			);
		}
		return (
			<span className="button-secondary__label">{label}</span>
		);
	};

	return (
		<button
			type={type}
			className="button-secondary"
			{...rest}
		>
			{renderLabel()}
		</button>
	);
}
