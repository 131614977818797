import { ApiError } from '@chroma-x/common/core/error';
import { isEnumValue, Maybe, Nullable } from '@chroma-x/common/core/util';

import {
	FilterCriteria, MultiValueFilterComparator,
	MultiValueFreeFilterCriterion,
	SingleValueFilterComparator,
	SingleValueFreeFilterCriterion
} from './filter-criteria';
import { isMultiValueFilterCriterion, isSingleValueFilterCriterion } from './filter-criteria.guards';

/**
 * Class for building and parsing URL filter criteria.
 */
export class UrlFilterCriteria {

	private readonly urlFilterParameterSeparator = ';';

	/**
	 * Builds the URL filter criteria string from the given filter criteria.
	 *
	 * @param filter - The filter criteria.
	 * @returns The URL filter criteria string.
	 */
	public buildUrlFilterCriteria = <T>(filter: FilterCriteria<T>) => {
		const filterParams: Array<string> = [];
		for (const filterCriteria of filter) {
			for (const filterCriterion of filterCriteria.criteria) {
				const comparator = filterCriterion.comparator ?? '=';
				let value: string;
				if (isSingleValueFilterCriterion(filterCriterion)) {
					value = filterCriterion.value.toString();
				} else if (isMultiValueFilterCriterion(filterCriterion)) {
					value = filterCriterion.value.join('|');
				} else {
					throw new ApiError('Invalid filter criteria');
				}
				filterParams.push((filterCriterion.property as keyof T).toString() + comparator.toString() + value);
			}
		}
		return filterParams.join(this.urlFilterParameterSeparator);
	};

	/**
	 * Parses the URL query string and returns an array of parsed filter criteria.
	 *
	 * @param queryString - The URL query string.
	 * @returns The parsed filter criteria.
	 */
	public parseUrlQueryString(queryString?: string): Maybe<Array<SingleValueFreeFilterCriterion | MultiValueFreeFilterCriterion>> {
		if (queryString === undefined || queryString.length === 0) {
			return undefined;
		}

		const filters = queryString.split(this.urlFilterParameterSeparator);
		if (filters.length === 0) {
			return undefined;
		}

		const parsedArray = filters
			.map((filterItem) => this.parseFilterRule(filterItem))
			.filter((item): item is SingleValueFreeFilterCriterion | MultiValueFreeFilterCriterion => item !== null);
		if (parsedArray.length === 0) {
			return undefined;
		}

		return parsedArray;
	}

	private parseFilterRule(filterRule: string): Nullable<SingleValueFreeFilterCriterion | MultiValueFreeFilterCriterion> {
		// split the input
		const filterParts = filterRule.split(/([<>!=]+)/).map(s => s.trim());
		if (filterParts.length !== 3) {
			return null;
		}
		const [property, comparatorSymbol, valueStr] = filterParts;

		// split the value string
		const valueList = valueStr.split('|').map(s => s.trim());

		if (valueList.length === 1) {
			if (!isEnumValue(SingleValueFilterComparator, comparatorSymbol)) {
				return null;
			}

			return {
				flavor: 'free',
				property: property,
				comparator: comparatorSymbol as SingleValueFilterComparator,
				value: valueStr
			};
		}

		if (valueList.length > 1) {
			if (!isEnumValue(MultiValueFilterComparator, comparatorSymbol)) {
				return null;
			}

			return {
				flavor: 'free',
				property: property,
				comparator: comparatorSymbol as MultiValueFilterComparator,
				value: valueList
			};

		}
		return null;
	}

}
