import { createContext } from 'react';

import { AppError } from '@chroma-x/common/core/error';

/**
 * Represents the context value for the service manager.
 */
export type ServiceManagerContextValue = {
	/**
	 * Resets all the services.
	 *
	 */
	resetAll(): void
};

export const serviceManagerContext = createContext<ServiceManagerContextValue>({
	resetAll: (): void => {
		throw new AppError('No service manager context provided');
	}
});
