import { ModelPrimaryKey } from '@chroma-x/common/core/api-integration';
import { ReadonlyOptional } from '@chroma-x/common/core/util';
import { Model } from '@chroma-x/frontend/core/domain-model';

import {
	CollectionFoundationData,
	CollectionFoundationMetaOperation,
	CollectionFoundationQuery,
	CollectionServiceFoundation
} from './collection-service-foundation';
import {
	createResolveActionStatusDefaultMetaOperation,
	createResolveEntityActionStatusDefaultMetaOperation,
	createResolveEntityFetchStatusDefaultMetaOperation,
	createResolveFetchStatusDefaultMetaOperation
} from './default-collection-meta-operation';
import { ActionStatus } from '../../meta/action-status';
import { FetchStatus, fetchStatusPendingGroup } from '../../meta/fetch-status';
import { ModelFilter } from '../../meta/model-filter';
import { ModelLimit } from '../../meta/model-limit';
import { ModelSort } from '../../meta/model-sort';
import { GetArg, SetArg } from '../zustand-util';

/**
 * Returns the default data structure for a collection service.
 *
 * @template M - The type of the model.
 * @returns The default data structure for a collection service.
 */
export const defaultCollectionServiceData = <M extends Model>(): CollectionFoundationData<M> => {
	return {
		models: [] as Array<M>,
		createdEntity: null,
		meta: {
			fetchStatus: FetchStatus.IDLE,
			fetchEntityStatus: {},
			lastFetchError: null,
			currentPage: null,
			maxPages: null,
			sortCriteria: null,
			filterCriteria: null,
			actionStatus: ActionStatus.IDLE,
			actionEntityStatus: {},
			lastActionError: null
		}
	};
};

/**
 * Returns the default query operations for a collection service.
 *
 * @template S - The type of the collection service.
 * @template M - The type of the model.
 * @template C - The type of the collection.
 * @param get - The function to get the current state.
 * @returns The default query operations for a collection service.
 */
export const defaultCollectionServiceQuery = <
	S extends CollectionServiceFoundation<M, C>,
	M extends Model,
	C extends Record<string, unknown>
>(get: GetArg<S, M, C>): CollectionFoundationQuery<M> => {
	return {
		query: (
			sort?: ModelSort<M>,
			filter?: ModelFilter<M>,
			limit?: ModelLimit
		) => {
			const state = get();
			let modelCollection = state.data.models;
			if (filter) {
				modelCollection = modelCollection.filter(filter);
			}
			if (sort) {
				modelCollection = [...modelCollection].sort(sort);
			}
			if (limit) {
				const offset = limit.offset ?? 0;
				modelCollection = modelCollection.slice(offset, offset + limit.limit);
			}
			return modelCollection;
		},
		queryCount: (filter?: ModelFilter<M>) => {
			const state = get();
			let modelCollection = state.data.models;
			if (filter) {
				modelCollection = modelCollection.filter(filter);
			}
			return modelCollection.length;
		},
		hasResults: (filter?: ModelFilter<M>) => {
			const state = get();
			let modelCollection = state.data.models;
			if (filter) {
				modelCollection = modelCollection.filter(filter);
			}
			return modelCollection.length > 0;
		},
		queryEntity: (id: ModelPrimaryKey) => {
			const state = get();
			const modelEntity = state.data.models.find((model): boolean => {
				return model.id === id;
			});
			return new ReadonlyOptional(modelEntity);
		},
		queryCreated: () => {
			const state = get();
			return new ReadonlyOptional(state.data.createdEntity);
		}
	};
};

/**
 * Returns the default meta operation object for a collection service.
 *
 * @template S - The type of the collection service.
 * @template M - The type of the model.
 * @template C - The type of the collection service commands.
 * @param get - The function to get the current state.
 * @param set - The function to set the state.
 * @returns The default meta operation object for a collection service.
 */
export const defaultCollectionServiceMetaOperation = <
	S extends CollectionServiceFoundation<M, C>,
	M extends Model,
	C extends Record<string, unknown>
>(get: GetArg<S, M, C>, set: SetArg<S, M, C>): CollectionFoundationMetaOperation<M> => {
	return {
		idle: () => {
			const state = get();
			return state.data.meta.fetchStatus === FetchStatus.IDLE;
		},
		pending: () => {
			const state = get();
			return fetchStatusPendingGroup.includes(state.data.meta.fetchStatus);
		},
		hasNextPage: () => {
			const state = get();
			const currentPage = state.data.meta.currentPage ?? 1;
			const maxPages = state.data.meta.maxPages ?? 1;
			return currentPage < maxPages;
		},
		isPaginated: () => {
			const state = get();
			const maxPages = state.data.meta.maxPages ?? 1;
			return maxPages > 1;
		},
		queryCurrentPage: () => {
			const state = get();
			return state.data.meta.currentPage;
		},
		queryMaxPages: () => {
			const state = get();
			return state.data.meta.maxPages;
		},
		isSorted: () => {
			const state = get();
			return state.data.meta.sortCriteria !== null;
		},
		querySortCriteria: () => {
			const state = get();
			return state.data.meta.sortCriteria;
		},
		isFiltered: () => {
			const state = get();
			return state.data.meta.filterCriteria !== null;
		},
		queryFilterCriteria: () => {
			const state = get();
			return state.data.meta.filterCriteria;
		},
		queryFetchStatus: () => {
			const state = get();
			return state.data.meta.fetchStatus;
		},
		queryFetchEntityStatus: (id: ModelPrimaryKey) => {
			const state = get();
			return state.data.meta.fetchEntityStatus[id] ?? FetchStatus.IDLE;
		},
		queryFetchError: () => {
			const state = get();
			return state.data.meta.lastFetchError;
		},
		queryActionStatus: () => {
			const state = get();
			return state.data.meta.actionStatus;
		},
		queryActionEntityStatus: (id: ModelPrimaryKey) => {
			const state = get();
			return state.data.meta.actionEntityStatus[id];
		},
		queryActionError: () => {
			const state = get();
			return state.data.meta.lastActionError;
		},
		resolveFetchStatus: createResolveFetchStatusDefaultMetaOperation<S, M, C>(set),
		resolveEntityFetchStatus: createResolveEntityFetchStatusDefaultMetaOperation<S, M, C>(set),
		resolveActionStatus: createResolveActionStatusDefaultMetaOperation<S, M, C>(set),
		resolveEntityActionStatus: createResolveEntityActionStatusDefaultMetaOperation<S, M, C>(set),
		storeSize: () => {
			const state = get();
			return state.data.models.length;
		},
		storeEntryIds: () => {
			const state = get();
			return state.data.models.map((model) => {
				return model.id;
			});
		}
	};
};
