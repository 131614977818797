/**
 * Sets the pages favicon.
 *
 * @param url - The url to the favicon
 */
export const setFavicon = (url: string): void => {
	const iconLinkElements: NodeListOf<HTMLLinkElement> = window.document.querySelectorAll('link[rel~=\'icon\']');
	iconLinkElements.forEach((iconLinkElement) => {
		iconLinkElement.remove();
	});

	const link = window.document.createElement('link');
	link.rel = 'icon';
	link.href = url;
	document.head.appendChild(link);
};
