export enum EventType {
	DEBUG = 'DEBUG',
	ERROR = 'ERROR',
	NAVIGATION = 'NAVIGATION',
	HTTP = 'HTTP',
	INFO = 'INFO',
	QUERY = 'QUERY',
	UI = 'UI',
	USER = 'USER'
}
