import { ReactNode } from 'react';

import './collection-header.scss';

export type CollectionHeaderProps = {
	children: ReactNode
};

export function CollectionHeader(props: CollectionHeaderProps) {

	const { children } = props;

	return (
		<article className="collection-header">
			{children}
		</article>
	);

}
