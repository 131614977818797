import { ReactNode } from 'react';

import { ServiceManager } from '@chroma-x/frontend/core/service';

import { serviceManagerContext } from './service-manager-context';

export type ServiceManagerProviderProps = {
	children: ReactNode
};

/**
 * ServiceManagerProvider is a React component that provides a context for managing service instances.
 * It wraps its children in a context provider and provides a resetAll function to reset all services.
 *
 * @param props - The properties of the component.
 * @param props.children - The child components to be wrapped.
 * @returns A React component.
 */
export const ServiceManagerProvider = (props: ServiceManagerProviderProps) => {

	const { children } = props;

	const contextValue = {
		resetAll: (): void => {
			ServiceManager.resetAll();
		}
	};

	return (
		<serviceManagerContext.Provider value={contextValue}>
			{children}
		</serviceManagerContext.Provider>
	);

};
