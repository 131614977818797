import { Nullable } from './nullable';
import { Optional } from './optional';

/**
 * A wrapper class for readonly values that may or may not exist.
 */
export class ReadonlyOptional<T> extends Optional<T> {

	/**
	 * Returns the value if it exists, otherwise returns null.
	 * @returns The value or null.
	 */
	override get(): Nullable<Readonly<T>> {
		return super.get();
	}

	/**
	 * Returns the value if it exists, otherwise returns the default value.
	 * @param defaultValue - The default value to return.
	 * @returns The value or the default value.
	 */
	override getOrDefault(defaultValue: T): Readonly<T> {
		return super.getOrDefault(defaultValue);
	}

	/**
	 * Returns the value if it exists, otherwise returns undefined.
	 * @returns The value or undefined.
	 */
	override getOrUndefined(): Readonly<T> | undefined {
		return super.getOrUndefined();
	}

	/**
	 * Returns the value if it exists, otherwise throws an error.
	 * @param error - The error to throw.
	 * @returns The value.
	 * @throws The error if the value does not exist.
	 */
	override getOrThrow(error: Error): Readonly<T> {
		return super.getOrThrow(error);
	}

	/**
	 * Returns the value if it exists, otherwise computes a new value.
	 * @param computation - The function to compute a new value.
	 * @returns The value or the computed value.
	 */
	override getOrCompute(computation: () => T): Readonly<T> {
		return super.getOrCompute(computation);
	}

}
