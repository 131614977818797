import { Maybe } from './maybe';
import { Probably } from './probably';

/**
 * Converts a string to a number.
 *
 * @param string - The string to convert.
 * @param defaultValue - The value to return if the string cannot be converted to a number.
 * @returns The converted number or the default value if the conversion fails.
 */
export const numberFromString = (string: Probably<string>, defaultValue: number): number => {
	if (string === null || isNaN(Number(string))) {
		return defaultValue;
	}
	return Number(string);
};

/**
 * Checks if the given value is a number.
 *
 * @param value - The value to check.
 * @returns True if the value is a number, false otherwise.
 */
export const isNumber = (value: unknown): value is number => {
	return typeof value === 'number' || typeof value === 'bigint';
};

/**
 * Checks if the given value is a number or undefined.
 *
 * @param value - The value to check.
 * @returns True if the value is a number or undefined, false otherwise.
 */
export const isMaybeNumber = (value: unknown): value is Maybe<number> => {
	return value === undefined || isNumber(value);
};
