import { isArray } from '@chroma-x/common/core/util';

import { FilterCriteria } from './filter-criteria';

/**
 * Compares two FilterCriteria objects by its content.
 *
 * @param left - The left-hand side FilterCriteria.
 * @param right - The right-hand side FilterCriteria.
 * @returns True if the FilterCriteria objects are equal, false otherwise.
 */
export const compareFilterCriteria = <Model>(left?: FilterCriteria<Model>, right?: FilterCriteria<Model>): boolean => {
	if (left === undefined && right === undefined) {
		return true;
	}
	if (left === undefined || right === undefined) {
		return false;
	}
	if (left.length !== right.length) {
		return false;
	}
	for (let i = 0, l = left.length; i < l; i++) {
		if (left[i].id !== right[i].id) {
			return false;
		}
		if (left[i].criteria.length !== right[i].criteria.length) {
			return false;
		}

		for (let j = 0, m = left[i].criteria.length; j < m; j++) {
			if (left[i].criteria[j].property !== right[i].criteria[j].property) {
				return false;
			}
			if (left[i].criteria[j].comparator !== right[i].criteria[j].comparator) {
				return false;
			}
			// Check value
			const leftValue = left[i].criteria[j].value;
			const rightValue = right[i].criteria[j].value;
			if (isArray(leftValue) && isArray(rightValue)) {
				// Both values are multi value criteria values, so array of scalar
				if (!leftValue.every((value, index) => value === rightValue[index])) {
					return false;
				}
			} else if (leftValue !== rightValue) {
				return false;
			}
		}
	}
	return true;
};
