import { useMemo } from 'react';

import { Optional } from '@chroma-x/common/core/util';

type UrlSearchParams = {
	get: (name: string) => Optional<string>,
	has: (name: string) => boolean,
	all: () => Map<string, string>
};

/**
 * Custom hook to read from the URL search parameters.
 *
 * @returns An object with methods to get, check if a parameter exists, and get all parameters.
 */
export const useUrlSearchParams = (): UrlSearchParams => {
	const search = window.location.search;

	const urlSearchParams = useMemo(() => {
		return new URLSearchParams(search);
	}, [search]);

	return {
		/**
		 * Get the value of a specific search parameter.
		 *
		 * @param name - The name of the search parameter.
		 * @returns An Optional object that may contain the value of the search parameter, or null if it doesn't exist.
		 */
		get: (name: string): Optional<string> => {
			return new Optional(urlSearchParams.get(name));
		},
		/**
		 * Check if a specific search parameter exists.
		 *
		 * @param name - The name of the search parameter.
		 * @returns True if the search parameter exists, false otherwise.
		 */
		has: (name: string): boolean => {
			return urlSearchParams.has(name);
		},
		/**
		 * Get all search parameters as a map.
		 *
		 * @returns A map of all search parameters.
		 */
		all: (): Map<string, string> => {
			const all = new Map();
			urlSearchParams.forEach((value, key) => {
				all.set(key, value);
			});
			return all;
		}
	};

};
