import { useAuth } from '@chroma-x/frontend/core/react-auth-provider';
import { useL10n } from '@chroma-x/frontend/core/react-l10n';
import { useTenant } from '@chroma-x/frontend/core/react-tenant-provider';
import { ButtonSecondary, IconIdentifier } from '@chroma-x/frontend/core/react-ui-primitive';

import './header.scss';

export function Header() {

	const tenant = useTenant().get();
	const l10n = useL10n();
	const auth = useAuth();

	const handleLogout = () => {
		if (auth === null) {
			return;
		}
		auth.unauthenticate();
	};

	const renderLogo = () => {
		if (!tenant?.theme?.logo) {
			return (
				<img
					src="/assets/vse-net.svg"
					alt={l10n.translate('reactStarter.theme.tenantName')}
					className="header__logo"
				/>
			);
		}
		return (
			<img
				src={`/themes/assets/${tenant.theme.logo}`}
				alt={l10n.translate('reactStarter.theme.tenantName')}
				className="header__logo"
			/>
		);
	};

	const renderLogout = () => {
		if (auth === null || !auth.isAuthenticated()) {
			return null;
		}
		return (
			<ButtonSecondary
				label={l10n.translate('reactStarter.header.logout')}
				icon={IconIdentifier.SIGN_OUT}
				onClick={handleLogout}
			/>
		);
	};

	return (
		<section className="header">
			{renderLogo()}
			{renderLogout()}
		</section>
	);
}
