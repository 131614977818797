import { CSSProperties, ReactNode } from 'react';

import { buildCssClassNameFromEnumValue } from '@chroma-x/common/core/util';

import { CollectionItemCellAlign, CollectionItemCellWidth } from './collection-item-cell.enum';

import './collection-item-cell.scss';

export type CollectionItemCellProps = {
	children?: ReactNode,
	width?: CollectionItemCellWidth,
	baseWidth?: number,
	align?: CollectionItemCellAlign
};

export function CollectionItemCell(props: CollectionItemCellProps) {

	const { children, width = CollectionItemCellWidth.WIDTH_CONTENT, baseWidth, align = CollectionItemCellAlign.ALIGN_LEFT } = props;

	const widthCssClass = buildCssClassNameFromEnumValue(width, 'collection-item-cell');
	const alignCssClass = buildCssClassNameFromEnumValue(align, 'collection-item-cell');

	const inlineStyle: CSSProperties = {};
	if (baseWidth) {
		inlineStyle.flexBasis = baseWidth;
	}

	return (
		<section className={`collection-item-cell ${widthCssClass} ${alignCssClass}`} style={inlineStyle}>
			{children}
		</section>
	);

}
