/**
 * Sets the page title.
 *
 * @param title - The new title for the page.
 */
export const setPageTitle = (title: string): void => {
	document.title = title;
};

/**
 * Gets the current page title.
 *
 * @returns The current title of the page.
 */
export const getPageTitle = (): string => {
	return document.title;
};
