export class Timeout {

	/**
	 * Wrap a function call that returns a Promise to throw an error if the promise does not resolve in a given amount of time and
	 * optionally calls a given callback. Method is generic to manually set the wrapped functions response type wchich could be inderred
	 * usually.
	 * @example
	 * const response: Response = await Timeout.wrap(
	 * 	fetch(request),
	 * 	5000,
	 * 	new TimeoutError('Request timeout'),
	 * 	(): void => {
	 * 		this.abortController.abort();
	 * 	}
	 * );
	 * @param promise Wrapped async function call
	 * @param timeoutMilliseconds Amount of time for the promise to resolve
	 * @param error Error instance to be thrown if the promise resolves not in time
	 * @param callback Function to be executed if the promise resolves not in time **before** throwing the error
	 * @returns The promise result
	 */
	public static async wrap<T>(promise: Promise<T>, timeoutMilliseconds: number, error: Error, callback?: () => void): Promise<T> {
		return new Promise((resolve, reject) => {
			const timeoutId = setTimeout(() => {
				if (callback !== undefined) {
					callback();
				}
				reject(error);
			}, timeoutMilliseconds);
			promise.then(
				(res) => {
					clearTimeout(timeoutId);
					resolve(res);
				},
				(err) => {
					clearTimeout(timeoutId);
					reject(err);
				}
			);
		});
	}

}
