import { useSyncExternalStore } from 'react';

/**
 * Custom hook to get the network status.
 * It uses the useSyncExternalStore hook from React.
 *
 * @returns The current network status.
 */
export const useNetworkStatus = (): boolean => {
	const getSnapshot = (): boolean => {
		return navigator.onLine;
	};

	const subscribe = (callback: () => void): () => void => {
		window.addEventListener('online', callback);
		window.addEventListener('offline', callback);
		return () => {
			window.removeEventListener('online', callback);
			window.removeEventListener('offline', callback);
		};
	};

	return useSyncExternalStore(subscribe, getSnapshot);
};
