import { ModelPrimaryKey, ModelPrimaryKeyDto } from '@chroma-x/common/core/api-integration';

import { AbstractModelConverter } from './foundation/abstract-model-converter';

export class ModelPrimaryKeyConverter extends AbstractModelConverter<ModelPrimaryKey, ModelPrimaryKeyDto> {
	/**
	 * Converts a ModelPrimaryKey to a ModelPrimaryKeyDto.
	 *
	 * @param model - The ModelPrimaryKey to convert.
	 * @returns The converted ModelPrimaryKeyDto.
	 *
	 * @example
	 * const converter = new ModelPrimaryKeyConverter();
	 * const dto = converter.toDto(primaryKey);
	 * console.log(dto);
	 */
	public toDto(model: ModelPrimaryKey): ModelPrimaryKeyDto {
		return model;
	}

	/**
	 * Converts a ModelPrimaryKeyDto to a ModelPrimaryKey.
	 *
	 * @param dto - The ModelPrimaryKeyDto to convert.
	 * @returns The converted ModelPrimaryKey.
	 *
	 * @example
	 * const converter = new ModelPrimaryKeyConverter();
	 * const model = converter.toModel(primaryKeyDto);
	 * console.log(model);
	 */
	public toModel(dto: ModelPrimaryKeyDto): ModelPrimaryKey {
		return dto;
	}

}
