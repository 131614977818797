import process from 'process';

import { MockRequest, MockResponse, MockResponseFunction } from 'fetch-mock';

import { DeleteResponseDto, ModelPrimaryKeyDto } from '@chroma-x/common/core/api-integration';
import { ErrorDetailObject } from '@chroma-x/common/core/error';
import { JsonTransportValue } from '@chroma-x/common/core/json';

export type ResponseHeader = {
	key: string,
	values: Array<string>
};

/**
 * Creates headers for the mock server response based on the provided headers array.
 *
 * @param headers Array of response headers
 * @returns Headers object with the appropriate headers
 */
const createResponseHeaders = (headers: Array<ResponseHeader>): Headers => {
	const contentTypeHeader = headers.find((header) => {
		return header.key.toLowerCase() === 'content-type';
	});
	if (contentTypeHeader === undefined) {
		headers.push({
			key: 'Content-Type',
			values: ['application/json']
		});
	}

	const responseHeaders = new Headers();
	for (const header of headers) {
		responseHeaders.append(header.key, header.values.join(', '));
	}

	return responseHeaders;
};

/**
 * Creates a mock response object with the provided response data, status, and headers.
 *
 * @param responseData The response data to be sent
 * @param status The status code of the response
 * @param headers Array of response headers
 * @returns MockResponse object representing the response
 */
export const createMockResponse = (
	responseData: JsonTransportValue,
	status = 200,
	headers: Array<ResponseHeader> = []
): MockResponse => {
	return new Response(JSON.stringify(responseData), {
		status: status,
		headers: createResponseHeaders(headers)
	});
};

/**
 * Creates a function that generates a mock response based on the provided response data function, status, and headers.
 *
 * @param responseDataFn Function to generate response data based on URL and options
 * @param status The status code of the response
 * @param responseHeadersFn Function to generate response headers based on URL and options
 * @param enricherFn Function to generate data to be passed to responseDataFn and responseHeadersFn
 * @returns MockResponseFunction that generates the response
 */
export const createMockResponseFunction = <EnricherResult = undefined>(
	responseDataFn: (url: string, opts: MockRequest, enrichment?: EnricherResult) => Promise<JsonTransportValue>,
	status = 200,
	responseHeadersFn?: (url: string, opts: MockRequest, enrichment?: EnricherResult) => Promise<Array<ResponseHeader>>,
	enricherFn?: (url: string, opts: MockRequest) => Promise<EnricherResult>
): MockResponseFunction => {
	return async (url: string, opts: MockRequest): Promise<MockResponse> => {
		const enrichmentResult = enricherFn ? await enricherFn(url, opts) : undefined;
		const responseHeaders = responseHeadersFn ? await responseHeadersFn(url, opts, enrichmentResult) : [];
		const responseData = await responseDataFn(url, opts, enrichmentResult);
		return new Response(JSON.stringify(responseData), {
			status: status,
			headers: createResponseHeaders(responseHeaders)
		});
	};
};

/**
 * Creates a mock response object for the head request with the provided status and headers.
 *
 * @param status The status code of the response
 * @param headers Array of response headers
 * @returns MockResponse for head request
 */
export const createMockHeadResponse = (
	status = 200,
	headers: Array<ResponseHeader> = []
): MockResponse => {
	return {
		status: status,
		headers: createResponseHeaders(headers)
	};
};

/**
 * Creates a mock response object for a delete request with the provided data.
 *
 * @param id ModelPrimaryKeyDto ID for the delete response
 * @returns DeleteResponseDto object
 */
export const createMockDeleteResponse = (id: ModelPrimaryKeyDto): DeleteResponseDto => {
	return { id };
};

/**
 * Creates a mock error response object with the provided status and optional details.
 *
 * @param status The status code of the error response
 * @param details Optional array of error details
 * @returns MockResponse representing the error response
 */
export const createMockErrorResponse = (status: number, details?: Array<ErrorDetailObject>): MockResponse => {
	return {
		body: JSON.stringify(createErrorResponseBody(status, details)),
		status: status,
		headers: {
			'Content-Type': 'application/json'
		}
	};
};

/**
 * Creates mock options object with an optional delay value.
 *
 * @param delay Optional delay value in milliseconds
 * @returns Object with delay value
 */
export const createMockOptions = (delay?: number) => {
	delay = delay ?? parseInt(process.env.NX_PUBLIC_API_MOCK_DEFAULT_DELAY ?? '700');
	return {
		delay
	};
};

/**
 * Creates the error response body object with the provided status and optional details.
 *
 * @param status The status code of the error
 * @param details Optional array of error details
 * @returns JsonTransportValue representing the error response body
 */
const createErrorResponseBody = (status: number, details?: Array<ErrorDetailObject>): JsonTransportValue => {
	return {
		status: status,
		error: 'Something went wrong',
		timestamp: '2022-03-08T15:23:11.246Z',
		details: details ?? []
	};
};
