import { FunctionComponent, ReactNode } from 'react';

import { FilterCriteria, ModelPrimaryKey, SortCriteria } from '@chroma-x/common/core/api-integration';

export type ServiceProviderPendingProps = Record<string, never>;

export enum FetchMode {
	// Fetch starts with rendering
	IMMEDIATE = 'IMMEDIATE',
	// Fetch is started manually
	LAZY = 'LAZY'
}

type ServiceProviderFoundation = {
	fetchMode?: FetchMode,
	suspense?: boolean,
	pendingComponent?: FunctionComponent<ServiceProviderPendingProps>,
	children: ReactNode
};

export type CollectionServiceProviderProps<Model> = {
	forceFetch?: boolean,
	sortCriteria?: SortCriteria<Model>,
	filterCriteria?: FilterCriteria<Model>
} & ServiceProviderFoundation;

export type EntityServiceProviderProps = {
	forceFetch?: boolean,
	primaryKey: ModelPrimaryKey
} & ServiceProviderFoundation;

export type EntitySearchServiceProviderProps<Model> = {
	filterCriteria: FilterCriteria<Model>
} & ServiceProviderFoundation;

export type CreationServiceProviderProps = {
	mockApi?: boolean,
	children: ReactNode
};
