/* eslint-disable @typescript-eslint/naming-convention */
import process from 'process';

import { WordArray } from 'crypto-es/lib/core';
import { Base64 } from 'crypto-es/lib/enc-base64';
import { SHA256 } from 'crypto-es/lib/sha256';

import { AppError, AuthenticationFailedError, TimeoutError } from '@chroma-x/common/core/error';
import { Optional, Timeout } from '@chroma-x/common/core/util';
import { LocalStorage } from '@chroma-x/frontend/core/local-storage';
import { OauthApiClient, TokenResponseModel } from '@chroma-x/frontend/core/oauth-api-integration';

/**
 * KeycloakApiClient implements OauthApiClient.
 * This class provides methods to handle Keycloak authentication and authorization.
 */
export class KeycloakApiClient implements OauthApiClient {

	private readonly LOGIN_REDIRECT_ONE_TIME_CODE_PARAM = 'code';
	private readonly LOGIN_REDIRECT_SESSION_STATE_PARAM = 'session_state';
	private readonly LOGIN_REDIRECT_ISSUER_PARAM = 'iss';

	private readonly VERIFIER_LOCAL_STORAGE_KEY = 'oauthVerfifier';

	private readonly defaultRefreshTokenLifetime: number;
	private readonly defaultIdTokenLifetime: number;
	private readonly baseUrl: string;
	private readonly clientId: string;
	private readonly realm: string;

	/**
	 * Constructor for the KeycloakApiClient.
	 * It initializes the client with the necessary configuration.
	 *
	 * @param realm The realm to be used for all Keycloak interactions
	 */
	public constructor(realm: string) {
		const defaultRefreshTokenLifetime = new Optional(process.env.NX_PUBLIC_CORE_KEYCLOAK_DEFAULT_REFRESH_TOKEN_LIFETIME)
			.getOrThrow(new AppError('Keycloak default refresh token lifetime unavailable'));
		this.defaultRefreshTokenLifetime = parseInt(defaultRefreshTokenLifetime);
		const defaultIdTokenLifetime = new Optional(process.env.NX_PUBLIC_CORE_KEYCLOAK_DEFAULT_ID_TOKEN_LIFETIME)
			.getOrThrow(new AppError('Keycloak default id token lifetime unavailable'));
		this.defaultIdTokenLifetime = parseInt(defaultIdTokenLifetime);
		this.baseUrl = new Optional(process.env.NX_PUBLIC_CORE_KEYCLOAK_BASE_URL).getOrThrow(new AppError('Keycloak API URL unavailable'));
		this.clientId = new Optional(process.env.NX_PUBLIC_CORE_KEYCLOAK_CLIENT_ID).getOrThrow(new AppError('Keycloak client id unavailable'));
		this.realm = realm;
	}

	/**
	 * Returns an array of the parameters used in the login redirect URL.
	 *
	 * @returns The parameters used in the login redirect URL.
	 */
	public getLoginRedirectUrlParams(): Array<string> {
		return [
			this.LOGIN_REDIRECT_ONE_TIME_CODE_PARAM,
			this.LOGIN_REDIRECT_SESSION_STATE_PARAM,
			this.LOGIN_REDIRECT_ISSUER_PARAM
		];
	}

	/**
	 * Redirects the user to the Keycloak login page.
	 *
	 * @param redirectUrl - The URL to redirect the user to after login.
	 */
	public login(redirectUrl: string) {
		const challenge = this.base64Encode(SHA256(this.getVerifier()));
		const url = new URL(this.buildRealmUrl() + '/protocol/openid-connect/auth');
		url.searchParams.set('scope', 'openid');
		url.searchParams.set('response_type', 'code');
		url.searchParams.set('client_id', this.clientId);
		url.searchParams.set('code_challenge', challenge);
		url.searchParams.set('code_challenge_method', 'S256');
		url.searchParams.set('redirect_uri', redirectUrl);
		window.location.replace(url.toString());
	}

	/**
	 * Requests a token from the Keycloak server.
	 *
	 * @param redirectUrl - The URL to redirect the user to after login.
	 * @param redirectUrlParams - The parameters used in the login redirect URL.
	 * @returns A Promise that resolves to a TokenResponseModel.
	 * @throws AuthenticationFailedError if the token request fails.
	 */
	public async requestToken(redirectUrl: string, redirectUrlParams: Map<string, string>): Promise<TokenResponseModel> {
		const oauthCode = redirectUrlParams.get(this.LOGIN_REDIRECT_ONE_TIME_CODE_PARAM);

		const abortController = new AbortController();
		const request = new Request(
			this.buildRealmUrl() + '/protocol/openid-connect/token',
			{
				signal: abortController.signal,
				method: 'POST',
				cache: 'no-cache',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				},
				body: new URLSearchParams({
					grant_type: 'authorization_code',
					client_id: this.clientId,
					code_verifier: this.getVerifier(),
					code: oauthCode ?? '',
					redirect_uri: redirectUrl,
					scope: 'openid'
				}).toString()
			}
		);

		LocalStorage.remove(this.VERIFIER_LOCAL_STORAGE_KEY);

		const response = await Timeout.wrap<Response>(fetch(request), 5000, new TimeoutError('Request timeout'), (): void => {
			abortController.abort();
		});
		if (!response.ok) {
			throw new AuthenticationFailedError('Token request failed');
		}

		let responseBody: TokenResponseModel;
		try {
			responseBody = await response.json();
		} catch (e) {
			throw new AuthenticationFailedError('Token response invalid');
		}

		responseBody = {
			...responseBody,
			refresh_expires_in: responseBody.refresh_expires_in ?? this.defaultRefreshTokenLifetime,
			id_expires_in: responseBody.id_expires_in ?? this.defaultIdTokenLifetime
		};

		return responseBody;
	}

	/**
	 * Finishes the login flow
	 *
	 * @param redirectUrl - The redirect URL used in the auth flow
	 */
	public finishLogin(redirectUrl: string): void {
		window.location.replace(redirectUrl);
	}

	/**
	 * Refreshes the token.
	 *
	 * @param refreshToken - The refresh token.
	 * @returns A Promise that resolves to a TokenResponseModel.
	 * @throws AuthenticationFailedError if the token refresh fails.
	 */
	public async refreshToken(refreshToken: string): Promise<TokenResponseModel> {
		const abortController = new AbortController();
		const request = new Request(
			this.buildRealmUrl() + '/protocol/openid-connect/token',
			{
				signal: abortController.signal,
				method: 'POST',
				cache: 'no-cache',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				},
				body: new URLSearchParams({
					grant_type: 'refresh_token',
					client_id: this.clientId,
					refresh_token: refreshToken
				}).toString()
			}
		);

		const response = await Timeout.wrap<Response>(fetch(request), 5000, new TimeoutError('Request timeout'), (): void => {
			abortController.abort();
		});
		if (!response.ok) {
			throw new AuthenticationFailedError('Token refresh failed');
		}

		let responseBody: TokenResponseModel;
		try {
			responseBody = await response.json();
		} catch (e) {
			throw new AuthenticationFailedError('Token response invalid');
		}

		responseBody = {
			...responseBody,
			refresh_expires_in: responseBody.refresh_expires_in ?? this.defaultRefreshTokenLifetime,
			id_expires_in: responseBody.id_expires_in ?? this.defaultIdTokenLifetime
		};

		return responseBody;
	}

	/**
	 * Logs the user out by redirecting them to the Keycloak logout URL.
	 *
	 */
	public logout() {
		window.location.replace(this.buildRealmUrl() + '/protocol/openid-connect/logout');
	}

	private buildRealmUrl(): string {
		return this.baseUrl + 'realms/' + this.realm;
	}

	private getVerifier(): string {
		return LocalStorage.read<string>(this.VERIFIER_LOCAL_STORAGE_KEY).getOrCompute(() => {
			const verifier = this.base64Encode(WordArray.random(32));
			LocalStorage.write(this.VERIFIER_LOCAL_STORAGE_KEY, verifier);
			return verifier;
		});
	}

	private base64Encode(wordArray: WordArray): string {
		return wordArray.toString(Base64)
			.replace(/\+/g, '-')
			.replace(/\//g, '_')
			.replace(/=/g, '');
	}

}
