import fetchMock from 'fetch-mock';

/**
 * Preconfigures the fetch mock.
 *
 * This function sets several configuration options for the fetch mock.
 * The options are:
 * - `sendAsJson`: Set to `false` to disable sending JSON data.
 * - `overwriteRoutes`: Set to `false` to prevent routes from being overwritten.
 * - `fallbackToNetwork`: Set to `true` to fall back to the network if a route is not found.
 * - `includeContentLength`: Set to `false` to exclude the content length from the response.
 * - `warnOnFallback`: Set to `false` to disable warnings when falling back to the network.
 */
export const preconfigureFetchMock = () => {

	fetchMock.config.sendAsJson = false;
	fetchMock.config.overwriteRoutes = false;
	fetchMock.config.fallbackToNetwork = true;
	fetchMock.config.includeContentLength = false;
	fetchMock.config.warnOnFallback = false;

};
