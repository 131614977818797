export enum SingleValueFilterComparator {
	EQUAL = '==',
	NOT_EQUAL = '!==',
	LIKE = '=',
	NOT_LIKE = '!=',
	GREATER_THAN = '>',
	GREATER_THAN_EQUAL = '>=',
	LESS_THAN = '<',
	LESS_THAN_EQUAL = '<='
}

export enum MultiValueFilterComparator {
	ONE_OF = '='
}

export type FilterCriterionValue = string | number;

export type SingleValueFilterCriterion<Model> = {
	property: keyof Model,
	comparator?: SingleValueFilterComparator,
	value: FilterCriterionValue
};

export type SingleValueFreeFilterCriterion = {
	flavor: 'free',
	property: string,
	comparator?: SingleValueFilterComparator,
	value: FilterCriterionValue
};

export type MultiValueFilterCriterion<Model> = {
	property: keyof Model,
	comparator?: MultiValueFilterComparator,
	value: Array<FilterCriterionValue>
};

export type MultiValueFreeFilterCriterion = {
	flavor: 'free',
	property: string,
	comparator?: MultiValueFilterComparator,
	value: Array<FilterCriterionValue>
};

export type ValueFilterCriterion<Model> =
	SingleValueFilterCriterion<Model>
	| MultiValueFilterCriterion<Model>
	| SingleValueFreeFilterCriterion
	| MultiValueFreeFilterCriterion;

export type FilterCriterion<Model> = {
	id: string,
	criteria: Array<ValueFilterCriterion<Model>>
};

export type FilterCriteria<Model> = Array<FilterCriterion<Model>>;
