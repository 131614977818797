import { ReactNode } from 'react';

import { Footer } from '../footer/footer';
import { Header } from '../header/header';

import './app-layout.scss';

export type AppLayoutProps = {
	children: ReactNode
};

export function AppLayout(props: AppLayoutProps) {

	const { children } = props;

	return (
		<article className="app-layout">
			<header className="app-layout__header">
				<Header />
			</header>
			<main className="app-layout__main">
				{children}
			</main>
			<footer className="app-layout__footer">
				<Footer />
			</footer>
		</article>
	);

}
