/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	compareFilterCriteria,
	compareSortCriteria,
	FilterCriteria,
	ModelPrimaryKey,
	SortCriteria
} from '@chroma-x/common/core/api-integration';
import { Model } from '@chroma-x/frontend/core/domain-model';

import { CollectionServiceFoundation } from './collection-service-foundation';
import { FetchStatus, fetchStatusPendingGroup } from '../../meta/fetch-status';

/**
 * Determines if a fetch operation can proceed.
 *
 * @param state - The current state of the collection service.
 * @param sortCriteria - The sort criteria to compare.
 * @param filterCriteria - The filter criteria to compare.
 * @param forceFetch - If true, the precondition is ignored.
 * @returns True if the fetch operation can proceed, false otherwise.
 */
export const fetchCollectionPrecondition = <S extends CollectionServiceFoundation<M, any>, M extends Model>(
	state: Readonly<S>,
	sortCriteria?: SortCriteria<M>,
	filterCriteria?: FilterCriteria<M>,
	forceFetch?: boolean
): boolean => {
	// Abort if already pending
	if (fetchStatusPendingGroup.includes(state.data.meta.fetchStatus)) {
		return false;
	}
	// Proceed if force fetch
	if (forceFetch) {
		return true;
	}
	// Proceed if sort criteria has changed
	if (!compareSortCriteria<M>(sortCriteria, state.data.meta.sortCriteria ?? undefined)) {
		return true;
	}
	// Proceed if filter criteria has changed
	if (!compareFilterCriteria<M>(filterCriteria, state.data.meta.filterCriteria ?? undefined)) {
		return true;
	}
	// Cancel if store is populated
	return state.data.meta.fetchStatus === FetchStatus.IDLE;
};

/**
 * Determines if a fetch operation for the next page can proceed.
 *
 * @param state - The current state of the collection service.
 * @returns True if the fetch operation can proceed, false otherwise.
 */
export const fetchNextPagePrecondition = <S extends CollectionServiceFoundation<any, any>>(
	state: Readonly<S>
): boolean => {
	const currentPage = state.data.meta.currentPage ?? 1;
	const lastPage = state.data.meta.maxPages ?? 1;
	return !fetchStatusPendingGroup.includes(state.data.meta.fetchStatus) && currentPage < lastPage;
};

/**
 * Determines if a sort operation can proceed.
 *
 * @param state - The current state of the collection service.
 * @param sortCriteria - The sort criteria to compare.
 * @param forceFetch - If true, the precondition is ignored.
 * @returns True if the sort operation can proceed, false otherwise.
 */
export const applySortPrecondition = <S extends CollectionServiceFoundation<M, any>, M extends Model>(
	state: Readonly<S>,
	sortCriteria?: SortCriteria<M>,
	forceFetch?: boolean
): boolean => {
	// Abort if already pending
	if (fetchStatusPendingGroup.includes(state.data.meta.fetchStatus)) {
		return false;
	}
	// Proceed if force fetch
	if (forceFetch) {
		return true;
	}
	// Proceed if sort criteria has changed
	if (!compareSortCriteria<M>(sortCriteria, state.data.meta.sortCriteria ?? undefined)) {
		return true;
	}
	// Cancel if store is populated
	return state.data.meta.fetchStatus === FetchStatus.IDLE;
};

/**
 * Determines if a filter operation can proceed.
 *
 * @param state - The current state of the collection service.
 * @param filterCriteria - The filter criteria to compare.
 * @param forceFetch - If true, the precondition is ignored.
 * @returns True if the filter operation can proceed, false otherwise.
 */
export const applyFilterPrecondition = <S extends CollectionServiceFoundation<M, any>, M extends Model>(
	state: Readonly<S>,
	filterCriteria?: FilterCriteria<M>,
	forceFetch?: boolean
): boolean => {
	// Abort if already pending
	if (fetchStatusPendingGroup.includes(state.data.meta.fetchStatus)) {
		return false;
	}
	// Proceed if force fetch
	if (forceFetch) {
		return true;
	}
	// Proceed if filter criteria has changed
	if (!compareFilterCriteria<M>(filterCriteria, state.data.meta.filterCriteria ?? undefined)) {
		return true;
	}
	// Cancel if store is populated
	return state.data.meta.fetchStatus === FetchStatus.IDLE;
};

/**
 * Determines if a refetch operation can proceed.
 *
 * @param state - The current state of the collection service.
 * @returns True if the refetch operation can proceed, false otherwise.
 */
export const refetchCollectionPrecondition = <S extends CollectionServiceFoundation<any, any>>(
	state: Readonly<S>
): boolean => {
	// Abort if already pending
	return !fetchStatusPendingGroup.includes(state.data.meta.fetchStatus);
};

/**
 * Determines if a fetch entity operation can proceed.
 *
 * @param state - The current state of the collection service.
 * @param id - The ID of the entity to fetch.
 * @param forceFetch - If true, the precondition is ignored.
 * @returns True if the fetch entity operation can proceed, false otherwise.
 */
export const fetchCollectionEntityPrecondition = <S extends CollectionServiceFoundation<any, any>>(
	state: Readonly<S>,
	id: ModelPrimaryKey,
	forceFetch?: boolean
): boolean => {
	// Abort if already pending
	if (
		fetchStatusPendingGroup.includes(state.data.meta.fetchStatus)
		|| state.data.meta.fetchEntityStatus?.[id] === FetchStatus.PENDING
	) {
		return false;
	}
	// Ignore existing model if forceFetch
	if (forceFetch) {
		return true;
	}
	// Cancel if id is already existing
	const existingModel = state.data.models.find((entry) => {
		return entry.id === id;
	});
	return existingModel === undefined;
};

/**
 * Determines if a refetch entity operation can proceed.
 *
 * @param state - The current state of the collection service.
 * @param id - The ID of the entity to refetch.
 * @returns True if the refetch entity operation can proceed, false otherwise.
 */
export const refetchCollectionEntityPrecondition = <S extends CollectionServiceFoundation<any, any>>(
	state: Readonly<S>,
	id: ModelPrimaryKey
): boolean => {
	// Abort if already pending
	if (
		fetchStatusPendingGroup.includes(state.data.meta.fetchStatus)
		|| state.data.meta.fetchEntityStatus?.[id] === FetchStatus.PENDING
	) {
		return false;
	}
	// Cancel if id not already existing
	const existingModel = state.data.models.find((entry) => {
		return entry.id === id;
	});
	return existingModel !== undefined;
};
