import { Optional, Probably } from '@chroma-x/common/core/util';

import { TimestampDto, Timestamp } from './timestamp';
import { isTimestampDto, isTimestamp } from './timestamp.guards';

/**
 * Creates a Timestamp object from a Date object.
 * @param date - The date to convert.
 * @returns A Timestamp object.
 */
export const createTimestampFromDate = (date: Date): Timestamp => {
	return {
		value: date.toISOString()
	};
};

/**
 * Creates a Date object from a Timestamp object.
 * @param timestamp - The timestamp to convert.
 * @returns A Date object.
 */
export const createDateFromTimestamp = (timestamp: Timestamp): Date => {
	return new Date(timestamp.value);
};

/**
 * Creates a Date object from a MaybeTimestamp.
 * @param timestamp - The timestamp to convert.
 * @returns An Optional Date object.
 */
export const createDateFromMaybeTimestamp = (timestamp: Probably<Timestamp>): Optional<Date> => {
	const date = timestamp?.value ? new Date(timestamp.value) : null;
	return new Optional(date);
};

/**
 * Creates a Timestamp object from a MaybeTimestampDto.
 * @param timestampDto - The timestampDto to convert.
 * @returns An Optional Timestamp object.
 */
export const createTimestampFromTimestampDto = (timestampDto: Probably<TimestampDto>): Optional<Timestamp> => {
	let timestamp = null;
	if (isTimestampDto(timestampDto)) {
		timestamp = {
			value: timestampDto
		} as Timestamp;
	}
	return new Optional<Timestamp>(timestamp);
};

/**
 * Creates a TimestampDto from a MaybeTimestamp.
 * @param timestamp - The timestamp to convert.
 * @returns An Optional TimestampDto.
 */
export const createTimestampDtoFromTimestamp = (timestamp: Probably<Timestamp>): Optional<TimestampDto> => {
	let timestampDto = null;
	if (isTimestamp(timestamp)) {
		timestampDto = timestamp.value;
	}
	return new Optional<TimestampDto>(timestampDto);
};

/**
 * Creates a TimestampDto from a Date object.
 * @param date - The date to convert.
 * @returns A TimestampDto.
 */
export const createTimestampDtoFromDate = (date: Date): TimestampDto => {
	return date.toISOString();
};
