/**
 * This file is generated! Changes will be lost on generation!
 * @see /libs/common/domain/document/api-spec/tspconfig.yml
 */
import { ApiError } from '@chroma-x/common/core/error';
import { guardProbably, probablyResolves } from '@chroma-x/common/core/util';
import { AbstractResponseModelConverter, ModelPrimaryKeyConverter, TimestampConverter } from '@chroma-x/frontend/core/api-integration';
import { DocumentModel, DocumentType } from '@chroma-x/frontend/domain/document/domain-model';

import { DocumentDto } from '../../../../dto';

export class DocumentResponseModelConverter extends AbstractResponseModelConverter<DocumentModel, DocumentDto> {
	public toModel(dto: DocumentDto): DocumentModel {
		return {
			id: new ModelPrimaryKeyConverter().toModel(
				guardProbably(dto.id, new ApiError("Expected property 'id' of 'DocumentDto' missing."))
			),
			documentType: guardProbably(
				dto.documentType,
				new ApiError("Expected property 'documentType' of 'DocumentDto' missing.")
			) as DocumentType,
			billingPeriod: guardProbably(
				dto.billingPeriod,
				new ApiError("Expected property 'billingPeriod' of 'DocumentDto' missing.")
			) satisfies string,
			invoiceId: guardProbably(
				dto.invoiceId,
				new ApiError("Expected property 'invoiceId' of 'DocumentDto' missing.")
			) satisfies string,
			customerId: guardProbably(
				dto.customerId,
				new ApiError("Expected property 'customerId' of 'DocumentDto' missing.")
			) satisfies string,
			downloaded: guardProbably(
				dto.downloaded,
				new ApiError("Expected property 'downloaded' of 'DocumentDto' missing.")
			) satisfies boolean,
			plannedDeletionDate: probablyResolves(dto.plannedDeletionDate)
				? new TimestampConverter().toModel(dto.plannedDeletionDate)
				: dto.plannedDeletionDate,
			createdAt: new TimestampConverter().toModel(
				guardProbably(dto.createdAt, new ApiError("Expected property 'createdAt' of 'DocumentDto' missing."))
			)
		};
	}
}
