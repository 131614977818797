import { ReactNode } from 'react';

import './card.scss';

export type CardProps = {
	children: ReactNode
};

export function Card(props: CardProps) {

	const { children } = props;

	return (
		<section className="card">
			{children}
		</section>
	);
}
