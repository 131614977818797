import { castDraft } from 'immer';
import { createStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { ModelPrimaryKey } from '@chroma-x/common/core/api-integration';
import { combineUrl } from '@chroma-x/common/core/util';
import { startDownload } from '@chroma-x/frontend/core/browser';
import {
	CollectionServiceFoundation,
	registerManagedService,
	defaultCollectionServiceData,
	defaultCollectionServiceQuery,
	createFetchCollectionDefaultCommand,
	createFetchNextPageDefaultCommand,
	createApplySortDefaultCommand,
	createClearSortDefaultCommand,
	createApplyFilterDefaultCommand,
	createClearFilterDefaultCommand,
	createRefetchCollectionDefaultCommand,
	defaultCollectionServiceMetaOperation,
	Action,
	updateCollectionEntity,
	collectionActionTransactionStart,
	collectionActionTransactionSuccess,
	collectionActionTransactionFailed
} from '@chroma-x/frontend/core/service';
import { DocumentModel as ViewModel } from '@chroma-x/frontend/domain/document/domain-model';

import {
	DocumentProvisionServiceCommands as ServiceCommands
} from './document-provision-service-commands';
import { DocumentProvisionUseCases } from './use-case/document-provision-use-cases';

// Define some defaults and aliases
export const DOCUMENT_PROVISION_SERVICE = Symbol('DOCUMENT_PROVISION_SERVICE');

export type DocumentProvisionService = CollectionServiceFoundation<ViewModel, ServiceCommands>;
type Service = DocumentProvisionService;

// Create use case instance
const useCases = new DocumentProvisionUseCases();

export const documentProvisionService = createStore<Service>()(
	devtools(
		immer(
			(set, get, _storeApi) => {
				return {
					data: defaultCollectionServiceData(),
					meta: defaultCollectionServiceMetaOperation(get, set),
					command: {
						fetchCollection: createFetchCollectionDefaultCommand<Service, ViewModel, ServiceCommands>(
							get,
							set,
							async (
								sortCriteria,
								filterCriteria
							) => useCases.fetchCollection(sortCriteria, filterCriteria)
						),
						fetchNextPage: createFetchNextPageDefaultCommand<Service, ViewModel, ServiceCommands>(
							get,
							set,
							async (
								currentPage,
								sortCriteria,
								filterCriteria
							) => useCases.fetchPage(currentPage + 1, sortCriteria, filterCriteria)
						),
						applySort: createApplySortDefaultCommand<Service, ViewModel, ServiceCommands>(
							get,
							set,
							async (
								sortCriteria,
								filterCriteria
							) => useCases.fetchCollection(sortCriteria, filterCriteria)
						),
						clearSort: createClearSortDefaultCommand(async (forceFetch) => {
							const currentState = get();
							currentState.command.applySort(undefined, forceFetch);
						}),
						applyFilter: createApplyFilterDefaultCommand<Service, ViewModel, ServiceCommands>(
							get,
							set,
							async (
								sortCriteria,
								filterCriteria
							) => useCases.fetchCollection(sortCriteria, filterCriteria)
						),
						clearFilter: createClearFilterDefaultCommand(async (forceFetch) => {
							const currentState = get();
							currentState.command.applyFilter(undefined, forceFetch);
						}),
						refetchCollection: createRefetchCollectionDefaultCommand<Service, ViewModel, ServiceCommands>(
							get,
							set,
							async (
								sortCriteria,
								filterCriteria
							) => useCases.fetchCollection(sortCriteria, filterCriteria)
						),
						downloadDocument: async (id: ModelPrimaryKey): Promise<void> => {
							try {
								collectionActionTransactionStart<Service, ViewModel, ServiceCommands>(set, Action.COMMAND);
								const signedDownloadUrlModel = await useCases.getSignedDownloadUrl(id);
								const currentState = get();
								const model = currentState.data.models.find((value) => {
									return value.id === signedDownloadUrlModel.documentId;
								});
								if (model) {
									const mutatedModel = {
										...model,
										downloaded: true
									};
									updateCollectionEntity<Service, ViewModel, ServiceCommands>(get, set, id, castDraft(mutatedModel));
								}
								collectionActionTransactionSuccess<Service, ViewModel, ServiceCommands>(set, Action.COMMAND);
								if (signedDownloadUrlModel) {
									const downloadUrl = combineUrl(
										process.env.NX_PUBLIC_DOCUMENT_API_BASE_URL ?? '',
										signedDownloadUrlModel.signedUrl
									);
									startDownload(downloadUrl);
								}
							} catch (error) {
								collectionActionTransactionFailed<Service, ViewModel, ServiceCommands>(set, Action.COMMAND, error as Error);
							}
						}
					},
					query: defaultCollectionServiceQuery(get)
				};
			}
		),
		{ name: 'document-provision-collection-service', store: 'document-provision-collection-service' }
	)
);

registerManagedService(documentProvisionService);
