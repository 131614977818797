import { ThemeDefinition } from '@chroma-x/frontend/core/react-tenant-provider';

export const energisTheme: ThemeDefinition = {
	cssFiles: ['energis-theme.css', 'energis-theme-custom.css'],
	logo: 'energis.png',
	favicon: 'energis-favicon.png',
	literals: [
		{
			localeIdentifier: 'de',
			literals: {
				reactStarter: {
					appName: 'Energis Dokumente',
					theme: {
						tenantName: 'Energis'
					}
				}
			}
		},
		{
			localeIdentifier: 'en',
			literals: {
				reactStarter: {
					appName: 'Energis Documents',
					theme: {
						tenantName: 'Energis'
					}
				}
			}
		}
	]
};
