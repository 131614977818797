/**
 * Generates a mock collection response.
 *
 * @template R The type of objects in the collection.
 * @param constructor A function that constructs an object of type R.
 * @param page The page number.
 * @param itemsPerPage The number of items per page.
 * @param [amount] The total number of items. If not set, a full page is created.
 * @returns The generated collection response.
 */
export const createMockCollectionResponse = <R extends object>(
	constructor: (index: number) => R,
	page: number,
	itemsPerPage: number,
	amount?: number
): Array<R> => {
	const collectionResponseData = [];
	const startIndex = (page - 1) * itemsPerPage;
	const endIndex = startIndex + (amount ?? itemsPerPage);
	for (let i = startIndex; i < endIndex; i++) {
		const entry = constructor(i);
		collectionResponseData.push(entry);
	}
	return collectionResponseData;
};
