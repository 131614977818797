import { TimestampDto, Timestamp } from './timestamp';

/**
 * Check if the given value is a Timestamp.
 *
 * @param timestamp - The value to check.
 * @returns True if the value is a Timestamp, false otherwise.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isTimestamp = (timestamp?: any): timestamp is Timestamp => {
	if ((timestamp ?? null) === null) {
		return false;
	}
	return (timestamp as Timestamp).value !== undefined
		&& typeof timestamp.value === 'string';
};

/**
 * Check if the given value is a TimestampDto.
 *
 * @param timestampDto - The value to check.
 * @returns True if the value is a TimestampDto, false otherwise.
 */
export const isTimestampDto = (timestampDto?: unknown): timestampDto is TimestampDto => {
	if ((timestampDto ?? null) === null) {
		return false;
	}
	return typeof timestampDto === 'string';
};
