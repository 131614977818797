import { Component, ReactElement, ReactNode } from 'react';

import { Nullable } from '@chroma-x/common/core/util';
import { Logger, LogLevel } from '@chroma-x/frontend/core/logger';
import { withLogger } from '@chroma-x/frontend/core/react-logger';

export type ErrorHandlerProps = {
	errorComponent: (error: Error) => Nullable<ReactElement>,
	transparent?: boolean,
	logger?: Logger,
	children?: ReactNode
};

type ErrorHandlerState = {
	error?: Error
};

/**
 * ErrorHandlerFoundation is a React component that handles errors thrown during rendering.
 * It uses the getDerivedStateFromError lifecycle method to set the error state.
 * The error is then logged and rendered using the errorComponent prop.
 * If the errorComponent returns null, the error is rethrown.
 */
class ErrorHandlerFoundation extends Component<ErrorHandlerProps, ErrorHandlerState> {

	/**
	 * Constructor for ErrorHandlerFoundation.
	 * @param props - The props for the component.
	 */
	public constructor(props: ErrorHandlerProps) {
		super(props);
		this.state = {
			error: undefined
		};
	}

	/**
	 * getDerivedStateFromError is a static method that sets the error state.
	 * @param error - The error that occurred.
	 * @returns The new state.
	 */
	public static getDerivedStateFromError(error: Error): ErrorHandlerState {
		return {
			error: error
		};
	}

	/**
	 * Logs the error using the logger prop.
	 * @param error - The error to log.
	 */
	private logError(error: Error) {
		if (!this.props.logger) {
			return;
		}
		void this.props.logger.logError(error, LogLevel.ERROR);
	}

	/**
	 * Renders the error using the errorComponent prop.
	 * If the errorComponent returns null, the error is rethrown.
	 * @param error - The error to render.
	 * @returns The rendered error.
	 */
	private renderError(error: Error) {
		const errorComponent = this.props.errorComponent(error);
		if (errorComponent === null) {
			throw error;
		}
		const children = this.props.transparent ? this.props.children : null;
		return (
			<>
				{errorComponent}
				{children}
			</>
		);
	}

	/**
	 * Renders the component.
	 * If an error is present, logs and renders the error.
	 * Otherwise, renders the children.
	 * @returns The rendered component.
	 */
	public override render() {
		const error = this.state.error;
		if (error !== undefined) {
			this.logError(error);
			return this.renderError(error);
		}
		return this.props.children;
	}

}

/**
 * Higher-order component (HOC) that wraps the ErrorHandlerFoundation component
 * with the withLogger HOC.
 *
 * @param props - The props passed to the ErrorHandler component.
 * @returns The rendered ErrorHandler component.
 */
export const ErrorHandler = withLogger(ErrorHandlerFoundation);
