import { createContext } from 'react';

import { AppError } from '@chroma-x/common/core/error';
import { Optional } from '@chroma-x/common/core/util';

export type EnvironmentValue = string | number | boolean | Array<unknown>;
export type Environment = { [_: string]: EnvironmentValue };

/**
 * The EnvContextValue type represents the shape of the object that is provided by the `envContext` React context.
 *
 * It has a single method get which takes a `key` of type `string` and returns an `Optional` value of type `T` (defaults to EnvironmentValue).
 */
export type EnvContextValue = {
	/**
	 * The get method retrieves a value from the environment context.
	 *
	 * @param key The key of the value to retrieve.
	 * @returns An `Optional` value of type `T`.
	 */
	get<T = EnvironmentValue>(key: string): Optional<T>
};

function getter<T = EnvironmentValue>(_key: string): Optional<T> {
	throw new AppError('Environment provider not found');
}

export const envContext = createContext<EnvContextValue>({
	get: getter
});
