import React, { ReactNode, useEffect, useState } from 'react';

import { AccessToken, AuthHandler, IdToken, RefreshToken } from '@chroma-x/frontend/core/auth-handler';
import { LocalStorage } from '@chroma-x/frontend/core/local-storage';
import { OauthApiClient, OauthApiClientInstrument } from '@chroma-x/frontend/core/oauth-api-integration';

import { authContext } from './auth-context';

export type AuthProviderProps = {
	children: ReactNode,
	oauthApiClient: OauthApiClient,
	authScope?: string,
	onAuthenticate?: () => void,
	onAuthenticated?: () => void,
	onUpdate?: () => void,
	onReauthenticate?: () => void,
	onUnauthenticate?: () => void
	onUnauthenticated?: () => void
};

/**
 * AuthProvider component.
 *
 * This component provides authentication context to its child components.
 * It uses the OauthApiClient to handle the authentication.
 *
 * @param props - The component props.
 * @param props.oauthApiClient - The OauthApiClient instance.
 * @param [props.authScope] - The authentication scope.
 * @param [props.onAuthenticate] - Callback function called when the user is authenticated.
 * @param [props.onAuthenticated] - Callback function called when the user is authenticated.
 * @param [props.onUpdate] - Callback function called when the authentication is updated.
 * @param [props.onReauthenticate] - Callback function called when the user is re-authenticated.
 * @param [props.onUnauthenticate] - Callback function called when the user is unauthenticated.
 * @param [props.onUnauthenticated] - Callback function called when the user is unauthenticated.
 *
 * @returns The AuthProvider component.
 */
export const AuthProvider = (props: AuthProviderProps) => {
	const {
		children,
		oauthApiClient,
		authScope,
		onAuthenticate = null,
		onAuthenticated = null,
		onUpdate = null,
		onReauthenticate = null,
		onUnauthenticate = null,
		onUnauthenticated = null
	} = props;

	OauthApiClientInstrument.setClient(oauthApiClient);

	const authHandler = AuthHandler.get();
	if (authScope) {
		authHandler.setScope(authScope);
	}

	const [authorized, setAuthorized] = useState<boolean>(authHandler.isAuthenticated());

	useEffect(() => {
		LocalStorage.addListener(handleStorageChange);
		return () => {
			LocalStorage.removeListener(handleStorageChange);
		};
	}, []);

	const handleStorageChange = () => {
		const authenticated = authHandler.isAuthenticated();
		if (authorized && !authenticated && onUnauthenticated !== null) {
			onUnauthenticated();
		} else if (!authorized && authenticated && onAuthenticated !== null) {
			onAuthenticated();
		}
		setAuthorized(authenticated);
	};

	const authContextValue = {
		getScope: (): string | undefined => {
			return authHandler.getScope();
		},
		authenticate: (
			accessToken: AccessToken,
			accessTokenValidTo: Date,
			idToken?: IdToken,
			idTokenValidTo?: Date,
			refreshToken?: RefreshToken,
			refreshTokenValidTo?: Date
		): void => {
			authHandler.authenticate(accessToken, accessTokenValidTo, idToken, idTokenValidTo, refreshToken, refreshTokenValidTo);
			setAuthorized(true);
			if (onAuthenticate !== null) {
				onAuthenticate();
			}
		},
		update: (
			accessToken: AccessToken,
			accessTokenValidTo: Date,
			idToken?: IdToken,
			idTokenValidTo?: Date,
			refreshToken?: RefreshToken,
			refreshTokenValidTo?: Date
		): void => {
			authHandler.update(accessToken, accessTokenValidTo, idToken, idTokenValidTo, refreshToken, refreshTokenValidTo);
			if (onUpdate !== null) {
				onUpdate();
			}
		},
		reauthenticate: (): void => {
			authHandler.unauthenticate();
			if (onReauthenticate !== null) {
				onReauthenticate();
			}
			setAuthorized(false);
		},
		unauthenticate: (): void => {
			authHandler.unauthenticate();
			if (onUnauthenticate !== null) {
				onUnauthenticate();
			} else {
				setAuthorized(false);
			}
		},
		getToken: authHandler.getToken,
		getTokenDetails: authHandler.getTokenDetails,
		isAuthenticated: authHandler.isAuthenticated,
		needsUpdate: authHandler.needsUpdate
	};

	return (
		<authContext.Provider value={authContextValue}>
			{children}
		</authContext.Provider>
	);

};
