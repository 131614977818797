import { Nullable } from '@chroma-x/common/core/util';

import { EventData } from './event-data';
import { LogLevel } from './log-level';
import { Logger } from './logger';

export class ConsoleLogger implements Logger {

	private lastError: Nullable<Error> = null;
	private lastMessage: unknown = null;

	private readonly doubleLogProtection: boolean;
	private readonly filterError: Nullable<(error: Error, level: LogLevel) => boolean>;
	private readonly filterMessage: Nullable<(message: unknown, level: LogLevel) => boolean>;

	/**
	 * Returns a new logger instance to grant straight access to the logger.
	 *
	 * @returns The console logger
	 */
	public static instrument(): ConsoleLogger {
		return new ConsoleLogger();
	}

	/**
	 * Creates a new ConsoleLogger.
	 *
	 * @param doubleLogProtection - Whether to enable double log protection.
	 * @param filterError - A filter function for errors.
	 * @param filterMessage - A filter function for messages.
	 */
	public constructor(
		doubleLogProtection?: boolean,
		filterError?: (error: Error, level: LogLevel) => boolean,
		filterMessage?: (message: unknown, level: LogLevel) => boolean
	) {
		this.doubleLogProtection = doubleLogProtection ?? false;
		this.filterError = filterError ?? null;
		this.filterMessage = filterMessage ?? null;
	}

	/**
	 * Logs an event.
	 *
	 * @param message - The message to log.
	 * @param data - Additional data to log.
	 * @param category - The category of the event.
	 * @param level - The log level.
	 * @returns A Promise that resolves when the event is logged.
	 */
	public async logEvent(message: string, data?: EventData, category?: string, level: LogLevel = LogLevel.INFO): Promise<void> {
		const logMessageParts: Array<Nullable<string>> = [];
		if (data) {
			logMessageParts.push(data.type);
		}
		if (category) {
			logMessageParts.push(category + ':');
		}
		logMessageParts.push(message);
		this.log(level, ...logMessageParts);
		if (data?.payload !== undefined) {
			console.table(data.payload);
		}
	}

	/**
	 * Logs an error.
	 *
	 * @param error - The error to log.
	 * @param level - The log level.
	 * @returns A Promise that resolves when the error is logged.
	 */
	public async logError(error: Error, level: LogLevel = LogLevel.LOG): Promise<void> {
		if (this.doubleLogProtection && this.lastError === error) {
			return;
		}
		if (this.filterError === null || this.filterError(error, level)) {
			this.lastError = error;
			this.log(level, error);
		}
	}

	/**
	 * Logs a message.
	 *
	 * @param message - The message to log.
	 * @param level - The log level.
	 * @returns A Promise that resolves when the message is logged.
	 */
	public async logMessage(message: unknown, level: LogLevel = LogLevel.LOG): Promise<void> {
		if (this.doubleLogProtection && this.lastMessage === message) {
			return;
		}
		if (this.filterMessage === null || this.filterMessage(message, level)) {
			this.lastMessage = message;
			this.log(level, message);
		}
	}

	private log(level: LogLevel, ...data: Array<unknown>) {
		switch (level) {
			case LogLevel.DEBUG:
				console.debug('[ConsoleLogger]', ...data);
				break;
			case LogLevel.INFO:
				console.info('[ConsoleLogger]', ...data);
				break;
			case LogLevel.LOG:
				console.log('[ConsoleLogger]', ...data);
				break;
			case LogLevel.WARN:
				console.warn('[ConsoleLogger]', ...data);
				break;
			case LogLevel.ERROR:
			case LogLevel.FATAL:
				console.error('[ConsoleLogger]', ...data);
				break;
		}
	}

}
