import { useContext } from 'react';

import { authContext } from './auth-context';

/**
 * Custom React hook that returns the authentication context.
 *
 * @returns The authentication context.
 */
export const useAuth = () => {
	return useContext(authContext);
};
