import './tag.scss';

export type TagProps = {
	label: string
};

export function Tag(props: TagProps) {

	const { label } = props;

	return (
		<div className="tag">
			{label}
		</div>
	);
}
