/* eslint-disable @typescript-eslint/no-explicit-any */
import { isArray } from './array';

export enum MutateNullStrategy {
	KEEP = 'KEEP',
	OMIT = 'OMIT'
}

/**
 * Deeply mutates an object based on a given mutation object.
 *
 * @param data - The object to mutate.
 * @param mutation - The object containing the mutations.
 * @param nullStrategy - The strategy to handle null values.
 * @returns The mutated object.
 */
export const mutate = <D extends Record<string, any>, M extends Record<string, any>>(
	data: D,
	mutation: M,
	nullStrategy = MutateNullStrategy.OMIT
): D => {
	data = { ...data };
	for (const key of Object.keys(data)) {
		if (!(key in mutation)) {
			continue;
		}
		const mutationProperty = mutation[key];
		if (mutationProperty === null) {
			switch (nullStrategy) {
				case MutateNullStrategy.KEEP:
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					data[(key as keyof D)] = null;
					break;
				case MutateNullStrategy.OMIT:
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					data[(key as keyof D)] = undefined;
					break;
			}
			continue;
		}
		if (isArray(mutationProperty)) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			data[(key as keyof D)] = mutationProperty;
			continue;
		} else if (typeof mutationProperty === 'object') {
			if (typeof data[key] === 'object') {
				data[(key as keyof D)] = mutate(data[key], mutationProperty, nullStrategy);
			} else {
				data[(key as keyof D)] = mutationProperty;
			}
			continue;
		}
		data[(key as keyof D)] = mutationProperty;
	}
	return data;
};
