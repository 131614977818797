import { MockMatcherFunction } from 'fetch-mock';

import { isArray, Maybe } from '@chroma-x/common/core/util';

/**
 * Matcher function that checks if the URL matches the specified pattern, has the required query params and optionally requires valid
 * authentication headers.
 *
 * @param urlPattern The URL pattern to match the URL.
 * @param requiredQueryParams The URL param names to match the URL.
 * @param validAuthRequired Flag indicating if valid authentication headers are required.
 * @returns A function that determines if the URL matches with the specified pattern.
 */
export function createMockMatcher(urlPattern: string, requiredQueryParams: Array<string>, validAuthRequired?: boolean): MockMatcherFunction;
/**
 * Matcher function that checks if the URL matches the specified pattern and optionally requires valid authentication headers.
 *
 * @param urlPattern The URL pattern to match the URL.
 * @param validAuthRequired Flag indicating if valid authentication headers are required.
 * @returns A function that determines if the URL matches with the specified pattern.
 */
export function createMockMatcher(urlPattern: string, validAuthRequired?: boolean): MockMatcherFunction;
export function createMockMatcher(
	urlPattern: string,
	requiredQueryParamsOrAuthRequired: Array<string> | Maybe<boolean>,
	validAuthRequired: Maybe<boolean> = false
): MockMatcherFunction {
	const urlPatternUrl = new URL(urlPattern);
	urlPatternUrl.searchParams.forEach((_, key) => {
		urlPatternUrl.searchParams.delete(key);
	});

	return (url, opts): boolean => {
		const requestUrl = new URL(url);
		const requestQueryParamNames: Array<string> = [];
		requestUrl.searchParams.forEach((_, key) => {
			requestQueryParamNames.push(key);
			requestUrl.searchParams.delete(key);
		});
		if (urlPatternUrl.toString().toLowerCase() !== requestUrl.toString().toLowerCase()) {
			return false;
		}
		if (isArray(requiredQueryParamsOrAuthRequired)) {
			for (const requiredQueryParam of requiredQueryParamsOrAuthRequired) {
				if (!requestQueryParamNames.includes(requiredQueryParam)) {
					return false;
				}
			}
		} else {
			validAuthRequired = requiredQueryParamsOrAuthRequired;
		}
		if (validAuthRequired === true) {
			const headers = opts.headers as HeadersInit;
			if (!checkAuthHeader(headers)) {
				return false;
			}
		}
		return true;
	};
}

/**
 * Matcher function that checks if the URL starts with the specified pattern, has the required query params and optionally requires valid
 * authentication headers.
 *
 * @param urlPattern The URL pattern to match the beginning of the URL.
 * @param requiredQueryParams The URL param names to match the URL.
 * @param validAuthRequired Flag indicating if valid authentication headers are required.
 * @returns A function that determines if the URL matches with the specified pattern.
 */
export function createMockBeginMatcher(urlPattern: string, requiredQueryParams: Array<string>, validAuthRequired?: boolean): MockMatcherFunction;
/**
 * Matcher function that checks if the URL starts with the specified pattern and optionally requires valid authentication headers.
 *
 * @param urlPattern The URL pattern to match the beginning of the URL.
 * @param validAuthRequired Flag indicating if valid authentication headers are required.
 * @returns A function that determines if the URL matches with the specified pattern.
 */
export function createMockBeginMatcher(urlPattern: string, validAuthRequired?: boolean): MockMatcherFunction;
export function createMockBeginMatcher(
	urlPattern: string,
	requiredQueryParamsOrAuthRequired: Array<string> | Maybe<boolean>,
	validAuthRequired: Maybe<boolean> = false
): MockMatcherFunction {
	const urlPatternUrl = new URL(urlPattern);
	urlPatternUrl.searchParams.forEach((_, key) => {
		urlPatternUrl.searchParams.delete(key);
	});

	return (url, opts): boolean => {
		const requestUrl = new URL(url);
		const requestQueryParamNames: Array<string> = [];
		requestUrl.searchParams.forEach((_, key) => {
			requestQueryParamNames.push(key);
			requestUrl.searchParams.delete(key);
		});
		if (!requestUrl.toString().toLowerCase().startsWith(urlPatternUrl.toString().toLowerCase())) {
			return false;
		}
		if (isArray(requiredQueryParamsOrAuthRequired)) {
			for (const requiredQueryParam of requiredQueryParamsOrAuthRequired) {
				if (!requestQueryParamNames.includes(requiredQueryParam)) {
					return false;
				}
			}
		} else {
			validAuthRequired = requiredQueryParamsOrAuthRequired;
		}
		if (validAuthRequired) {
			const headers = opts.headers as HeadersInit;
			if (!checkAuthHeader(headers)) {
				return false;
			}
		}
		return true;
	};
}

/**
 * Matcher function that checks if the URL includes the specified path pattern, has the required query params and optionally requires valid
 * authentication headers.
 *
 * @param pathPattern The path pattern to match within the URL.
 * @param requiredQueryParams The URL param names to match the URL.
 * @param validAuthRequired Flag indicating if valid authentication headers are required.
 * @returns A function that determines if the URL matches with the specified pattern.
 */
export function createMockContainsMatcher(pathPattern: string, requiredQueryParams: Array<string>, validAuthRequired?: boolean): MockMatcherFunction;
/**
 * Matcher function that checks if the URL includes the specified path pattern and optionally requires valid authentication headers.
 *
 * @param pathPattern The path pattern to match within the URL.
 * @param validAuthRequired Flag indicating if valid authentication headers are required.
 * @returns A function that determines if the URL matches with the specified pattern.
 */
export function createMockContainsMatcher(pathPattern: string, validAuthRequired?: boolean): MockMatcherFunction;
export function createMockContainsMatcher(
	pathPattern: string,
	requiredQueryParamsOrAuthRequired: Array<string> | Maybe<boolean>,
	validAuthRequired: Maybe<boolean> = false
): MockMatcherFunction {
	return (url, opts): boolean => {
		const requestUrl = new URL(url);
		const requestQueryParamNames: Array<string> = [];
		requestUrl.searchParams.forEach((_, key) => {
			requestQueryParamNames.push(key);
			requestUrl.searchParams.delete(key);
		});
		if (!requestUrl.toString().toLowerCase().includes(pathPattern.toLowerCase())) {
			return false;
		}
		if (isArray(requiredQueryParamsOrAuthRequired)) {
			for (const requiredQueryParam of requiredQueryParamsOrAuthRequired) {
				if (!requestQueryParamNames.includes(requiredQueryParam)) {
					return false;
				}
			}
		} else {
			validAuthRequired = requiredQueryParamsOrAuthRequired;
		}
		if (validAuthRequired) {
			const headers = opts.headers as HeadersInit;
			if (!checkAuthHeader(headers)) {
				return false;
			}
		}
		return true;
	};
}

/**
 * Matcher function that checks if the URL starts with a specified pattern and contains another path pattern, has the required query
 * params and optionally requires valid
 * authentication headers.
 *
 * @param urlPattern The URL pattern to match the beginning of the URL.
 * @param pathPattern The path pattern to match within the URL.
 * @param requiredQueryParams The URL param names to match the URL.
 * @param validAuthRequired Flag indicating if valid authentication headers are required.
 * @returns A function that determines if the URL matches with the specified pattern.
 */
export function createMockBeginAndContainsMatcher(
	urlPattern: string,
	pathPattern: string,
	requiredQueryParams: Array<string>,
	validAuthRequired?: boolean
): MockMatcherFunction;
/**
 * Matcher function that checks if the URL starts with a specified pattern and contains another path pattern, and optionally requires
 * valid authentication headers.
 *
 * @param urlPattern The URL pattern to match the beginning of the URL.
 * @param pathPattern The path pattern to match within the URL.
 * @param validAuthRequired Flag indicating if valid authentication headers are required.
 * @returns A function that determines if the URL matches with the specified pattern.
 */
export function createMockBeginAndContainsMatcher(
	urlPattern: string,
	pathPattern: string,
	validAuthRequired?: boolean
): MockMatcherFunction;
export function createMockBeginAndContainsMatcher(
	urlPattern: string,
	pathPattern: string,
	requiredQueryParamsOrAuthRequired: Array<string> | Maybe<boolean>,
	validAuthRequired: Maybe<boolean> = false
): MockMatcherFunction {
	let beginMatcher: MockMatcherFunction;
	let containsMatcher: MockMatcherFunction;
	if (isArray(requiredQueryParamsOrAuthRequired)) {
		beginMatcher = createMockBeginMatcher(urlPattern, requiredQueryParamsOrAuthRequired, validAuthRequired);
		containsMatcher = createMockContainsMatcher(pathPattern, requiredQueryParamsOrAuthRequired, validAuthRequired);
	} else {
		beginMatcher = createMockBeginMatcher(urlPattern, validAuthRequired);
		containsMatcher = createMockContainsMatcher(pathPattern, validAuthRequired);
	}
	return (url, opts): boolean => {
		return beginMatcher(url, opts) && containsMatcher(url, opts);
	};
}

/**
 * Matcher function that checks if the URL starts with a specified pattern or contains another path pattern, has the required query
 * params and optionally requires valid
 * authentication headers.
 *
 * @param urlPattern The URL pattern to match the beginning of the URL.
 * @param pathPattern The path pattern to match within the URL.
 * @param requiredQueryParams The URL param names to match the URL.
 * @param validAuthRequired Flag indicating if valid authentication headers are required.
 * @returns A function that determines if the URL matches with the specified pattern.
 */
export function createMockBeginOrContainsMatcher(
	urlPattern: string,
	pathPattern: string,
	requiredQueryParams: Array<string>,
	validAuthRequired?: boolean
): MockMatcherFunction;
/**
 * Matcher function that checks if the URL starts with a specified pattern or contains another path pattern, and optionally requires
 * valid authentication headers.
 *
 * @param urlPattern The URL pattern to match the beginning of the URL.
 * @param pathPattern The path pattern to match within the URL.
 * @param validAuthRequired Flag indicating if valid authentication headers are required.
 * @returns A function that determines if the URL matches with the specified pattern.
 */
export function createMockBeginOrContainsMatcher(
	urlPattern: string,
	pathPattern: string,
	validAuthRequired?: boolean
): MockMatcherFunction;
export function createMockBeginOrContainsMatcher(
	urlPattern: string,
	pathPattern: string,
	requiredQueryParamsOrAuthRequired: Array<string> | Maybe<boolean>,
	validAuthRequired: Maybe<boolean> = false
): MockMatcherFunction {
	let beginMatcher: MockMatcherFunction;
	let containsMatcher: MockMatcherFunction;
	if (isArray(requiredQueryParamsOrAuthRequired)) {
		beginMatcher = createMockBeginMatcher(urlPattern, requiredQueryParamsOrAuthRequired, validAuthRequired);
		containsMatcher = createMockContainsMatcher(pathPattern, requiredQueryParamsOrAuthRequired, validAuthRequired);
	} else {
		beginMatcher = createMockBeginMatcher(urlPattern, validAuthRequired);
		containsMatcher = createMockContainsMatcher(pathPattern, validAuthRequired);
	}
	return (url, opts): boolean => {
		return beginMatcher(url, opts) || containsMatcher(url, opts);
	};
}

/**
 * Checks if the provided headers contain a valid 'Authorization' header with a 'Bearer' token.
 *
 * @param headers The headers object to check for the 'Authorization' header.
 * @returns True if the 'Authorization' header has a 'Bearer' token, false otherwise.
 */
const checkAuthHeader = (headers: HeadersInit): boolean => {
	const authHeaderName = Object.keys(headers).find((headerName) => {
		return headerName.toLowerCase() === 'authorization';
	});
	// No auth header
	if (!authHeaderName) {
		return false;
	}
	const authHeaderValue = (headers as Record<string, string>)?.[authHeaderName] ?? null;
	// No auth header value
	if (authHeaderValue === null) {
		return false;
	}
	// Invalid auth header
	return String(authHeaderValue).startsWith('Bearer ');
};
