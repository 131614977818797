import { Timestamp, TimestampDto, createTimestampDtoFromTimestamp, createTimestampFromTimestampDto } from '@chroma-x/common/core/data-type';
import { ApiError } from '@chroma-x/common/core/error';

import { AbstractModelConverter } from './foundation/abstract-model-converter';

export class TimestampConverter extends AbstractModelConverter<Timestamp, TimestampDto> {
	/**
	 * Converts a Timestamp to a TimestampDto.
	 *
	 * @param model - The Timestamp object to convert.
	 * @returns The converted TimestampDto.
	 * @throws ApiError if the conversion fails.
	 *
	 * @example
	 * const timestamp = createTimestampFromDate(new Date());
	 * const timestampConverter = new TimestampConverter();
	 * const timestampDto = timestampConverter.toDto(timestamp);
	 * console.log(timestampDto);
	 */
	public toDto(model: Timestamp): TimestampDto {
		return createTimestampDtoFromTimestamp(model).getOrThrow(new ApiError('Timestamp conversion failed'));
	}

	/**
	 * Converts a TimestampDto to a Timestamp.
	 *
	 * @param dto - The TimestampDto object to convert.
	 * @returns The converted Timestamp.
	 * @throws ApiError if the conversion fails.
	 *
	 * @example
	 * const timestampDto = createTimestampDtoFromDate(new Date());
	 * const timestampConverter = new TimestampConverter();
	 * const timestamp = timestampConverter.toModel(timestampDto);
	 * console.log(timestamp);
	 */
	public toModel(dto: TimestampDto): Timestamp {
		return createTimestampFromTimestampDto(dto).getOrThrow(new ApiError('Timestamp conversion failed'));
	}

}
