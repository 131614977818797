import {
	FilterCriteria,
	FilterCriterion,
	FilterCriterionValue,
	SingleValueFilterComparator,
	ValueFilterCriterion
} from '@chroma-x/common/core/api-integration';
import { Nullable } from '@chroma-x/common/core/util';
import { FilterOptionGroup } from '@chroma-x/frontend/core/api-integration';
import { DocumentModel } from '@chroma-x/frontend/domain/document/domain-model';

export enum DocumentFilterOption {
	BILLING_PERIOD_ALL = 'BILLING_PERIOD_ALL',
	BILLING_PERIOD_THIS_YEAR = 'BILLING_PERIOD_THIS_YEAR',
	BILLING_PERIOD_THIS_QUARTER = 'BILLING_PERIOD_THIS_QUARTER'
}

export const billingPeriodFilterOptions = [
	DocumentFilterOption.BILLING_PERIOD_ALL,
	DocumentFilterOption.BILLING_PERIOD_THIS_YEAR,
	DocumentFilterOption.BILLING_PERIOD_THIS_QUARTER
];

export const documentFilterOptionGroups: Array<FilterOptionGroup<DocumentFilterOption, DocumentModel>> = [
	{
		property: 'billingPeriod',
		options: billingPeriodFilterOptions,
		buildFilterCriteria: (selectedOptions: Array<FilterCriterionValue>): Nullable<FilterCriterion<DocumentModel>> => {
			selectedOptions = selectedOptions.filter((selectedOption) => {
				return billingPeriodFilterOptions.includes(selectedOption as DocumentFilterOption);
			});
			if (selectedOptions.length === 0) {
				return null;
			}
			let criteriaId = '';
			let criteria: Array<ValueFilterCriterion<DocumentModel>> = [];
			if (selectedOptions.length === 1) {
				const selectedOption = selectedOptions[0];
				if (selectedOption === DocumentFilterOption.BILLING_PERIOD_ALL) {
					criteriaId = DocumentFilterOption.BILLING_PERIOD_ALL;
					criteria = [];
				} else if (selectedOption === DocumentFilterOption.BILLING_PERIOD_THIS_YEAR) {
					criteriaId = DocumentFilterOption.BILLING_PERIOD_THIS_YEAR;
					const value = `${new Date().getFullYear()}-01`;
					criteria = [
						{
							property: 'billingPeriod',
							comparator: SingleValueFilterComparator.GREATER_THAN_EQUAL,
							value: value
						}
					];
				} else if (selectedOption === DocumentFilterOption.BILLING_PERIOD_THIS_QUARTER) {
					criteriaId = DocumentFilterOption.BILLING_PERIOD_THIS_QUARTER;
					const dateValue = new Date();
					const monthOffset = dateValue.getMonth() % 3;
					dateValue.setMonth(dateValue.getMonth() - monthOffset);
					const criterionValueYear = dateValue.getFullYear();
					const criterionValueMonth = (dateValue.getMonth() + 1).toString().padStart(2, '0');
					criteria = [
						{
							property: 'billingPeriod',
							comparator: SingleValueFilterComparator.GREATER_THAN_EQUAL,
							value: `${criterionValueYear}-${criterionValueMonth}`
						}
					];
				}
			}
			return {
				id: criteriaId,
				criteria
			};
		},
		buildFilterOptions: <Model extends DocumentModel>(filterCriteria: FilterCriteria<Model>): Array<DocumentFilterOption> => {
			let filterOptions: Array<DocumentFilterOption> = [];
			for (const filterCriterion of filterCriteria) {
				filterOptions = [...filterOptions, ...(filterCriterion.id.split(':') as Array<DocumentFilterOption>)];
			}
			return filterOptions.filter((filterOption) => {
				return billingPeriodFilterOptions.includes(filterOption);
			});
		}
	}
];
