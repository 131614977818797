import { LiteralStruct } from './locale';

/**
 * Flattens a nested object structure into a single-level object.
 *
 * @param literals - The nested object to flatten.
 * @param keyChain - The current key chain.
 * @returns The flattened object.
 */
export const flattenLiteralStruct = (literals: LiteralStruct, keyChain: Array<string> = []): Record<string, string> => {
	let flattened: Record<string, string> = {};
	Object.keys(literals).forEach((key: string) => {
		if (typeof literals[key] === 'object' && literals[key] !== null) {
			flattened = { ...flattened, ...flattenLiteralStruct(literals[key] as LiteralStruct, [...keyChain, key]) };
		} else {
			flattened[[...keyChain, key].join('.')] = String(literals[key]);
		}
	});
	return flattened;
};
