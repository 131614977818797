import process from 'process';

import React from 'react';

import { OauthEmitter, OauthEmitterProps } from './oauth-emitter';
import { OauthEmitterMock } from './oauth-emitter-mock';

/**
 * OauthEmitterFactory is a function that returns either an OauthEmitter or an OauthEmitterMock
 * based on the value of the NX_PUBLIC_CORE_REACT_UI_AUTH_EMITTER_MOCKED environment variable.
 *
 * @param props - The props to pass to the OauthEmitter or OauthEmitterMock.
 * @returns The OauthEmitter or OauthEmitterMock JSX element.
 */
export function OauthEmitterFactory(props: OauthEmitterProps) {

	if (process.env.NX_PUBLIC_CORE_REACT_UI_AUTH_EMITTER_MOCKED === 'true') {
		return (<OauthEmitterMock {...props} />);
	}
	return (<OauthEmitter {...props} />);

}
