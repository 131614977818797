import { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';

import { AppError } from '@chroma-x/common/core/error';
import { Nullable } from '@chroma-x/common/core/util';

import { Toast } from '../toast/toast';

export type ToastManagerProps = {
	children: ReactNode
};

export type ToastManagerContext = {
	present: (message: string) => void,
	dismiss: () => void
};

export const toastManagerContext = createContext<ToastManagerContext>({
	present: (_message: string) => {
		throw new AppError('No toast manager provided');
	},
	dismiss: () => {
		throw new AppError('No toast manager provided');
	}
});

export const useToastManager = (): ToastManagerContext => {
	return useContext(toastManagerContext);
};

export function ToastManager(props: ToastManagerProps) {

	const { children } = props;

	// Use a symbol instead of string to force state change even if the message is the same as before
	const [toastMessage, setToastMessage] = useState<Nullable<symbol>>(null);
	const dismissTimeout = useRef<Nullable<number>>(null);

	useEffect(() => {
		return () => {
			if (dismissTimeout.current !== null) {
				window.clearTimeout(dismissTimeout.current);
			}
		};
	}, []);

	const handlePresent = (message: string) => {
		if (dismissTimeout.current !== null) {
			window.clearTimeout(dismissTimeout.current);
		}
		dismissTimeout.current = window.setTimeout(handleDismiss, 7000);
		setToastMessage(Symbol(message));
	};

	const handleDismiss = () => {
		if (dismissTimeout.current !== null) {
			window.clearTimeout(dismissTimeout.current);
		}
		setToastMessage(null);
	};

	const toastManagerContextValue = {
		present: handlePresent,
		dismiss: handleDismiss
	};

	const renderToast = () => {
		if (toastMessage === null) {
			return null;
		}
		return (
			<Toast message={toastMessage.description as string} onDismiss={handleDismiss} />
		);
	};

	return (
		<toastManagerContext.Provider value={toastManagerContextValue}>
			{renderToast()}
			{children}
		</toastManagerContext.Provider>
	);

}
