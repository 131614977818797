import React, { Suspense } from 'react';

import { IllustrationIdentifier } from './illustration.enum';

const LazyLoadedIllustrationSvg = React.lazy(() => import('./illustration-svg'));

export type IllustrationProps = {
	identifier: IllustrationIdentifier
};

export function Illustration(props: IllustrationProps) {

	const { identifier } = props;

	return (
		<Suspense fallback={null}>
			<LazyLoadedIllustrationSvg identifier={identifier} />
		</Suspense>
	);

}
