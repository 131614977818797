import { FilterCriteria, ModelPrimaryKey, PaginatedCollectionResponse, SortCriteria } from '@chroma-x/common/core/api-integration';
import { DocumentProvisionServiceApiClientFactory } from '@chroma-x/frontend/domain/document/api-integration-factory';
import { DocumentModel, SignedDownloadUrlModel } from '@chroma-x/frontend/domain/document/domain-model';

export abstract class DocumentProvisionUseCasesBase {

	public async fetchCollection(
		sort?: SortCriteria<DocumentModel>,
		filter?: FilterCriteria<DocumentModel>
	): Promise<PaginatedCollectionResponse<DocumentModel>> {
		return DocumentProvisionServiceApiClientFactory.create().fetchPage(1, undefined, sort, filter);
	}

	public async fetchPage(
		page = 1,
		sort?: SortCriteria<DocumentModel>,
		filter?: FilterCriteria<DocumentModel>
	): Promise<PaginatedCollectionResponse<DocumentModel>> {
		return DocumentProvisionServiceApiClientFactory.create().fetchPage(page, undefined, sort, filter);
	}

	public async getSignedDownloadUrl(id: ModelPrimaryKey): Promise<SignedDownloadUrlModel> {
		return DocumentProvisionServiceApiClientFactory.create().getSignedDownloadUrl(id);
	}

}
