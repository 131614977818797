/**
 * Returns the current date with the time set to midnight.
 *
 * @returns The current date with the time set to midnight.
 */
export const getToday = (): Date => {
	const today = new Date();
	today.setHours(0, 0, 0, 0);
	return today;
};

/**
 * Returns the date that is `offset` days from the current date.
 *
 * @param offset - The number of days to add to the current date.
 * @returns The date that is `offset` days from the current date.
 */
export const getCalendarDay = (offset: number): Date => {
	const day = getToday();
	day.setDate(day.getDate() + offset);
	return day;
};

/**
 * Returns the date that is `offset` business days from the current date.
 *
 * @param offset - The number of business days to add to the current date.
 * @returns The date that is `offset` business days from the current date.
 */
export const getBusinessDay = (offset: number): Date => {
	const day = getToday();
	day.setDate(day.getDate() + offset);
	if (day.getDay() === 0) {
		day.setDate(day.getDate() + 1);
	}
	return day;
};

/**
 * Returns the next business day after the given date.
 *
 * @param date - The date to start from.
 * @returns The next business day after the given date.
 */
export const getNextBusinessDay = (date: Date): Date => {
	if (date.getDay() === 0) {
		date.setDate(date.getDate() + 1);
	}
	return date;
};

/**
 * Returns the morning of the given date.
 *
 * @param date - The date to get the morning of.
 * @returns The morning of the given date.
 */
export const getMorningOfDay = (date: Date): Date => {
	date.setHours(0, 0, 0, 0);
	return date;
};

/**
 * Checks if the given date is today.
 *
 * @param date - The date to check.
 * @returns `true` if the given date is today, `false` otherwise.
 */
export const isToday = (date: Date): boolean => {
	return getMorningOfDay(date).getTime() === getToday().getTime();
};

/**
 * Checks if two dates are on the same day.
 *
 * @param left - The first date to compare.
 * @param right - The second date to compare.
 * @returns `true` if the two dates are on the same day, `false` otherwise.
 */
export const isSameDay = (left: Date, right: Date): boolean => {
	return (getMorningOfDay(left).getTime() === getMorningOfDay(right).getTime());
};

/**
 * Checks if two dates are in the same month.
 *
 * @param left - The first date to compare.
 * @param right - The second date to compare.
 * @returns `true` if the two dates are in the same month, `false` otherwise.
 */
export const isSameMonth = (left: Date, right: Date): boolean => {
	return ((left.getMonth() === right.getMonth()) && (left.getFullYear() === right.getFullYear()));
};
