/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ComponentType, FC } from 'react';

import { UiErrorHandler, UiErrorHandlerProps } from './ui-error-handler';

type ComponentProps = UiErrorHandlerProps & any;

/**
 * Higher-order component that wraps a component with a UI error handler.
 *
 * @template P - The type of the component's props.
 * @param Component - The component to wrap.
 * @returns The wrapped component.
 */
export const withUiErrorHandler = <P = ComponentProps>(Component: ComponentType<P>): FC<P> => {
	return (props: any) => {
		return (
			<UiErrorHandler>
				<Component {...props} />
			</UiErrorHandler>
		);
	};
};
