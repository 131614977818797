import process from 'process';

import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

import { AppError } from '@chroma-x/common/core/error';
import { Nullable, Optional } from '@chroma-x/common/core/util';
import { LogLevel } from '@chroma-x/frontend/core/logger';
import { useLogger } from '@chroma-x/frontend/core/react-logger';

import { TenantDefinition } from './tenant.type';

export type TenantProviderProps = {
	children: ReactNode,
	tenants: Array<TenantDefinition>
};

export const tenantContext = createContext<Optional<TenantDefinition>>(new Optional<TenantDefinition>(null));

export const useTenant = (): Optional<TenantDefinition> => {
	return useContext(tenantContext);
};

export function TenantProvider(props: TenantProviderProps) {

	const { children, tenants } = props;

	const [tenantDefinition, setTenantDefinition] = useState<Nullable<TenantDefinition>>(null);
	const logger = useLogger();

	const tenantProviderMocked = new Optional(process.env.NX_PUBLIC_CORE_REACT_TENANT_PROVIDER_MOCKED)
		.getOrThrow(new AppError('Tenant provider mock environment flag unavailable'));

	const hostname = window.location.hostname;

	useMemo(() => {
		if (tenantProviderMocked === 'true') {
			const tenant = tenants[0];
			if (tenant === undefined) {
				logger?.logMessage(`No tenant mock defined.`, LogLevel.DEBUG);
				return;
			}
			setTenantDefinition(tenant);
			return;
		}

		// Get the matching tenant by hostname
		const tenant = tenants.find((tenant) => tenant.hostnames.includes(hostname));
		if (tenant === undefined) {
			logger?.logMessage(`No tenant defined for hostname ${hostname}.`, LogLevel.DEBUG);
			return;
		}

		if (tenant) {
			logger?.logMessage(`Selected tenant ${tenant.name}.`, LogLevel.DEBUG);
		}

		setTenantDefinition(tenant);
	}, [tenantProviderMocked, hostname]);

	return (
		<tenantContext.Provider value={new Optional(tenantDefinition)}>
			{children}
		</tenantContext.Provider>
	);

}
