import { Optional } from '@chroma-x/common/core/util';

import { BrowserLocalStorageAdapter } from './storage-adapter/browser-local-storage-adapter';
import { StorageAdapter } from './storage-adapter/storage-adapter';

export type ValueWriteConversion<From, To> = (value: From) => To;
export type ValueReadConversion<From, To> = (value: From) => To;

/**
 * Represents a utility class for interacting with the browser's local storage.
 * It allows for reading, writing, removing, and clearing values from the storage.
 * It also allows for adding and removing event listeners.
 */
export class LocalStorage {

	private static storageAdapter: StorageAdapter = new BrowserLocalStorageAdapter();

	/**
	 * Sets the storage adapter to be used by the LocalStorage class.
	 * @param storageAdapter - The storage adapter to set.
	 */
	public static setStorageAdapter(storageAdapter: StorageAdapter) {
		this.storageAdapter = storageAdapter;
	}

	/**
	 * Reads a value from the storage.
	 * @param key - The key of the value to read.
	 * @param options - The options for reading the value.
	 * @param options.namespace - The namespace to use for the key.
	 * @param options.conversion - A conversion function to apply to the value.
	 * @returns An optional value.
	 */
	public static read<T, StorageT = T>(key: string, options?: { namespace?: string, conversion?: ValueReadConversion<StorageT, T> }): Optional<T> {
		const { namespace, conversion } = options ?? {};
		const value = this.storageAdapter.read(this.buildKey(key, namespace));
		if (value === null) {
			return new Optional<T>(null);
		}
		let data = JSON.parse(value);
		if (conversion !== undefined) {
			data = conversion(data);
		}
		return new Optional<T>(data);
	}

	/**
	 * Writes a value to the storage.
	 * @param key - The key to associate with the value.
	 * @param value - The value to write.
	 * @param options - The options for writing the value.
	 * @param options.namespace - The namespace to use for the key.
	 * @param options.conversion - A conversion function to apply to the value.
	 */
	public static write<T, StorageT = T>(key: string, value: T, options?: { namespace?: string, conversion?: ValueWriteConversion<T, StorageT> }): void {
		const { namespace, conversion } = options ?? {};
		let data;
		if (conversion !== undefined) {
			data = conversion(value);
		} else {
			data = value;
		}
		this.storageAdapter.write(this.buildKey(key, namespace), JSON.stringify(data));
	}

	/**
	 * Removes a value from the storage.
	 * @param key - The key of the value to remove.
	 * @param options - The options for removing the value.
	 * @param options.namespace - The namespace to use for the key.
	 */
	public static remove(key: string, options?: { namespace?: string }): void {
		const { namespace } = options ?? {};
		this.storageAdapter.remove(this.buildKey(key, namespace));
	}

	/**
	 * Clears all values from the storage.
	 */
	public static clear(): void {
		this.storageAdapter.clear();
	}

	/**
	 * Adds an event listener for the 'storage' event.
	 * @param callback - The callback function to invoke when the event is fired.
	 */
	public static addListener(callback: (event: StorageEvent) => void): void {
		this.storageAdapter.addListener(callback);
	}

	/**
	 * Removes an event listener for the 'storage' event.
	 * @param callback - The callback function to remove.
	 */
	public static removeListener(callback: (event: StorageEvent) => void): void {
		this.storageAdapter.removeListener(callback);
	}

	private static buildKey(key: string, namespace?: string): string {
		return (namespace ? namespace + '_' : '') + key;
	}

}
