import { jwtDecode, JwtPayload } from 'jwt-decode';

import { Maybe, Nullable, Optional } from '@chroma-x/common/core/util';
import { LocalStorage } from '@chroma-x/frontend/core/local-storage';

import { AuthHandlerInterface } from './auth-handler-interface';
import { AccessToken, IdToken, JsonWebToken, RefreshToken } from './json-web-token';

type StoredJsonWebToken = Omit<JsonWebToken, 'accessTokenValidTo' | 'idTokenValidTo' | 'refreshTokenValidTo'> & {
	accessTokenValidTo: number,
	idTokenValidTo?: number,
	refreshTokenValidTo?: number
};

export const JSON_WEB_TOKEN_STORAGE_KEY = 'lib_auth_jwt';

/**
 * AuthHandler class handles authentication related operations.
 * It is a singleton class and provides methods for authentication, token update,
 * unauthentication, and retrieval of token and token details.
 */
export class AuthHandler implements AuthHandlerInterface {

	private static instance: Nullable<AuthHandler> = null;
	private scope?: string;

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	private constructor() {
	}

	/**
	 * Gets the singleton instance of the AuthHandler.
	 * @returns The singleton instance of the AuthHandler.
	 */
	public static get(): AuthHandler {
		if (this.instance === null) {
			this.instance = new this();
		}
		return this.instance;
	}

	/**
	 * Sets the scope for the AuthHandler.
	 * @param scope - The scope to be set.
	 */
	public setScope(scope: string): void {
		this.scope = scope;
	}

	/**
	 * Gets the scope of the AuthHandler.
	 * @returns The scope of the AuthHandler.
	 */
	public getScope(): Maybe<string> {
		return this.scope;
	}

	/**
	 * Authenticates the user.
	 * @param accessToken - The access token.
	 * @param accessTokenValidTo - The date the access token is valid to.
	 * @param idToken - (Optional) The ID token.
	 * @param idTokenValidTo - (Optional) The date the ID token is valid to.
	 * @param refreshToken - (Optional) The refresh token.
	 * @param refreshTokenValidTo - (Optional) The date the refresh token is valid to.
	 */
	public authenticate(
		accessToken: AccessToken,
		accessTokenValidTo: Date,
		idToken?: IdToken,
		idTokenValidTo?: Date,
		refreshToken?: RefreshToken,
		refreshTokenValidTo?: Date
	): void {
		const token = {
			accessToken,
			accessTokenValidTo,
			idToken,
			idTokenValidTo,
			refreshToken,
			refreshTokenValidTo
		} as JsonWebToken;

		this.writeToken(token);
	}

	/**
	 * Updates the authentication.
	 * @param accessToken - The access token.
	 * @param accessTokenValidTo - The date the access token is valid to.
	 * @param idToken - (Optional) The ID token.
	 * @param idTokenValidTo - (Optional) The date the ID token is valid to.
	 * @param refreshToken - (Optional) The refresh token.
	 * @param refreshTokenValidTo - (Optional) The date the refresh token is valid to.
	 */
	public update(
		accessToken: AccessToken,
		accessTokenValidTo: Date,
		idToken?: IdToken,
		idTokenValidTo?: Date,
		refreshToken?: RefreshToken,
		refreshTokenValidTo?: Date
	): void {
		const existingToken = this.getToken().get();
		if (existingToken === null) {
			return;
		}

		let token = {
			...existingToken,
			accessToken,
			accessTokenValidTo
		} as JsonWebToken;

		if (idToken !== undefined && idTokenValidTo !== undefined) {
			token = {
				...token,
				idToken,
				idTokenValidTo
			} as JsonWebToken;
		}

		if (refreshToken !== undefined && refreshTokenValidTo !== undefined) {
			token = {
				...token,
				refreshToken,
				refreshTokenValidTo
			} as JsonWebToken;
		}

		this.writeToken(token);
	}

	/**
	 * Removes the JSON Web Token from the LocalStorage.
	 */
	public unauthenticate(): void {
		LocalStorage.remove(JSON_WEB_TOKEN_STORAGE_KEY, { namespace: this.getScope() });
	}

	/**
	 * Retrieves the JSON Web Token from the LocalStorage.
	 * @returns An Optional object that contains the JSON Web Token.
	 */
	public getToken(): Optional<JsonWebToken> {
		return LocalStorage.read<JsonWebToken, StoredJsonWebToken>(JSON_WEB_TOKEN_STORAGE_KEY, {
			namespace: this.getScope(),
			conversion: (value: StoredJsonWebToken) => {
				return {
					...value,
					accessTokenValidTo: new Date(value.accessTokenValidTo),
					idTokenValidTo: value.idTokenValidTo ? new Date(value.idTokenValidTo) : undefined,
					refreshTokenValidTo: value.refreshTokenValidTo ? new Date(value.refreshTokenValidTo) : undefined
				};
			}
		});
	}

	/**
	 * Retrieves the JSON Web Token details from the LocalStorage.
	 * @returns An Optional object that contains the JSON Web Token details.
	 */
	public getTokenDetails<CustomPayload extends Record<string, unknown>>(): Optional<JwtPayload & CustomPayload> {
		const token = this.getToken().get();
		if (token === null) {
			return new Optional<JwtPayload & CustomPayload>(null);
		}
		return new Optional(jwtDecode<JwtPayload & CustomPayload>(token.accessToken));
	}

	/**
	 * Checks if the user is authenticated.
	 * @returns True if the user is authenticated, false otherwise.
	 */
	public isAuthenticated(): boolean {
		const jsonWebToken = this.getToken().get();
		if (jsonWebToken === null) {
			return false;
		}
		const accessTokenValidToTimestamp = jsonWebToken.accessTokenValidTo?.getTime() ?? 0;
		const refreshTokenValidToTimestamp = jsonWebToken.refreshTokenValidTo?.getTime() ?? 0;
		return (
			jsonWebToken?.accessToken !== undefined
			&& accessTokenValidToTimestamp > Date.now()
		) || (
			jsonWebToken?.refreshToken !== undefined
			&& refreshTokenValidToTimestamp > Date.now()
		);
	}

	/**
	 * Checks if the authentication needs to be updated.
	 * @returns True if the authentication needs to be updated, false otherwise.
	 */
	public needsUpdate(): boolean {
		const jsonWebToken = this.getToken().get();
		if (jsonWebToken === null) {
			return false;
		}
		const accessTokenValidToTimestamp = jsonWebToken.accessTokenValidTo?.getTime() ?? 0;
		const refreshTokenValidToTimestamp = jsonWebToken.refreshTokenValidTo?.getTime() ?? 0;
		return jsonWebToken?.refreshToken !== undefined
			&& refreshTokenValidToTimestamp > Date.now()
			&& accessTokenValidToTimestamp < Date.now() + 10000;
	}

	private writeToken(token: JsonWebToken): void {
		LocalStorage.write<JsonWebToken, StoredJsonWebToken>(JSON_WEB_TOKEN_STORAGE_KEY, token, {
			namespace: this.getScope(),
			conversion: (value) => {
				return {
					...value,
					accessTokenValidTo: value.accessTokenValidTo.getTime(),
					idTokenValidTo: value.idTokenValidTo?.getTime() ?? undefined,
					refreshTokenValidTo: value.refreshTokenValidTo?.getTime() ?? undefined
				};
			}
		});
	}

}
