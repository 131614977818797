import { ModelPrimaryKey } from '@chroma-x/common/core/api-integration';
import { createErrorObject } from '@chroma-x/common/core/error';
import { Model } from '@chroma-x/frontend/core/domain-model';

import { CollectionServiceFoundation } from './collection-service-foundation';
import { FetchStatus } from '../../meta/fetch-status';
import {
	Action,
	getActionFailedStatusFromAction,
	getActionPendingStatusFromAction,
	getActionSuccessStatusFromAction
} from '../action-status-mapper';
import { SetArg } from '../zustand-util';

/**
 * Sets the fetch status of the collection to pending.
 *
 * @template S - The type of the collection service.
 * @template M - The type of the model.
 * @template C - The type of the collection service commands.
 * @param set - The set function to update the state.
 */
export const fetchCollectionTransactionStart = <
	S extends CollectionServiceFoundation<M, C>,
	M extends Model,
	C extends Record<string, unknown>
>(set: SetArg<S, M, C>) => {
	set((state) => {
		state.data.meta.fetchStatus = FetchStatus.PENDING;
	});
};

/**
 * Sets the fetch status of the collection to success and clears the last fetch error.
 *
 * @template S - The type of the collection service.
 * @template M - The type of the model.
 * @template C - The type of the collection service commands.
 * @param set - The set function to update the state.
 */
export const fetchCollectionTransactionSuccess = <
	S extends CollectionServiceFoundation<M, C>,
	M extends Model,
	C extends Record<string, unknown>
>(set: SetArg<S, M, C>) => {
	set((state) => {
		state.data.meta.lastFetchError = null;
		state.data.meta.fetchStatus = FetchStatus.SUCCESS;
	});
};

/**
 * Sets the fetch status of the collection to failed and sets the last fetch error.
 *
 * @template S - The type of the collection service.
 * @template M - The type of the model.
 * @template C - The type of the collection service commands.
 * @param set - The set function to update the state.
 * @param error - The error that occurred.
 */
export const fetchCollectionTransactionFailed = <
	S extends CollectionServiceFoundation<M, C>,
	M extends Model,
	C extends Record<string, unknown>
>(set: SetArg<S, M, C>, error: Error) => {
	const errorObject = createErrorObject(error);
	set((state) => {
		state.data.meta.lastFetchError = errorObject;
		state.data.meta.fetchStatus = FetchStatus.FAILED;
	});
};

/**
 * Sets the fetch status of the collection to pending.
 *
 * @template S - The type of the collection service.
 * @template M - The type of the model.
 * @template C - The type of the collection service commands.
 * @param set - The set function to update the state.
 */
export const fetchPageTransactionStart = <
	S extends CollectionServiceFoundation<M, C>,
	M extends Model,
	C extends Record<string, unknown>
>(set: SetArg<S, M, C>) => {
	set((state) => {
		state.data.meta.fetchStatus = FetchStatus.PAGING_PENDING;
	});
};

/**
 * Sets the fetch status of the entity in the collection to pending.
 *
 * @template S - The type of the collection service.
 * @template M - The type of the model.
 * @template C - The type of the collection service commands.
 * @param set - The set function to update the state.
 * @param id - The ID of the entity.
 */
export const fetchCollectionEntityTransactionStart = <
	S extends CollectionServiceFoundation<M, C>,
	M extends Model,
	C extends Record<string, unknown>
>(set: SetArg<S, M, C>, id: ModelPrimaryKey) => {
	set((state) => {
		state.data.meta.fetchEntityStatus[id] = FetchStatus.PENDING;
	});
};

/**
 * Sets the fetch status of the entity in the collection to success and clears the last fetch error.
 *
 * @template S - The type of the collection service.
 * @template M - The type of the model.
 * @template C - The type of the collection service commands.
 * @param set - The set function to update the state.
 * @param id - The ID of the entity.
 */
export const fetchCollectionEntityTransactionSuccess = <
	S extends CollectionServiceFoundation<M, C>,
	M extends Model,
	C extends Record<string, unknown>
>(set: SetArg<S, M, C>, id: ModelPrimaryKey) => {
	set((state) => {
		state.data.meta.lastFetchError = null;
		state.data.meta.fetchEntityStatus[id] = FetchStatus.SUCCESS;
	});
};

/**
 * Updates the state of the collection service to indicate that the fetch of a specific entity failed.
 *
 * @param set - The set function to update the state.
 * @param id - The ID of the entity.
 * @param error - The error that occurred.
 * @template S - The type of the collection service.
 * @template M - The type of the model.
 * @template C - The type of the collection service commands.
 */
export const fetchCollectionEntityTransactionFailed = <
	S extends CollectionServiceFoundation<M, C>,
	M extends Model,
	C extends Record<string, unknown>
>(set: SetArg<S, M, C>, id: ModelPrimaryKey, error: Error) => {
	const errorObject = createErrorObject(error);
	set((state) => {
		state.data.meta.lastFetchError = errorObject;
		state.data.meta.fetchEntityStatus[id] = FetchStatus.FAILED;
	});
};

/**
 * Updates the state of the collection service to indicate that a specific action is in progress.
 *
 * @param set - The set function to update the state.
 * @param action - The action that is being performed.
 * @template S - The type of the collection service.
 * @template M - The type of the model.
 * @template C - The type of the collection service commands.
 */
export const collectionActionTransactionStart = <
	S extends CollectionServiceFoundation<M, C>,
	M extends Model,
	C extends Record<string, unknown>
>(set: SetArg<S, M, C>, action: Action) => {
	set((state) => {
		state.data.meta.actionStatus = getActionPendingStatusFromAction(action);
	});
};

/**
 * Updates the state of the collection service to indicate that a specific action has succeeded.
 *
 * @param set - The set function to update the state.
 * @param action - The action that was performed.
 * @template S - The type of the collection service.
 * @template M - The type of the model.
 * @template C - The type of the collection service commands.
 */
export const collectionActionTransactionSuccess = <
	S extends CollectionServiceFoundation<M, C>,
	M extends Model,
	C extends Record<string, unknown>
>(set: SetArg<S, M, C>, action: Action) => {
	set((state) => {
		state.data.meta.actionStatus = getActionSuccessStatusFromAction(action);
	});
};

/**
 * Updates the state of the collection service to indicate that a specific action has failed.
 *
 * @param set - The set function to update the state.
 * @param action - The action that was performed.
 * @param error - The error that occurred.
 * @template S - The type of the collection service.
 * @template M - The type of the model.
 * @template C - The type of the collection service commands.
 */
export const collectionActionTransactionFailed = <
	S extends CollectionServiceFoundation<M, C>,
	M extends Model,
	C extends Record<string, unknown>
>(set: SetArg<S, M, C>, action: Action, error: Error) => {
	const errorObject = createErrorObject(error);
	set((state) => {
		state.data.meta.lastActionError = errorObject;
		state.data.meta.actionStatus = getActionFailedStatusFromAction(action);
	});
};

/**
 * Updates the state of the collection service to indicate that a specific entity action is in progress.
 *
 * @param set - The set function to update the state.
 * @param id - The ID of the entity.
 * @param action - The action that is being performed.
 * @template S - The type of the collection service.
 * @template M - The type of the model.
 * @template C - The type of the collection service commands.
 */
export const collectionEntityActionTransactionStart = <
	S extends CollectionServiceFoundation<M, C>,
	M extends Model,
	C extends Record<string, unknown>
>(set: SetArg<S, M, C>, id: ModelPrimaryKey, action: Action) => {
	set((state) => {
		state.data.meta.actionEntityStatus[id] = getActionPendingStatusFromAction(action);
	});
};

/**
 * Updates the state of the collection service to indicate that a specific entity action has succeeded.
 *
 * @param set - The set function to update the state.
 * @param id - The ID of the entity.
 * @param action - The action that was performed.
 * @template S - The type of the collection service.
 * @template M - The type of the model.
 * @template C - The type of the collection service commands.
 */
export const collectionEntityActionTransactionSuccess = <S extends CollectionServiceFoundation<M, C>, M extends Model, C extends Record<string, unknown>>(
	set: SetArg<S, M, C>,
	id: ModelPrimaryKey,
	action: Action
) => {
	set((state) => {
		state.data.meta.lastActionError = null;
		state.data.meta.actionEntityStatus[id] = getActionSuccessStatusFromAction(action);
	});
};

/**
 * Updates the state of the collection service to indicate that a specific entity action has failed.
 *
 * @param set - The set function to update the state.
 * @param id - The ID of the entity.
 * @param action - The action that was performed.
 * @param error - The error that occurred.
 * @template S - The type of the collection service.
 * @template M - The type of the model.
 * @template C - The type of the collection service commands.
 */
export const collectionEntityActionTransactionFailed = <
	S extends CollectionServiceFoundation<M, C>,
	M extends Model,
	C extends Record<string, unknown>
>(set: SetArg<S, M, C>, id: ModelPrimaryKey, action: Action, error: Error) => {
	const errorObject = createErrorObject(error);
	set((state) => {
		state.data.meta.lastActionError = errorObject;
		state.data.meta.actionEntityStatus[id] = getActionFailedStatusFromAction(action);
	});
};
