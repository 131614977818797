import React, { ReactNode } from 'react';

import { Optional } from '@chroma-x/common/core/util';

import { envContext, Environment, EnvironmentValue } from './env-context';

export type EnvProviderProps = {
	env: Environment,
	children: ReactNode
};

/**
 * EnvProvider is a React component that provides the environment values to its children.
 * It takes an object of type EnvProviderProps as its props.
 *
 * @param props - The props for the EnvProvider.
 * @param props.env - The environment object.
 * @param props.children - The child components.
 * @returns The JSX element.
 */
export const EnvProvider = (props: EnvProviderProps): React.JSX.Element => {
	const { env, children } = props;

	const getter = <T = EnvironmentValue>(key: string): Optional<T> => {
		return new Optional<T>(env?.[key] as unknown as T ?? null);
	};

	const environmentValue = {
		get: getter
	};

	return (
		<envContext.Provider value={environmentValue}>
			{children}
		</envContext.Provider>
	);

};
