export type Probably<T> = T | null | undefined;

/**
 * Checks if the given value is not undefined or null.
 *
 * @param value - The value to check.
 * @returns True if the value is not undefined or null, false otherwise.
 */
export const probablyResolves = <T>(value: Probably<T>): value is T => {
	return value !== undefined && value !== null;
};

/**
 * Throws an error if the given value is undefined or null.
 *
 * @param value - The value to check.
 * @param error - Optional error to throw. If not provided, a default error is thrown.
 * @returns The value if it is not undefined or null.
 * @throws The provided or default error if the value is undefined or null.
 */
export const guardProbably = <T = unknown>(value: Probably<T>, error?: Error): T => {
	if (!probablyResolves(value)) {
		error = error ?? new Error('Resolving probably value failed');
		throw error;
	}
	return value;
};
